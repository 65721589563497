import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    onDelete: () => void;
}

export const SettlementAccountBankOptionalField = ({children, onDelete }: Props) => {
    return (
        <div className="form-cell position-relative">
            {children}
            <i className="icon icon-delete position-absolute" onClick={onDelete} />
        </div>
    )
}
