import { SORTING_TYPE } from "../../constants";
import { InventoryPosition } from "../../types/inventory/InventoryPosition";
import { dateTimeUtils, moneyUtils } from "../../utils";
import { IColumnDefinition } from "../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../bidding/common/table/columns/column-builder/ColumnBuilder";
import { Table } from "../bidding/common/table";
import { SecurityInventoryLink } from "../inventory/SecurityInventoryLink";

interface Props {
    items: InventoryPosition[];
}

const columns: IColumnDefinition<InventoryPosition>[] = [{
    columnKey: 'lastUpdated',
    renderColumnHeaderContent: () => 'Last Updated',
    renderColumnContent: i => <span className="due-date">{dateTimeUtils.utcToLocalString(i.lastUpdateDate)}</span>,
    sortingField: 'lastUpdated',
    sortingType: SORTING_TYPE.date,
    headerClassName: 'data-list-cell-lg',
    bodyClassName: 'data-list-cell-lg',
}, {
    columnKey: 'ticker',
    renderColumnHeaderContent: () => 'Ticker',
    renderColumnContent: i => i.ticker,
    sortingField: 'security.ticker',
    sortingType: SORTING_TYPE.string,
    headerClassName: 'data-list-cell-lg',
    bodyClassName: 'data-list-cell-lg',
}, {
    columnKey: 'size',
    renderColumnHeaderContent: () => 'Size, MM',
    renderColumnContent: i => moneyUtils.amountToMM(i.size),
    sortingField: 'security.size',
    sortingType: SORTING_TYPE.number,
    headerClassName: 'text-right',
    bodyClassName: 'text-right',

}, {
    columnKey: 'view',
    renderColumnHeaderContent: () => '',
    renderColumnContent: i => <SecurityInventoryLink ticker={i.ticker} />,
    headerClassName: 'data-list-cell-xl text-right',
    bodyClassName: 'data-list-cell-xl text-right',
}]

export function SecurityUsageInventoryTable({ items }: Props) {
    return (
        <Table
            className="data-list-striped"
            defaultSortBy="lastUpdated"
            dataItems={items}
            columns={columns.map(c => new ColumnBuilder(c))}
        />
    );
}
