import { ReactNode } from "react"
import { Trade } from "../../types/trades/Trade"
import { TradeStatus } from "../../types/trades/TradeStatus"
import { AffirmTradeButtons } from "../bidding/brokerDealer/trade-affirm"
import { RejectedLink } from "../bidding/common"
import { TradedToMe } from "./TradedToMe"
import { ShowFor } from "../access"
import { roles } from "../../constants"

interface TradeActionProps {
    trade?: Trade;
    isTradedAway: boolean;
    positionId: number;
    ticker: string;
    children?: ReactNode
}

export const TradeAction = ({ trade, isTradedAway, positionId, ticker, children = null }: TradeActionProps) => {
    if (!trade) {
        return isTradedAway ? <>Traded away</> : <>{children}</>;
    }

    switch (trade.status) {
        case TradeStatus.rejected:
            return <RejectedLink className="seller-rejected" rejectReason={trade.rejectReason} />
        case TradeStatus.pending:
            return (
                <ShowFor roles={[roles.BrokerDealerTrader, roles.SellerTrader, roles.SellerAdministrator]}>
                    <AffirmTradeButtons
                        positionId={positionId}
                        tradeReferenceId={trade.id}
                        ticker={ticker}
                        iconsView={true}
                        buyerPseudoOrderNumber={trade.buyerPseudoOrderNumber}
                    />
                </ShowFor>
            )
        default:
            return <TradedToMe price={trade.price} />
    }
}
