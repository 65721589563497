import * as React from 'react';
import cn from 'classnames';
import { TradeSettlementStatus, tradeSettlementStatusTitle } from '../../types/settlement/TradeSettlementStatus';

interface Props {
    status: TradeSettlementStatus;
}

export function SettlementStatusLabel({ status }: Props) {
    const style = 'status-' + tradeSettlementStatusTitle[status].toLowerCase().replace(" ", "-");

    return (
        <div className={cn('status', { [style]: true })}>
            {tradeSettlementStatusTitle[status]}
        </div>
    );
}
