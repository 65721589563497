import { useSelector } from "react-redux";
import { Preloader } from "../../common";
import { AppState } from "../../../types/state/AppState";
import { isRequestSuccess } from "../../../utils";
import { useInvenotory } from "../../../effects/data-accessors/useInventory";
import { SecurityHistory } from "../SecurityHistory";
import { PxTalkHistoricalColorDispersion } from "../PxTalkHistoricalColorDispersion";

interface BWICLogProps {
    evalPriceTalkVisible?: boolean;
}

export const BWICLog = ({ evalPriceTalkVisible = false }: BWICLogProps) => {
    const {
        security,
        securityBwicStatistics,
        initRequestState
    } = useSelector((s: AppState) => s.bwicLog);
    const inventoryState = useInvenotory(security == null, [], security?.ticker ? security.ticker : '');

    if (!security) return null;

    const isInitialized = isRequestSuccess(initRequestState) && isRequestSuccess(inventoryState.requestState);

    return (
        <div className="security-information">
            <Preloader inProgress={!isInitialized}>
                <>
                    <PxTalkHistoricalColorDispersion
                        securityStatistics={securityBwicStatistics}
                        securityId={security.securityId}
                        evalPriceTalkVisible={evalPriceTalkVisible}
                    />
                    {
                        (!!securityBwicStatistics.count || !!inventoryState.inventory.length) &&
                        <SecurityHistory
                            securityIdentifier={security.ticker}
                            bwicsHistory={securityBwicStatistics}
                            inventory={inventoryState.inventory}
                        />
                    }
                </>
            </Preloader>
        </div>
    )
}
