import * as React from 'react';
import cn from 'classnames';
import { StatusMessageSectionType } from '../../types/state/NotificationState';

interface Props {
    type: StatusMessageSectionType;
    children: React.ReactNode;
    iconOnly?: boolean;
}

export function StatusMessageSection({ type, children, iconOnly = false }: Props) {
    const getClassName = () => ({
        info: type === StatusMessageSectionType.Info,
        error: type === StatusMessageSectionType.Error,
        alert: type === StatusMessageSectionType.Alert,
        success: type === StatusMessageSectionType.Success,
    });

    const renderIcon = () => {
        switch (type) {
            case StatusMessageSectionType.Info: return <i className="icon icon-info" />;
            case StatusMessageSectionType.Error: return <i className="icon icon-error" />;
            case StatusMessageSectionType.Alert: return <i className="icon icon-warning" />;
            case StatusMessageSectionType.Success: return <i className="icon icon-success" />;
            default: return null;
        };
    };

    return (
        <div className={cn("status-message", getClassName(), {"icon-only": iconOnly})}>
            {renderIcon()}
            <span className="status-message-cnt">
                {children}
            </span>
        </div>

    );
}
