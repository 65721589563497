import moment from 'moment';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { constants, routes } from '../../constants';
import { dateTimeUtils, formatUtils, moneyUtils } from '../../utils';
import { OnHoverTooltip } from '../common';
import { PxTalkNormalizationTable } from '../common/PxTalk/PxTalkNormalizationTable'
import { SameDayBwicIcon } from '../common/SameDayBwicIcon';
import IconSVG from '../../styles/svg-icons';
import { FeatureLink } from '../access/FeatureLink';
import { SecurityBwicStatistics } from '../../types/security/SecurityBwicStatistics';
import { InventoryPosition } from '../../types/inventory/InventoryPosition';
import {
    SecurityListColumn,
    SecurityListHeader,
    SecurityListHeaderColumn,
    SecurityListRow
} from "../common/security-list";
import { MyBwicIcon } from '../common/MyBwicIcon';
import { BwicViewLink } from '../common/BwicViewLink';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { user } from '../../user';
import { RequiredFeature } from '../access/RequiredFeature';
import { SubscribeLink } from '../access/BlockedFeatureText';
import { ActionBlocker } from '../access/ActionBlocker';
import { ValueBlocker } from '../access/ValueBlocker';
import { SecurityBwicStatisticsSummary } from '../../types/security/SecurityBwicStatisticsSummary';

interface Props {
    securityIdentifier: string;
    bwicsHistory: SecurityBwicStatisticsSummary
    inventory: InventoryPosition[];
}

enum ItemType {
    BwicHistory,
    Inventory
}

export function SecurityHistory({ securityIdentifier, bwicsHistory, inventory }: Props) {
    const bwicHistoryItems = useMemo(() => bwicsHistory?.securities.map(item => ({
        type: ItemType.BwicHistory,
        date: item.bidsDueUtc,
        item,
    })), [bwicsHistory]);

    const inventoryItems = useMemo(() => inventory.map(item => ({
        type: ItemType.Inventory,
        date: item.lastUpdateDate,
        item
    })), [inventory]);

    const allItems =
        [...bwicHistoryItems, ...inventoryItems]
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    const items = user.hasFeatures(SubscriptionFeature.BwicMonitorBwicsData)
        ? allItems
        : allItems.length ? [allItems[0]] : []

    const renderHistory = (bwic: SecurityBwicStatistics) => {
        const date = dateTimeUtils.utcToLocalString(bwic.bidsDueUtc, 'MM/DD/YY');
        const offerSize = bwic.size ? moneyUtils.amountToMM(bwic.size): constants.emptyPlaceholder;
        const bidSize = bwic.bidSize ? moneyUtils.amountToMM(bwic.bidSize) : constants.emptyPlaceholder;
        const bidOfferSize = `${offerSize}/${bidSize}`;
        const color = formatUtils.formatColor(bwic.color);
        const pxTalks = formatUtils.formatPxTalk(bwic.pxTalks);
        const dateFilter = moment(bwic.bidsDueUtc).format('YYYY-M-D');

        return (
            <SecurityListRow key={bwic.positionId + "-bwic"}>
                <SecurityListColumn className="data-list-cell-xs">
                    <ActionBlocker feature={SubscriptionFeature.BwicMonitorSecuritySearch}>
                        <FeatureLink
                            className="btn-link secondary text-regular"
                            path={`${routes.allBWICs}?isinCusipsAndTickers=${securityIdentifier}&dateFrom=${dateFilter}&dateTo=${dateFilter}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {date}
                        </FeatureLink>
                    </ActionBlocker>
                </SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xxxs padding-l-0">
                    <MyBwicIcon bwic={bwic} />
                </SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xxxs padding-l-0">{bwic.isSameDayBwic && <SameDayBwicIcon />}</SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xs cell-bwic-view-link padding-l-0">
                    <BwicViewLink
                        text="BWIC"
                        emptyPlaceholderText="BWIC"
                        referenceName={bwic.referenceName}
                        status={bwic.status}
                        isMy={bwic.isMy}
                        isParticipant={bwic.isParticipant}
                        isDirectBidding={bwic.isClearingBankParticipant}
                        isAllToAll={bwic.isAllToAll}
                        isParsed={bwic.isParsed}
                    />
                </SecurityListColumn>
                <SecurityListColumn className="padding-l-0">
                    <OnHoverTooltip overlay={bidOfferSize}>{bidOfferSize}</OnHoverTooltip>
                </SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xs-flexible">
                    {color ? <OnHoverTooltip overlay={color}>{color}</OnHoverTooltip> : constants.emptyPlaceholder}
                </SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xs-sm cell-px-talk text-right">
                    <ValueBlocker feature={SubscriptionFeature.BwicMonitorBwicsData}>
                        {pxTalks ? pxTalks : constants.emptyPlaceholder}
                    </ValueBlocker>
                </SecurityListColumn>
            </SecurityListRow>
        );
    }

    const renderInventory = (inventory: InventoryPosition) => {
        const date = moment(inventory.lastUpdateDate).format('MM/DD/YY');
        const dateFilter = moment(inventory.lastUpdateDate).format('YYYY-M-D');
        const offerSize = inventory.size ? moneyUtils.amountToMM(inventory.size): constants.emptyPlaceholder;
        const bidSize = inventory.bidSize ? moneyUtils.amountToMM(inventory.bidSize) : constants.emptyPlaceholder;
        const bidOfferSize = `${offerSize}/${bidSize}`;


        return (
            // uuid() - temporary solution due to test database broken data issue
            <SecurityListRow key={inventory.id + "-" + (inventory.securityId ?? uuid()) + "-inventory"}>
                <SecurityListColumn className="data-list-cell-xs">
                    <ActionBlocker feature={SubscriptionFeature.InventorySecuritySearch}>
                        <FeatureLink
                            target="_blank"
                            path={`${routes.inventory}?isinCusipsAndTickers=${inventory.ticker}&dateFrom=${dateFilter}&dateTo=${dateFilter}`}
                        >
                            {date}
                        </FeatureLink>
                    </ActionBlocker>
                </SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xxxs padding-l-0">&nbsp;</SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xxxs padding-l-0">&nbsp;</SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xs padding-l-0">Inventory</SecurityListColumn>
                <SecurityListColumn className="padding-l-0">
                    <OnHoverTooltip overlay={bidOfferSize}>{bidOfferSize}</OnHoverTooltip>
                </SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xs-flexible">{constants.emptyPlaceholder}</SecurityListColumn>
                <SecurityListColumn className="data-list-cell-xs-sm cell-px-talk text-right">{constants.emptyPlaceholder}</SecurityListColumn>
            </SecurityListRow>
        );
    }

    return (
        <div className="security-information-history">
            <div className="flex-row">
                <h4>Security History</h4>
                <div className="flex-item-right text-sm text-bold">
                    {Boolean(bwicsHistory.count) && <>{bwicsHistory.count} BWICs</>}
                    {Boolean(inventory.length) && <span className="margin-l-8">{inventory.length} Inventory</span>}
                </div>
            </div>
            <div className="security-information-table">
                <div className="data-list data-list-striped">
                    <SecurityListHeader>
                        <SecurityListHeaderColumn className="data-list-cell-xs">Date</SecurityListHeaderColumn>
                        <SecurityListHeaderColumn className="data-list-cell-xxxs padding-l-0" />
                        <SecurityListHeaderColumn className="data-list-cell-xxxs padding-l-0" />
                        <SecurityListHeaderColumn className="data-list-cell-xs padding-l-0">Monitor</SecurityListHeaderColumn>
                        <SecurityListHeaderColumn className="padding-l-0">Offer/Bid, MM</SecurityListHeaderColumn>
                        <SecurityListHeaderColumn className="data-list-cell-xs-flexible">Color</SecurityListHeaderColumn>
                        <SecurityListHeaderColumn className="data-list-cell-xs-sm text-right padding-l-0">
                            Px Talk
                            <OnHoverTooltip
                                overlayClassName="px-talk-tooltip"
                                overlay={<PxTalkNormalizationTable />}
                                placement="bottomRight"
                            >
                                <IconSVG name="info" width={16} height={16} />
                            </OnHoverTooltip>
                        </SecurityListHeaderColumn>
                    </SecurityListHeader>
                    {
                        items.map(x => x.type === ItemType.BwicHistory
                            ? renderHistory(x.item as SecurityBwicStatistics)
                            : renderInventory(x.item as InventoryPosition))
                    }
                </div>
            </div>
            <RequiredFeature
                feature={SubscriptionFeature.BwicMonitorBwicsData}
                inline
                text={
                    <><SubscribeLink /> to see more security history records.</>
                }
            >
            </RequiredFeature>
        </div>
    );
}
