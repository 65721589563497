import { entries } from "lodash";
import { BwicSearchResult } from "../../types/bwic-monitor/BwicSearchResult";
import { BwicsHistory } from "./SecurityUsagePopup";
import { BwicStatus } from "../../types/enums/BwicStatus";
import { Table } from "../bidding/common/table";
import { ColumnBuilder } from "../bidding/common/table/columns/column-builder/ColumnBuilder";
import { BwicStatusLabel } from "../common/BwicStatusLabel";
import { dateTimeUtils, moneyUtils } from "../../utils";
import { constants, SORTING_TYPE } from "../../constants";
import { BwicProcessType } from "../../types/models/Process";
import { MyBwicIcon } from "../common/MyBwicIcon";
import { BwicViewLink } from "../common/BwicViewLink";

interface SecurityUsageBwicsTableItem {
    bwicReferenceName: string;
    ticker: string;
    status: BwicStatus;
    isMy: boolean;
    isParticipant: boolean;
    bidsDueDateUtc: Date;
    size: number;
    isDirectBidding: boolean;
    isAllToAll: boolean;
    isParsed: boolean;
    isLiveBidding: boolean;
}

const columns = [
    {
        columnKey: 'my-flag',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (entity: SecurityUsageBwicsTableItem) => (
            <MyBwicIcon bwic={entity} />
        ),
        className: 'data-list-cell-xxxs padding-l-0'
    },
    {
        columnKey: 'bwicStatus',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (entity: SecurityUsageBwicsTableItem) => (
            <BwicStatusLabel
                status={entity.status}
                liveBidding={entity.isLiveBidding}
                isParsed={entity.isParsed}
                directBidding={entity.isDirectBidding}
                isAllToAll={entity.isAllToAll}
            />
        ),
        headerClassName: 'data-list-bwic-status',
        bodyClassName: 'data-list-bwic-status',
    },
    {
        columnKey: 'dueDate',
        renderColumnHeaderContent: () => 'Date',
        renderColumnContent: (entity: SecurityUsageBwicsTableItem) => {
            if (!entity.bidsDueDateUtc) return '';
            return dateTimeUtils.utcToLocalString(entity.bidsDueDateUtc, constants.dateTimeFormat);

        },
        sortingField: 'bidsDueDateUtc',
        sortingType: SORTING_TYPE.date,
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'ticker',
        renderColumnHeaderContent: () => 'Ticker',
        renderColumnContent: (entity: SecurityUsageBwicsTableItem) => entity.ticker,
        sortingField: 'ticker',
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'size',
        renderColumnHeaderContent: () => 'Size, MM',
        renderColumnContent: (entity: SecurityUsageBwicsTableItem) => moneyUtils.amountToMM(entity.size),
        className: 'text-right',
        sortingField: 'size'
    },
    {
        columnKey: 'bwic-view',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (entity: SecurityUsageBwicsTableItem) =>
            <BwicViewLink
                referenceName={entity.bwicReferenceName}
                status={entity.status}
                isDirectBidding={entity.isDirectBidding}
                isAllToAll={entity.isAllToAll}
                isMy={entity.isMy}
                isParticipant={entity.isParticipant}
                isParsed={entity.isParsed}
                text="View"
            />,
        className: 'data-list-cell-xs-flexible text-right'
    }]

interface Props {
    items: SecurityUsageBwicsTableItem[];
}

export function SecurityUsageBwicsTable({ items }: Props) {
    return (
        <Table
            className="data-list-striped"
            dataItems={items}
            columns={columns.map(c => new ColumnBuilder(c))} />
    )
}

export const convert = {
    formHistory: (hisotory: BwicsHistory): SecurityUsageBwicsTableItem[] =>
        entries(hisotory)
            .map(([ticker, statistics]) =>
                statistics.map(s => ({
                    ticker,
                    bwicReferenceName: s.referenceName,
                    status: s.status,
                    isMy: s.isMy,
                    isParticipant: s.isParticipant,
                    bidsDueDateUtc: s.bidsDueUtc,
                    size: s.size,
                    isDirectBidding: s.isClearingBankParticipant,
                    isAllToAll: s.isAllToAll,
                    isLiveBidding: s.process.type === BwicProcessType.Live,
                    isParsed: s.isParsed,
                }))
            ).flat(),
    fromSearchResult: (searchResult: BwicSearchResult[]) =>
        searchResult
            .map(b => b.securities.map(s => ({
                ticker: s.ticker,
                bwicReferenceName: b.referenceName,
                status: b.status,
                isMy: b.isMy,
                isParticipant: b.isParticipant,
                bidsDueDateUtc: b.bidsDueDateUtc,
                size: s.size,
                isDirectBidding: b.isClearingBankParticipant,
                isAllToAll: b.isAllToAll,
                isLiveBidding: b.process.type === BwicProcessType.Live,
                isParsed: b.isParsed,
            })))
            .flat()
}
