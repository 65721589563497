import { SettlementAgentAgreement } from "../../types/bid-as-dealer/SettlementAgentAgreement";
import { SellerBuysideSearchResult } from "../../types/bid-as-dealer/SellerBuysideSearchResult";
import { BwicProcessType } from "../../types/models/Process";
import { canBid } from '../../utils/direct-bidding.utils';
import { jumpBallUtils } from "../../utils/jump-ball.utils";
import { liveBiddingUtils } from "../../utils/live-bidding.utils";
import { topXUtils } from "../../utils/top-x.utils";

export function getCanBidFlag(entity: SellerBuysideSearchResult, agreement?: SettlementAgentAgreement) {
    const canSubmitDirectBid = canBid(
        entity.bwic.status,
        entity.bwic.isColorDistribution,
        entity.position.isTradedAway,
        agreement,
        entity.position.trade
    );

    if (!canSubmitDirectBid) return false;

    switch (entity.bwic.process.type) {
        case BwicProcessType.JumpBall:
            return jumpBallUtils.canBid(
                entity.bwic.process.stagedBiddingStatus,
                entity.bidAsDealerRequest?.directBid,
                entity.position.isStage2Participant);
        case BwicProcessType.TopX:
            return topXUtils.canBid(
                entity.bwic.process.stagedBiddingStatus,
                entity.position.isStage2Participant
            );
        case BwicProcessType.BestFootForward:
            return entity.bwic.process.stagedBiddingStatus == null;
        case BwicProcessType.Live:
            return liveBiddingUtils.canBid(
                entity.bwic.bidsDueDateUtc,
                entity.bwic.process,
                Boolean(entity.position.isStage2Participant),
                entity.position.latestBidSubmission);
        default: return true // standard;
    }
}