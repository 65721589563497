import React from 'react';
import { useDispatch } from 'react-redux';
import { Preloader } from '../common';
import { tradesActions } from '../../actions';
import { Checkbox } from '../controls/Checkbox';

export const ReportedFlag = ({ tradeId, isReported, reportedInProgress }) => {
    const dispatch = useDispatch();

    return (
        <Preloader small inProgress={reportedInProgress}>
            <Checkbox
                checked={isReported}
                onChange={() => dispatch(tradesActions.setTradeReportedFlag(tradeId, !isReported))}
            />
        </Preloader>
    );
}
