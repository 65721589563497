import { constants } from '../../constants/constants';
import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { BiddingInfo, BiddingInfoProps } from '../bidding/brokerDealer/biddingSecurities/BiddingInfo';
import { useAgreement } from './useAgreement';

export function SellerBuysideBiddingInfo( props: BiddingInfoProps) {
    const { agreement } = useAgreement();

    if (agreement?.agreementStatus === SettlementAgreementStatus.confirmed) {
        return <BiddingInfo {...props} />
    }

    return <>{constants.emptyPlaceholder}</>;
}