import * as React from 'react';
import { Redirect, useParams, useRouteMatch } from 'react-router';
import { SettlementAccount } from '../../types/settlement/SettlementAccount';
import { SettlementAccountSelectionList } from './SettlementAccountSelectionList';
import { routes } from '../../constants';
import { useSettlementAccounts } from '../../effects/data-accessors/useSettlementAccounts';
import { isRequestSuccess, isRequesting } from '../../utils/request-state.utils';
import { Preloader } from '../common/Preloader';
import { SettlementAccountsEmptyView } from './SettlementAccountsEmptyView';
import { numericUtils } from '../../utils';
import { RequestState } from '../../constants/request-state';

interface Props {
    assetManagerId?: number;
    deleteAccountRequestState?: RequestState;
    onAccountChange: (account: SettlementAccount, replace?: boolean) => void;
    onAccountCreate?: () => void;
    children: (activeSettlementAccount?: SettlementAccount) => React.ReactNode;
}

export function SettlementAccountsView({
    assetManagerId,
    deleteAccountRequestState,
    onAccountChange,
    onAccountCreate,
    children }: Props) {
    const { settlementAccountId } = useParams<{ settlementAccountId: string }>();
    const activeSettlementAccountId = settlementAccountId ? numericUtils.numberOrDefault(settlementAccountId) : undefined;
    const settlementAccountsState = useSettlementAccounts(false);
    const settlementAccountsRequestState = settlementAccountsState.settlementAccountsRequestState;
    const settlementAccounts = assetManagerId
        ? settlementAccountsState.settlementAccounts.filter(a => a.assetManager.id === assetManagerId)
        : settlementAccountsState.settlementAccounts;
    const isAccountsLoaded = isRequestSuccess(settlementAccountsRequestState);
    const activeSettlementAccount =
        isAccountsLoaded && activeSettlementAccountId
            ? settlementAccounts.find(a => a.id === activeSettlementAccountId)
            : undefined;
    const readonly = !!useRouteMatch({ path: [routes.blotterSettlementAccounts, routes.clientsSettlementAccount], strict: false, exact: true });

    React.useEffect(() => {
        if (
            isAccountsLoaded &&
            readonly &&
            settlementAccounts.length &&
            !activeSettlementAccountId) {
            onAccountChange(settlementAccounts[0], true);
        }// eslint-disable-next-line
    }, [isAccountsLoaded, settlementAccounts, activeSettlementAccountId, readonly]);

    if (isAccountsLoaded && activeSettlementAccountId && !activeSettlementAccount) {
        return isRequesting(deleteAccountRequestState) ? null : <Redirect to={routes.notFound} />;
    } else if (isAccountsLoaded && !settlementAccounts.length && !activeSettlementAccountId && readonly) {
        return <SettlementAccountsEmptyView onCreate={onAccountCreate} />;
    }

    return (
        <Preloader inProgress={!isAccountsLoaded}>
            {
                isAccountsLoaded &&
                <div className="container-flex flex-direction-row">
                    <div className="settlement-accounts-sidebar">
                        <SettlementAccountSelectionList
                            activeSettlementAccountId={activeSettlementAccountId}
                            settlementAccounts={settlementAccounts}
                            addAccountDisabled={!readonly}
                            onChange={onAccountChange}
                            onCreate={onAccountCreate}
                        />
                    </div>
                    {children(activeSettlementAccount)}
                </div>
            }
        </Preloader>
    );
}
