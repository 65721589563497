import cn from 'classnames'
import { SettlementAccount } from '../../types/settlement/SettlementAccount';
import IconSVG from "../../styles/svg-icons";
import { ShowFor } from '../access';
import { roles } from '../../constants';
import { OnHoverTooltip } from '../common';

interface Props {
    activeSettlementAccountId?: number;
    settlementAccounts: SettlementAccount[];
    addAccountDisabled: boolean;
    onChange: (account: SettlementAccount) => void;
    onCreate?: () => void;
}

const maxAccountNumber = 200;

export function SettlementAccountSelectionList(
    {
        activeSettlementAccountId,
        settlementAccounts,
        addAccountDisabled,
        onChange,
        onCreate
    }: Props) {
    const isAccountLimitExceeded = settlementAccounts.length >= maxAccountNumber;

    return (
        <ul className="settlement-accounts-list">
            {
                settlementAccounts.map(a =>
                    <ListItem
                        key={a.id}
                        settlementAccount={a}
                        active={activeSettlementAccountId === a.id}
                        onClick={() => onChange(a)}
                    />
                )
            }
            <ShowFor roles={roles.bd()}>
                <li className="control">
                    <OnHoverTooltip
                        placement="topLeft"
                        disabled={!isAccountLimitExceeded}
                        overlay="The maximum number of accounts have already been created."
                    >
                        <button
                            className="btn btn-ghost btn-full-width"
                            disabled={addAccountDisabled || isAccountLimitExceeded}
                            onClick={() => onCreate && onCreate()}
                        >
                            <IconSVG name="plus-sm" width={12} height={12} /> Account
                        </button>
                    </OnHoverTooltip>
                </li>
            </ShowFor>
        </ul>
    );
}


interface SettlementAccountProps {
    active: boolean;
    settlementAccount: SettlementAccount;
    onClick: () => void;
}

function ListItem({ settlementAccount, active, onClick }: SettlementAccountProps) {
    return (
        <li className={cn({ active })} onClick={active ? undefined : onClick}>
            {settlementAccount.accountName}
        </li>
    );
}
