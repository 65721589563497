import { useState } from 'react';
import { settlementService } from '../../../services/settlement.service';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../actions';
import { settlementAccountActions } from '../../../actions/settlement-account.actions';
import { RequestState } from '../../../constants/request-state';
import { useHistory } from 'react-router';
import { routes } from '../../../constants';

export const useSettlementAccountDelete = (assetManagerCompanyId: number) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [deleteAccountRequestState, setDeleteAccountRequestState] = useState(RequestState.none);

    const onDelete = async (accountId: number) => {
        setDeleteAccountRequestState(RequestState.request);
        try {
            await settlementService.deleteSettlementAccount(accountId);
            dispatch(settlementAccountActions.deleteAccount(accountId));
            history.replace(routes.clientsSettlementAccountUrl(assetManagerCompanyId))
            setDeleteAccountRequestState(RequestState.success);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            setDeleteAccountRequestState(RequestState.failure);
        }
    }
    return {deleteAccountRequestState, onDelete}
}
