import React, { Component } from 'react';
import { connect } from 'react-redux';
import { gridColumns } from '../../constants';
import { moneyUtils } from '../../utils';

class Securities extends Component {
    render = () => {
        const headers = this.props.headers.map(h =>
            <th className={'cell-' + h.name} key={h.name}>
                {h.title || h.name}
            </th>
        );
        const securities = this.props.securities.map(s => this.renderSecurity(s));

        return (
            <table className="table-submit-securities">
                <thead>
                    <tr>
                        {headers}
                    </tr>
                </thead>
                <tbody>
                    {securities}
                </tbody>
            </table>
        );
    }

    renderSecurity = security => {
        const { headers } = this.props;
        const cells = headers.map(h =>
            <td className={'cell-' + h.name} key={h.name}>
                {
                    h.name === gridColumns.size.name
                        ? moneyUtils.money(security[h.name])
                        : security[h.name]
                }
            </td>
        );

        return <tr key={security.isinCusip}>{cells}</tr>;
    }
}

const mapStateToProps = ({ grid }) => ({
    headers: grid.headers,
    securities: grid.dataItems.filter(s => !s.draft)
});

const connectedSecurities = connect(mapStateToProps)(Securities);
export { connectedSecurities as Securities };
