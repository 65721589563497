import { useState } from 'react';
import { SaveSettlementAccount } from '../../../types/settlement/SaveSettlementAccount';
import { settlementService } from '../../../services/settlement.service';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../actions';
import { RequestState } from '../../../constants/request-state';
import { settlementAccountActions } from '../../../actions/settlement-account.actions';

export const useSettlementAccountSave = () => {
    const dispatch = useDispatch();
    const [saveAccountRequestState, setSaveAccountRequestState] = useState(RequestState.none);

    const onSave = async (account: SaveSettlementAccount) => {
        setSaveAccountRequestState(RequestState.request);
        try {
            const updatedAccount = await settlementService.saveSettlementAccount(account);
            setSaveAccountRequestState(RequestState.success);
            await dispatch(settlementAccountActions.update(updatedAccount));
            return Promise.resolve(updatedAccount);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            setSaveAccountRequestState(RequestState.failure);
        }
    }
    return { saveAccountRequestState, onSave }
}
