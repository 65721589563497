import { useDispatch, useSelector } from "react-redux";
import IconSVG from "../../styles/svg-icons";
import { ChartBox, EmptyPlaceholder } from "../common";
import { AppState } from "../../types/state/AppState";
import { RequestState } from "../../constants/request-state";
import { dashboardChartBox } from "../../constants";
import { bwicLogActions } from "../../actions/bwic-log.actions";
import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { ChartRequiredFeature } from "../access/ChartRequiredFeature";
import { FeatureButton } from "../access/FeatureButton";
import { ActionBlocker } from "../access/ActionBlocker";
import { SecurityBwicStatisticsSummary } from "../../types/security/SecurityBwicStatisticsSummary";

const chartName = "Px Talk & Historical Color Dispersion";

interface Props {
    securityStatistics: SecurityBwicStatisticsSummary;
    securityId: number;
    evalPriceTalkVisible?: boolean;
}

export const PxTalkHistoricalColorDispersion = ({
    securityStatistics,
    securityId,
    evalPriceTalkVisible = false,
}: Props) => {
    const dispatch = useDispatch();
    const { exportDataRequestState } = useSelector(
        (s: AppState) => s.bwicLog
    );

    const hasChartData = !!securityStatistics.count;
    const isExporting = exportDataRequestState === RequestState.request;

    if (!hasChartData) {
        return (
            <div className="security-information-chart">
                <div className="security-information-empty">
                    <h4>{chartName}</h4>
                    <EmptyPlaceholder
                        textView={true}
                        text="No historical data found."
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="security-information-chart">
            <div className="flex-row">
                <h4>{chartName}</h4>
                <div className="flex-item-right">
                    <ActionBlocker
                        feature={SubscriptionFeature.BwicMonitorBwicsData}
                    >
                        <FeatureButton
                            className="btn-link"
                            type="button"
                            disabled={isExporting}
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                    bwicLogActions.bwicLogExportDataRequest(
                                        securityId
                                    )
                                );
                            }}
                        >
                            <IconSVG name="export" width={16} height={17} />
                            <span className="align-middle">Export Data</span>
                        </FeatureButton>
                    </ActionBlocker>
                </div>
            </div>
            <div className="security-information-graph">
                <ChartRequiredFeature
                    feature={SubscriptionFeature.BwicMonitorBwicsData}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-bwiclog"
                >
                    <ChartBox
                        historyList={securityStatistics.securities}
                        constants={dashboardChartBox}
                        divId={`portfolio-px-talk-${securityId}`}
                        evalPriceTalkVisible={evalPriceTalkVisible}
                    />
                </ChartRequiredFeature>
            </div>
        </div>
    );
};

