import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmBidRequestsPopup } from '../bid-as-dealer/confirm-bids/ConfirmBidRequestsPopup';
import { AppState } from '../../types/state/AppState';
import { sellerBuysideActions, SubmitBidRequestPayloadType } from '../../actions';
import { RequestState } from '../../constants/request-state';
import { ApiOperationType } from '../../types/api-operation/ApiOperationType';
import { useAgreement } from './useAgreement';
import { OpenBiddingStatus } from '../../types/enums/OpenBiddingStatus';
import { liveBiddingUtils } from '../../utils/live-bidding.utils';

export function SellerBuysideConfirmPopup() {
    const dispatch = useDispatch();
    const { agreement } = useAgreement();

    const isDirectBidOpetation = (e: ApiOperationType) =>
        e === ApiOperationType.SubmitAxedFinal ||
        e === ApiOperationType.SubmitBidRequest

    const positionId = useSelector((s: AppState) =>
        Object
            .keys(s.sellerBuyside.editBidRequestState)
            .map(key => +key)
            .find(positionId => s.sellerBuyside.editBidRequestState[positionId].isConfirming)
    );
    const editState = useSelector((s: AppState) => positionId && s.sellerBuyside.editBidRequestState[positionId]);
    const sendingDataItem = useSelector((s: AppState) => positionId && s.sellerBuyside.dataItems.find(i => i.position.id === positionId));
    const isSubmitting = useSelector((s: AppState) => positionId ? Boolean(s.sellerBuyside.requestStateSubmitBidRequest[positionId]) : false);
    const isSubmitSuccess = useSelector((s: AppState) =>
        s.apiOperation.requests.some(r => r.state === RequestState.success &&
            isDirectBidOpetation(r.event)))
    const isBiddingEndedError = useSelector((s: AppState) =>
        s.apiOperation.requests.some(r =>
            r.state === RequestState.failure &&
            r.result?.statusCode === 410 &&
            isDirectBidOpetation(r.event))
    );


    if (!positionId || !editState || !sendingDataItem) {
        return null;
    }

    const handleSubmit = () => {
        if (positionId && sendingDataItem && editState && agreement) {
            const { settlementAgent, level, commission } = editState;
            if (settlementAgent) {
                const data: SubmitBidRequestPayloadType = {
                    ...editState,
                    commission: commission.value,
                    level: Number(level.value),
                    agreementBrokerDealerId: settlementAgent.id,
                    agreementCommission: agreement.commissions[sendingDataItem.position.rating],
                    modifiedDate: sendingDataItem.bidAsDealerRequest?.modifiedDate
                };
                dispatch(sellerBuysideActions.submitBidRequest(data));
            }
        }
    };

    const handleClose = () => {
        dispatch(sellerBuysideActions.confirmBidRequestPopup(positionId, false));
        const { bwic, position } = sendingDataItem;
        const isStagedBiddingEnded = sendingDataItem.bwic.process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded;
        const isLiveBiddingTimerEnded = liveBiddingUtils.checkLiveBiddingStage2Expired(bwic.process, bwic.bidsDueDateUtc, position.latestBidSubmission);
        if (isSubmitSuccess || isBiddingEndedError || isStagedBiddingEnded || isLiveBiddingTimerEnded) {
            dispatch(sellerBuysideActions.resetPositionEditState(positionId));
        }
    }

    return (
        <ConfirmBidRequestsPopup
            isSubmitting={isSubmitting}
            bidRequests={sendingDataItem.bidAsDealerRequest ? [sendingDataItem.bidAsDealerRequest] : []}
            positions={[sendingDataItem.position]}
            process={sendingDataItem.bwic.process}
            bidsDueDateUtc={sendingDataItem.bwic.bidsDueDateUtc}
            improvements={[{
                positionId,
                level: editState.level.value!,
                axed: editState.axed,
                final: editState.final,
                commission: editState.commission.value,
                settlementAgent: editState.settlementAgent
            }]}
            onConfirmed={handleSubmit}
            onClose={handleClose}
            onReSubmitSingle={handleSubmit}
        />
    );
}
