import * as React from 'react';
import moment from 'moment';
import { constants } from '../../constants';
import { user } from '../../user/user';
import { SubscribeLink } from "../access/BlockedFeatureText";
import { BottomPanel, BottomPanelCloseButton } from "../controls/BottomPanel"
import { calculateSubscriptionTimeline } from '../../utils/subscription.utils';
import IconSVG from '../../styles/svg-icons';

export function TrialPeriodStartBottomPanel() {
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        const isShown = !!localStorage.getItem(constants.trialCongratulationsBottomPanelKey);
        const { isTrial, remainingDays } = calculateSubscriptionTimeline();
        const isTrialPeriodEnded = remainingDays && remainingDays < 1;

        if (!isShown && isTrial && !isTrialPeriodEnded) {
            setVisible(true);
        }
    }, []);

    const handleClose = () => {
        setVisible(false);
        try {
            localStorage.setItem(constants.trialCongratulationsBottomPanelKey, "true");
        } catch (e) {
            console.log('Local storage is unavailable');
        }
    }

    if (!visible) {
        return null;
    }

    const currentUser = user.current();

    return (
        <BottomPanel className='trial-enabled'>
            <div className="fixed-banner-icon">
                <IconSVG name="trial-enabled" width={24} height={24} />
            </div>
            <div className="fixed-banner-content">
                <h1 className="normal">Congratulations! Your FREE Trial of the KTX Pro plan has been enabled</h1>
                <p>Enjoy your KTX Pro plan free trial
                    until <b>{moment(currentUser?.trialPeriodEnd).format(constants.dateFormat5)}</b> with full feature
                    access. Subscribe to the Pro plan now to keep unlimited access.</p>
            </div>
            <div className="fixed-banner-control flex-row">
                <BottomPanelCloseButton onClick={handleClose} />
                <SubscribeLink text="Subscribe" btnClassName="btn btn-main btn-sm" />
            </div>
        </BottomPanel>
    );
}
