import * as React from 'react';
import { SettlementAccountBank } from '../../../types/settlement/SettlementAccountBank';
import { SettlementAccountBankAttentions } from './SettlementAccountBankAttentions';
import { OnHoverTooltip } from '../../common';
import { useCountries } from '../../../effects/data-accessors/useCountries';
import { constants } from '../../../constants';

interface Props {
    bank: SettlementAccountBank;
    index: number;
}

export function SettlementAccountBankView({ bank, index }: Props) {
    const { countries } = useCountries();

    const getCountryName = () => {
        const country = countries.find(c => c.code === bank.countryCode);
        return country ? country.name : constants.emptyPlaceholder
    }
    return (
        <>
            <div className="section-title">
                <h2>Bank #{index + 1}</h2>
            </div>
            <div className="main-fields align-items-flex-start flex-row">
                <dl className="definition-list">
                    <BankField title="Agent ID" text={String(bank.agentId || constants.emptyPlaceholder)} hide={!bank.agentId} />
                    <BankField title="Agent Intern AC" text={bank.agentInternAc || constants.emptyPlaceholder} />
                    <BankField title="DTC" text={String(bank.dtc || constants.emptyPlaceholder)} />
                    <BankField
                        title="Institutional ID"
                        text={String(bank.institutionalId || constants.emptyPlaceholder)}
                        hide={!bank.institutionalId}
                    />
                    <BankField title="CMTA" text={String(bank.cmta || constants.emptyPlaceholder)} hide={!bank.cmta} />
                    <BankField title="Eurolcear" text={String(bank.euroclear || constants.emptyPlaceholder)} hide={!bank.euroclear} />
                    <BankField title="Gov ABA #" text={String(bank.govAbaNumber || constants.emptyPlaceholder)} hide={!bank.govAbaNumber} />
                    <BankField
                        title="Gov Bank Acc #"
                        text={String(bank.govBankAccountNumber || constants.emptyPlaceholder)}
                        hide={!bank.govBankAccountNumber}
                    />
                </dl>
                <dl className="definition-list">
                    <BankField title="Original Account" text={String(bank.originalAccount || constants.emptyPlaceholder)} />
                    <BankField title="Short Name" text={String(bank.shortName || constants.emptyPlaceholder)} />
                    <BankField title="Tax ID" text={bank.taxId ?? ''} />
                    <BankField
                        title="Intst Party Custodian"
                        fullTitle="Interested Party Custodian"
                        text={String(bank.interestedPartyCustodian || constants.emptyPlaceholder)}
                        hide={!bank.interestedPartyCustodian}
                    />
                    <BankField
                        title="Intst Party ID" fullTitle="Interested Party ID"
                        text={String(bank.interestedPartyId || constants.emptyPlaceholder)}
                        hide={!bank.interestedPartyId}
                    />
                    <BankField title="LTID" text={String(bank.ltId || constants.emptyPlaceholder)} hide={!bank.ltId} />
                    <BankField
                        title="Mortgage ABA #"
                        text={String(bank.mortgageAbaNumber || constants.emptyPlaceholder)}
                        hide={!bank.mortgageAbaNumber}
                    />
                    <BankField
                        title="Mortgage Bank Acc #"
                        text={String(bank.mortgageBankAccountNumber || constants.emptyPlaceholder)}
                        hide={!bank.mortgageBankAccountNumber}
                    />
                </dl>
            </div>
            {
                !!bank.customFields?.length &&
                <div className="custom-fields">
                    <dl className="definition-list">
                        {bank.customFields.map(f => <CustomField key={f.id} title={f.name} text={f.value} />)}
                    </dl>
                </div>
            }
            {!!bank.attention.length && <SettlementAccountBankAttentions attentions={bank.attention} />}
            <div className="address-section">
                <h4>Address</h4>
                <div className="main-fields align-items-flex-start flex-row">
                    <dl className="definition-list">
                        <BankField title="Address Line 1" text={bank.address1 || constants.emptyPlaceholder} />
                        <BankField title="Address Line 2" text={bank.address2 || constants.emptyPlaceholder} />
                        <BankField title="Country" text={getCountryName()} />
                        <BankField title="State" text={bank.stateName || bank.stateAbbreviation || constants.emptyPlaceholder} />
                        <BankField title="City" text={bank.cityName || constants.emptyPlaceholder} />
                        <BankField title="ZIP Code" text={String(bank.zipCode || constants.emptyPlaceholder)} />
                    </dl>
                </div>
            </div>
        </>
    );
}

interface FieldProps {
    title: string;
    text: string;
    fullTitle?: string;
    hide?: boolean;
}

function CustomField({ title, text }: FieldProps) {
    return (
        <>
            <dt className="text-warm-grey">{title}</dt>
            <dd>{text}</dd>
        </>
    );
}

function BankField({ title, fullTitle, text, hide = false }: FieldProps) {
    if(hide) {
        return null;
    }
    return (
        <>
            <dt className="text-warm-grey">
                {fullTitle
                    ? <OnHoverTooltip overlay={fullTitle}>{title}</OnHoverTooltip>
                    : <>{title}</>
                }
            </dt>
            <dd>{text}</dd>
        </>
    );
}
