import * as React from 'react';
import { constants } from '../../constants';
import { SubscribeLink } from "../access/BlockedFeatureText";
import { BottomPanel, BottomPanelCloseButton } from "../controls/BottomPanel"
import { calculateSubscriptionTimeline } from '../../utils/subscription.utils';
import IconSVG from '../../styles/svg-icons';

export function TrialExpiredBottomPanel() {
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        const isShown = !!localStorage.getItem(constants.trialExpiredBottomPanelKey);
        const { isTrialEnded } = calculateSubscriptionTimeline();

        if (!isShown && isTrialEnded) {
            setVisible(true);
        }
    }, []);

    const handleClose = () => {
        setVisible(false);
        try {
            localStorage.setItem(constants.trialExpiredBottomPanelKey, "true");
        } catch (e) {
            console.log('Local storage is unavailable');
        }
    }

    if (!visible) {
        return null;
    }

    return (
        <BottomPanel className="trial-expired">
            <div className="fixed-banner-icon">
                <IconSVG name="trial-expired" width={24} height={24} />
            </div>
            <div className="fixed-banner-content">
                <h1 className="normal">Free Trial of the KTX Pro plan has expired</h1>
                <p>Your KTX Pro plan trial has ended. Your account is now on Basic plan. Your data remains, but key features are disabled. Subscribe to the Pro plan now for full access.</p>
            </div>
            <div className="fixed-banner-control flex-row">
                <BottomPanelCloseButton onClick={handleClose} />
                <div onClick={handleClose}>
                    <SubscribeLink text="Subscribe" btnClassName="btn btn-main btn-sm" />
                </div>
            </div>
        </BottomPanel>
    );
}
