import React, { LegacyRef, memo, useRef } from 'react';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import { isEqual } from 'lodash';
import MaskedInput from 'react-text-mask';
import { FormControlLoader } from '../../common';
import { useFocusOnError } from '../../../effects/useFocusOnError';

interface Props {
    name: string;
    label?: string;
    markRequired?: boolean;
    loading?: boolean;
    placeholder?: string;
    className?: string;
    formItemCustomClass?: string;
    pattern?: string;
    maxLength?: number;
    minLength?: number;
    mask?: Array<string | RegExp> | false;
}

interface InputProps extends Props {
    inputRef: LegacyRef<HTMLInputElement> | undefined;
    touched: boolean;
    error: string;
    submitCount: number;
    formikField: FieldInputProps<string>;
    mask?: Array<string | RegExp> | false;
}

const InputComponent: React.FC<InputProps> = (
    {
        inputRef,
        touched,
        formikField,
        error,
        submitCount,
        markRequired,
        className,
        formItemCustomClass,
        label,
        loading,
        mask,
        ...props
    }
) => (
    <div className={classNames("form-item", formItemCustomClass)}>
        <div className="form-control-wrapper">
            <label className="form-label">
                {label}&nbsp;{markRequired && <span className="text-red">*</span>}
            </label>
            <div className="form-control-wrapper">
                {
                    mask ? (
                        <MaskedInput
                            {...formikField}
                            value={formikField.value || ''}
                            className={classNames('form-control', { 'is-invalid': touched && error && submitCount }, className)}
                            mask={mask}
                            autoComplete="new-password"
                            list="autocompleteOff"
                            {...props}
                            keepCharPositions={true}
                        />
                    ) : (
                        <input
                            ref={inputRef}
                            className={classNames('form-control', { 'is-invalid': touched && error && submitCount }, className)}
                            {...formikField}
                            {...props}
                            value={formikField.value || ''}
                            autoComplete="new-password"
                            list="autocompleteOff" // disable auto-complete in IE & EDGE
                            type="text"
                        />
                    )
                }
                {touched && error && !!submitCount && <div className="form-error">{error}</div>}
                <FormControlLoader inProgress={loading} />
            </div>
        </div>
    </div>
);

const Input = memo(
    InputComponent,
    (prevProps: InputProps, nextProps: InputProps) =>
        isEqual(prevProps.formikField, nextProps.formikField) &&
        prevProps.name === nextProps.name &&
        prevProps.submitCount === nextProps.submitCount &&
        prevProps.error === nextProps.error &&
        prevProps.touched === nextProps.touched &&
        prevProps.placeholder === nextProps.placeholder
);

export const InputField: React.FC<Props> = (props) => {
    const fieldRef = useRef(null);
    const formik = useFormikContext();
    const [field, meta] = useField(props.name);

    useFocusOnError({ fieldRef, name: props.name });

    return (
        <Input
            inputRef={fieldRef}
            formikField={field}
            touched={meta.touched}
            error={meta.error || ''}
            submitCount={formik.submitCount}
            {...props}
        />
    )
}
