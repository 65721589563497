import moment from 'moment';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { constants } from '../../constants';
import { Preloader, OnHoverTooltip } from '../common';
import { AgencyRatingList, RatingMappingTable } from '../ratings';
import IconSVG from '../../styles/svg-icons';
import { IntexLink } from '../common/IntexLink';
import { BloombergLink } from '../common/BloombergLink';
import { useInvenotory } from '../../effects/data-accessors/useInventory';
import { dateTimeUtils, isRequesting, moneyUtils } from '../../utils';
import { ValitanaLink } from '../common/ValitanaLink';
import { AppState } from '../../types/state/AppState';
import { PeriodPopover } from '../amrPipeline/aggregated/PeriodPopover';
import { useSecurityDetails } from './useSecurityDetails';
import { SecurityIdentifierType } from '../../types/security/SecurityIdentifierType';
import { BwicPosition } from '../../types/bwic/BwicPosition';
import { collateralTypesAbbr } from '../../constants/collateral-types';
import { ManagerLink } from '../amrPipeline/common/ManagerLink';
import { ImDealLookupLink } from '../amrPipeline/common/ImDealLookupLink';
import { ViewDealDocumentsButton } from '../documents/deal-documents-popup/ViewDealDocumentButton';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';

interface Props {
    accessDealDocumentsFeature?: SubscriptionFeature
}
export function SecurityDetails({ accessDealDocumentsFeature }: Props) {
    const {
        security,
        agencyRatings,
        ratingMappings,
        initRequestState,
    } = useSelector((s: AppState) => s.securityDetails);
    const inventoryState = useInvenotory(security == null, [], security?.ticker ? security.ticker : '');
    const { securityDetails, securityDetailsRequestState } = useSecurityDetails(security?.securityId);

    if (!security) return null;

    const isRatingMappingsExist = Array.isArray(ratingMappings) && ratingMappings.length > 0;
    const isInitializing = isRequesting(initRequestState)
        || isRequesting(inventoryState.requestState)
        || isRequesting(securityDetailsRequestState);

    const renderDefinitionList = (...keyValues: Array<[name: string, value?: React.ReactNode]>) =>
        keyValues.map(([name, value]) =>
            <Fragment key={name}>
                <dt>{name}</dt>
                <dd>{value || constants.emptyPlaceholder}</dd>
            </Fragment>
        )

    const dealLegalName = security.dealLegalName || securityDetails?.dealLegalName;
    const collateralManager = security.collateralManager || securityDetails?.collateralManager;
    const trustee = security.trustee?.legalName || securityDetails?.trustee?.legalName;
    const collateralType = security.collateralType || securityDetails?.collateralType;
    const closingDate = security.closingDate || securityDetails?.closingDate;
    const startedMaturity = security.statedMaturity || securityDetails?.statedMaturity;
    const finalMaturity = security.finalMaturity || securityDetails?.finalMaturity;
    const maturity = startedMaturity || finalMaturity;
    const vintage = security.vintage || securityDetails?.vintage;
    const amr = security.amr || securityDetails?.amr;
    const esg = security.esg || securityDetails?.esg;
    const euCompliance = security.euCompliance || securityDetails?.euCompliance;
    const staticDeal = security.staticDeal || securityDetails?.staticDeal;
    const reinvestmentPeriodEnd = security.reinvestmentPeriodEnd || securityDetails?.reinvestmentPeriodEnd;
    const nonCallPeriodEnd = security.nonCallPeriodEnd || securityDetails?.nonCallPeriodEnd;

    return (
        <div className="security-information">
            <div className="security-information-main flex-row">
                <div className="flex-item security-isin">
                    <span>ISIN/CUSIP</span>
                    <span className="text-medium">{security.isinCusip}</span>
                </div>
                <div className="flex-item security-ticker">
                    <span>Ticker</span>
                    <span className="text-medium">{security.ticker}</span>
                </div>
                {!!security.size && (
                    <div className="flex-item security-asset">
                        <span>Size</span>
                        <span className="text-medium">{moneyUtils.money(security.size)}</span>
                    </div>
                )}
                <div className="flex-item security-currency">
                    <span>Rtg</span>
                    <span className="text-medium">{security.rating}</span>
                </div>
            </div>
            <div className="security-information-links">
                <div className="flex-row">
                    {
                        !!security.dealReferenceName &&
                        !!security.dealLegalName &&
                        <div className="flex-item">
                            <ImDealLookupLink
                                dealReferenceName={security.dealReferenceName}
                                dealLegalName={security.dealLegalName}
                            >
                                Related Trans
                            </ImDealLookupLink>
                        </div>
                    }
                    <div className="flex-item">
                        <IntexLink positionId={security.isinCusip} text="Intex" />
                    </div>
                    <div className="flex-item">
                        <BloombergLink identifier={security.isinCusip} textLink />
                    </div>
                    <div className="flex-item">
                        <ValitanaLink identifier={security.isinCusip} textLink />
                    </div>
                    <div className="flex-item">
                        <ViewDealDocumentsButton
                            security={security}
                            checkDocumentsExist={true}
                            requiredFeature={accessDealDocumentsFeature}
                        />
                    </div>
                </div>
            </div>
            <Preloader inProgress={isInitializing}>
                <div className="security-information-definition">
                    <h4>Deal</h4>
                    <dl className="definition-list">
                        {renderDefinitionList(
                            ['Deal Name', dealLegalName],
                            ['Manager', !!collateralManager && <ManagerLink
                                referenceName={collateralManager.referenceName}
                                legalName={collateralManager.legalName} />],
                            ['Trustee', trustee],
                            ['Collateral Type', collateralType ? collateralTypesAbbr[collateralType] : constants.emptyPlaceholder],
                            ['Ccy', security.currency],
                            ['Closing', closingDate ? moment(closingDate).format(constants.dateFormat) : constants.emptyPlaceholder],
                            ['NCE', nonCallPeriodEnd ? moment(nonCallPeriodEnd).format('MMM DD, YYYY') : constants.emptyPlaceholder]
                        )}
                        <dt>NCE, yrs</dt>
                        <dd>
                            {
                                <OnHoverTooltip
                                    overlay={
                                        <PeriodPopover
                                            reinvestmentPeriodEnd={reinvestmentPeriodEnd}
                                            nonCallPeriodEnd={nonCallPeriodEnd}
                                        />
                                    }
                                >
                                    <span>
                                        {dateTimeUtils.yrsLeftToDateFrom(nonCallPeriodEnd, closingDate, false) ||
                                            constants.emptyPlaceholder}
                                    </span>
                                </OnHoverTooltip>
                            }
                        </dd>
                        {renderDefinitionList(['RIE', reinvestmentPeriodEnd ? moment(reinvestmentPeriodEnd).format(constants.dateFormat) : constants.emptyPlaceholder])}
                        <dt>RIE, yrs</dt>
                        <dd>
                            {
                                <OnHoverTooltip
                                    overlay={
                                        <PeriodPopover
                                            reinvestmentPeriodEnd={reinvestmentPeriodEnd}
                                            nonCallPeriodEnd={nonCallPeriodEnd}
                                        />
                                    }
                                >
                                    <span>
                                        {dateTimeUtils.yrsLeftToDateFrom(reinvestmentPeriodEnd, closingDate, false) ||
                                            constants.emptyPlaceholder}
                                    </span>
                                </OnHoverTooltip>
                            }
                        </dd>
                        {renderDefinitionList(
                            ['Maturity', maturity ? moment(maturity).format(constants.dateFormat) : constants.emptyPlaceholder],
                            ['Vintage', vintage ? moment(vintage).format('YYYY') : constants.emptyPlaceholder],
                            ['Deal with AMR', amr ? 'Yes' : constants.emptyPlaceholder],
                            ['ESG', esg ? 'Yes' : constants.emptyPlaceholder],
                            ['EU Compliance', euCompliance ? 'Yes' : constants.emptyPlaceholder],
                            ['Static Deal', staticDeal ? 'Yes' : constants.emptyPlaceholder]
                        )}
                    </dl>
                </div>
                <div className="security-information-definition">
                    <h4>Class</h4>
                    <dl className="definition-list">
                        {renderDefinitionList(
                            ['Ticker (144a)', securityDetails?.identifiers[SecurityIdentifierType.Ticker144a]],
                            ['CUSIP (144a)', securityDetails?.identifiers[SecurityIdentifierType.CUSIP144a]],
                            ['ISIN (144a)', securityDetails?.identifiers[SecurityIdentifierType.ISIN144a]],
                            ['Ticker (Reg S)', securityDetails?.identifiers[SecurityIdentifierType.TickerRegS]],
                            ['CUSIP (Reg S)', securityDetails?.identifiers[SecurityIdentifierType.CUSIPRegS]],
                            ['ISIN (Reg S)', securityDetails?.identifiers[SecurityIdentifierType.ISINRegS]],
                            ['Ticker (Acc’d Inv/Certif.)', securityDetails?.identifiers[SecurityIdentifierType.TickerAccd]],
                            ['CUSIP (Acc’d Inv/Certif.)', securityDetails?.identifiers[SecurityIdentifierType.CUSIPAccd]],
                            ['ISIN (Acc’d Inv/Certif.)', securityDetails?.identifiers[SecurityIdentifierType.ISINAccd]],
                        )}
                        {
                            security.rating &&
                            <>
                                <dt>
                                    <span>Aggregated Rating</span>
                                    <OnHoverTooltip
                                        overlayClassName="rating-tooltip"
                                        placement="bottomRight"
                                        overlay={
                                            isRatingMappingsExist &&
                                            <RatingMappingTable ratingMappings={ratingMappings} />
                                        }
                                    >
                                        <Preloader inProgress={!isRatingMappingsExist}>
                                            <IconSVG name="info" width={16} height={16} />
                                        </Preloader>
                                    </OnHoverTooltip>
                                </dt>
                                <dd>{security.rating}</dd>
                            </>
                        }
                        <AgencyRatingList ratings={(security as BwicPosition).agencyRatings || agencyRatings} />
                    </dl>
                </div>
            </Preloader>
        </div>

    );
}

