import React, { useEffect } from "react";
import { values } from "lodash";
import { useHistory } from "react-router";
import { useInvenotory } from "../../effects/data-accessors/useInventory";
import { SecurityBwicStatistics } from "../../types/security/SecurityBwicStatistics";
import { LocationState, LocationStateBuilder, PopupType } from "../../types/state/ui/LocationState";
import { useBwicsSearch } from "../../effects/data-accessors/useBwicsSearch";
import { BwicStatus } from "../../types/enums/BwicStatus";
import { arrayUtils, isRequestSuccess, isRequesting } from "../../utils";
import { Popup, PopupBody, PopupFooter } from "../controls";
import { TabItem, TabList } from "../bidding/common/tab-list";
import { TabContent } from '../amrPipeline/common/tabs/TabContent';
import { TabBody } from '../amrPipeline/common/tabs/TabBody';
import { SecurityUsageBwicsTable, convert } from "./SecurityUsageBwicsTable";
import { SecurityUsageInventoryTable } from "./SecurityUsageInventoryTable";
import { StatusMessageSection } from "../status-message/StatusMessageSection";
import { StatusMessageSectionType } from "../../types/state/NotificationState";
import { Pluralize } from "../common";
import { user } from "../../user";
import { roles } from "../../constants";
import { BwicSearchResult } from "../../types/bwic-monitor/BwicSearchResult";

export enum SecurityUsagePopupTab {
    Bwics,
    Inventory
}

export interface BwicsHistory {
    [ticker: string]: SecurityBwicStatistics[];
}

export type TSecurityUsagePopupPayload = {
    tickers: string[];
    bwicsHistory?: BwicsHistory;
    activeTab?: SecurityUsagePopupTab;
}

interface Props {
    acceptedTabs?: SecurityUsagePopupTab[];
    disabled?: boolean;
    children: (isRequesting: boolean) => React.ReactNode;
    onConfirm: () => void;
}

export function SecurityUsagePopup({ disabled = false, ...props }: Props) {
    const history = useHistory<LocationState<undefined, TSecurityUsagePopupPayload>>();
    const { type, payload } = history.location?.state?.popup ?? {};
    const isPopupRequested = type === PopupType.SecurityUsage;

    const visible = !disabled && isPopupRequested && !!payload?.tickers?.length;

    return visible ? <SecurityUsagePopupContent {...props} /> : <>{props.children(false)}</>
}

function SecurityUsagePopupContent({
    children,
    onConfirm,
    acceptedTabs = [SecurityUsagePopupTab.Bwics, SecurityUsagePopupTab.Inventory]
}: Props) {
    const history = useHistory<LocationState<undefined, TSecurityUsagePopupPayload>>();
    const {
        tickers = [],
        bwicsHistory,
        activeTab = SecurityUsagePopupTab.Bwics
    } = history.location?.state?.popup?.payload ?? {};

    const inventoryVisible = acceptedTabs.some(t => t === SecurityUsagePopupTab.Inventory);
    // Request inventory if not provided
    const inventory = useInvenotory(!inventoryVisible, [], ...tickers);
    // Search bwics if history has not been passed
    const bwics = useBwicsSearch(
        tickers ?? [],
        [BwicStatus.scheduled, BwicStatus.bidding],
        bwicsHistory != null
    );

    useEffect(() => {
        // check is loading in progress
        if (inventoryVisible && !isRequestSuccess(inventory.requestState)) return;
        if (bwicsHistory == null && !isRequestSuccess(bwics.requestState)) return

        const hasInventory = inventoryVisible ? inventory.inventory.length > 0 : false;
        const hasBwics = values(bwicsHistory ?? {}).flat().length > 0 || bwics.bwics.length > 0

        //Auto-confirm if nothing to show
        if (!hasInventory && !hasBwics) {
            onConfirm();
        }

        // Set inventory tab active if no bwics
        if (!hasBwics && activeTab === SecurityUsagePopupTab.Bwics) {
            handleTabChange(SecurityUsagePopupTab.Inventory);
        }
        // eslint-disable-next-line
    }, [inventory, bwics, bwicsHistory, inventoryVisible, activeTab])

    const handleTabChange = (activeTab: SecurityUsagePopupTab) =>
        history.replace({
            ...history.location,
            state: new LocationStateBuilder(history.location.state)
                .popup(PopupType.SecurityUsage, { tickers, bwicsHistory, activeTab })
                .result()
        });

    const handleClose = () =>
        history.replace({
            ...history.location,
            state: new LocationStateBuilder(history.location.state).resetPopup().result()
        });

    const getSecuritiesInBwicsCount = (bwics: BwicSearchResult[]) => {
        const allTickers = bwics
            .map(b => b.securities)
            .flat()
            .map(s => s.ticker);

        return arrayUtils
            .distinct(allTickers, t => t)
            .filter(t => tickers.some(ti =>
                ti.localeCompare(t, undefined, { sensitivity: 'accent' }) === 0))
            .length;
    }

    return (
        <>
            {children(isRequesting(inventory.requestState, bwics.requestState))}
            {(
                    bwics.bwics.length > 0 ||
                    values(bwicsHistory ?? {}).flat().length > 0 ||
                    inventory.inventory.length > 0
                ) &&
                <Popup
                    modalClass="modal-security-usage"
                    title="Confirmation"
                    renderInBody={true}
                    onClose={handleClose}
                >
                    <PopupBody className="container-flex">
                        <Message
                            bwicCount={bwicsHistory ? values(bwicsHistory).flat().length : getSecuritiesInBwicsCount(bwics.bwics)}
                            inventoryCount={inventoryVisible ? arrayUtils.groupBy(inventory.inventory, i => i.ticker).size : 0}
                        />
                        <Tabs
                            acceptedTabs={acceptedTabs}
                            activeTab={activeTab}
                            bwicCount={bwicsHistory ? values(bwicsHistory).flat().length : bwics.bwics.length}
                            inventoryCount={inventory.inventory.length}
                            onTabChange={handleTabChange}
                        />
                        <TabContent>
                            <TabBody>
                                {
                                    activeTab === SecurityUsagePopupTab.Bwics &&
                                    <SecurityUsageBwicsTable
                                        items={bwicsHistory
                                            ? convert.formHistory(bwicsHistory)
                                            : convert.fromSearchResult(bwics.bwics)
                                        }
                                    />
                                }
                                {
                                    activeTab === SecurityUsagePopupTab.Inventory &&
                                    <SecurityUsageInventoryTable items={inventory.inventory} />
                                }
                            </TabBody>
                        </TabContent>
                    </PopupBody>
                    <PopupFooter>
                        <button className="btn btn-ghost" onClick={handleClose}>Cancel</button>
                        <button className="btn btn-main" onClick={onConfirm}>Create</button>
                    </PopupFooter>
                </Popup>
            }
        </>
    );
}


interface MessageProps {
    bwicCount: number;
    inventoryCount: number;
}


function Message({ bwicCount, inventoryCount }: MessageProps) {
    if (!bwicCount && !inventoryCount) return null;

    const security = (count: number) => <Pluralize count={count} singular="security" plural="securities" template="{{text}}" />
    const is = (count: number) => <Pluralize count={count} singular="is" plural="are" template="{{text}}" />
    const your = () => user.hasRoles(...roles.bd()) ? "your" : "Dealers";

    return (
        <StatusMessageSection type={StatusMessageSectionType.Alert}>
            <p>
                {!!bwicCount && <><b>{`${bwicCount} selected`} {security(bwicCount)}</b> {is(bwicCount)} currently a part of other BWIC(s)</>}
                {!!bwicCount && !!inventoryCount && <br />}
                {!!inventoryCount && <><b>{`${inventoryCount} selected`} {security(inventoryCount)}</b> {is(inventoryCount)} currently in {your()} Inventory</>}
            </p>
        </StatusMessageSection>

    );
}

interface TabsProps {
    acceptedTabs: SecurityUsagePopupTab[];
    activeTab: SecurityUsagePopupTab;
    bwicCount: number;
    inventoryCount: number;
    onTabChange: (tab: SecurityUsagePopupTab) => void;
}

function Tabs({ activeTab, bwicCount, inventoryCount, acceptedTabs, onTabChange }: TabsProps) {
    if (acceptedTabs.length <= 1) return null;

    const formatTitle = (text: string, count: number) => count ? `${text} (${count})` : text
    const inventory = () => user.hasRoles(...roles.bd()) ? 'Inventory' : 'Dealer Inventory';

    return (
        <TabList>
            {
                acceptedTabs.some(t => t === SecurityUsagePopupTab.Bwics) &&
                <TabItem
                    disabledTooltip="Among the selected securities, there are no that participate on a BWIC."
                    active={activeTab === SecurityUsagePopupTab.Bwics}
                    title={formatTitle('BWIC', bwicCount)}
                    disabled={!bwicCount}
                    onClick={() => onTabChange(SecurityUsagePopupTab.Bwics)}
                />
            }
            {
                acceptedTabs.some(t => t === SecurityUsagePopupTab.Inventory) &&
                <TabItem
                    active={activeTab === SecurityUsagePopupTab.Inventory}
                    title={formatTitle(inventory(), inventoryCount)}
                    disabled={!inventoryCount}
                    disabledTooltip={`Among the selected securities, there are no that would be added to ${inventory()}`}
                    onClick={() => onTabChange(SecurityUsagePopupTab.Inventory)}
                />
            }
        </TabList>
    );
}

