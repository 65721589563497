import { SettlementAccountBankFormModel } from './SettlementAccountBankForm';
import { BankOptionalFields, bankOptionalFieldsLabel } from '../../../types/settlement/BankOptionalFields';
import IconSVG from '../../../styles/svg-icons';
import React, { useState } from 'react';
import { values } from 'lodash';
import { DropDownList, DropDownListItem } from '../../controls/DropDownList';
import { ClickOutside } from '../../common';

interface Props {
    bank: SettlementAccountBankFormModel;
    onAddField: (field: string) => void;
    onAddCustomField: () => void;
    isVisible: boolean;
}

const newCustomFieldKey = 'newCustomField';

export const SettlementAccountBankAddOptionalFields = ({ bank, onAddField, onAddCustomField, isVisible = false }: Props) => {
    const [selectVisible, setSelectVisible] = useState(isVisible);

    const optionalFields = values(BankOptionalFields);

    const filterOptions = (option: string) => bank[option as keyof typeof bank] === undefined;

    const handleAddField = (field: string) => {
        if (field === newCustomFieldKey) {
            onAddCustomField();
        } else {
            onAddField(String(field))
        }
    }

    const getDropDownItems = () => {
        const items: DropDownListItem[] = [...optionalFields]
            .filter(filterOptions)
            .sort((a, b) => a.localeCompare(b))
            .map((field) => ({
                value: String(field),
                text: bankOptionalFieldsLabel[field as keyof typeof bankOptionalFieldsLabel].option,
                selected: false,
            }))
        items.push({
            value: newCustomFieldKey,
            text: 'Add Custom Text',
            selected: false,
        })
        return items
    }

    return (
        <ClickOutside onClick={() => setSelectVisible(false)}>
            <div className="form-row form-row-inline">
                <div className="form-cell">
                    <div className="form-control-wrapper">
                        <div className="form-label">
                            <button
                                disabled={selectVisible}
                                className="btn-link"
                                type="button"
                                onClick={() => setSelectVisible(true)}
                            >
                                <IconSVG name="plus" width={16} height={16} /> Add Field
                            </button>
                        </div>
                        {(selectVisible || isVisible) && (
                            <div className="form-control-wrapper">
                                <DropDownList
                                    placeholder="Select Field"
                                    items={getDropDownItems()}
                                    defaultSortingEnabled={false}
                                    onChange={(e) => handleAddField(String(e.value))}
                                    renderItemContentCallback={(item) => {
                                        if (item.value === newCustomFieldKey) {
                                            return <><IconSVG name="plus" width={16} height={16} />Add Custom Field</>
                                        }
                                        return <>{item.text}</>
                                    }}
                                    className="custom-drop-down custom-drop-down-filter"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ClickOutside>
    )
}
