import { rulesActions } from "../../actions";
import { useAppSelector } from "../../effects/useAppSelector";
import { Process } from "../../types/models/Process";
import { useAppDispatch } from '../../effects/useAppDispatch';
import { BwicRulesPseudoLink } from "../common/BwicRulesPseudoLink";
import { ParsedBwicProcess } from "../../types/models/ParsedBwicProcess";

interface SellerBuySideRulesButtonProps {
    process: Process;
    parsedProcess?: ParsedBwicProcess;
    isParsed: boolean;
    positionId: number;
    showLabel?: boolean;
}

export const SellerBuySideRulesButton = ({ process, parsedProcess, isParsed, positionId, showLabel }: SellerBuySideRulesButtonProps) => {
    const dispatch = useAppDispatch();
    const visible = useAppSelector(s => s.rules.show);
    const rulesPositionId = useAppSelector(s => s.rules.positionId);

    const handleClick = () => {
        if (visible && positionId === rulesPositionId) {
            dispatch(rulesActions.hide());
        } else {
            dispatch(rulesActions.show(positionId, true));
        }
    }

    return (
        <div className="bwic-rules-link">
            <BwicRulesPseudoLink
                isParsed={isParsed}
                process={process}
                parsedProcess={parsedProcess}
                showLabel={showLabel}
                onClick={handleClick}
            />
        </div>
    )
}
