import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { MissingAgreementTooltip } from '../agreements/MissingAgreementTooltip';
import { HistoryButton } from '../bwic-history/HistoryButton'
import { bwicHistoryActions } from '../../actions';
import { useAgreement } from './useAgreement';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props {
    bwicReferenceName: string;
    positionId: number;
    ticker: string;
}

export function SellerBuysideHistoryButton({ bwicReferenceName, positionId, ticker }: Props) {
    const dispatch = useAppDispatch();
    const { agreement } = useAgreement();

    const handleClick = (visible: boolean) => {
        if (visible) {
            dispatch(bwicHistoryActions.loadHistory(bwicReferenceName, positionId));
        }
    }

    return (
        <MissingAgreementTooltip agreement={agreement}>
            <HistoryButton
                positionId={positionId}
                ticker={ticker}
                onClick={handleClick}
                unreadCountVisble={false}
                disabled={!(agreement?.agreementStatus === SettlementAgreementStatus.confirmed)}
            />
        </MissingAgreementTooltip>
    )
}
