import React, { memo } from 'react';
import { FieldArray } from 'formik';
import { InputField } from './Input';
import { constants } from '../../../constants';
import { SaveSettlementAccountBankAttention } from '../../../types/settlement/SaveSettlementAccount';
import IconSVG from "../../../styles/svg-icons";

interface Props {
    name: string;
    attentions: SaveSettlementAccountBankAttention[]
}

const initialBankAttnItem: SaveSettlementAccountBankAttention = {
    name: '',
    email: '',
    phone: '',
}

const SettlementAccountBankAttentionComponent: React.FC<Props> = ({ name, attentions }) => {
    return (
        <FieldArray name={name}>
            {({ push, remove }) => (
                <>
                    {!attentions.length && (
                        <div className="flex-row">
                            <button
                                className="btn-link"
                                type="button"
                                onClick={() => push({ ...initialBankAttnItem })}
                            >
                                <IconSVG name="plus" width={16} height={16} /> Add Attn
                            </button>
                        </div>
                    )}

                    {!!attentions.length && <h4>Attn</h4>}
                    {attentions.map((attention, index) => (
                        <div className="form-row form-row-inline custom-field" key={`attn-${index}`}>
                            <InputField
                                formItemCustomClass="custom-field-full-name"
                                label="Full Name"
                                placeholder="Full Name"
                                markRequired={true}
                                name={`${name}.${index}.name`}
                                pattern={constants.name.source}
                                loading={false}
                                maxLength={64}
                            />
                            <InputField
                                formItemCustomClass="custom-field-email"
                                label="Email"
                                placeholder="Email"
                                markRequired={true}
                                name={`${name}.${index}.email`}
                                pattern={constants.name.source}
                                loading={false}
                                maxLength={64}
                            />
                            <InputField
                                formItemCustomClass="custom-field-phone"
                                label="Phone"
                                placeholder="Phone"
                                name={`${name}.${index}.phone`}
                                pattern={constants.name.source}
                                loading={false}
                                maxLength={20}
                            />
                            <button className="btn-link btn-danger" type="button" onClick={() => remove(index)}>
                                <i className="icon icon-delete" />
                            </button>
                        </div>
                    ))}
                    {!!attentions.length && (
                        <div className="flex-row">
                            <button
                                className="btn-link"
                                type="button"
                                onClick={() => push({ ...initialBankAttnItem })}
                            >
                                <IconSVG name="plus" width={16} height={16} /> Add New Line
                            </button>
                        </div>
                    )}
                </>
            )}
        </FieldArray>
    )
}

export const SettlementAccountBankAttention = memo(SettlementAccountBankAttentionComponent)
