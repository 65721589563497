import React, { memo } from 'react';
import { isEqual, values } from 'lodash';
import { SettlementAccountBankCustomField } from './SettlementAccountBankCustomField';
import { SettlementAccountBankAttention } from './SettlementAccountBankAttention';
import { InputField } from './Input';
import { SettlementAccountBankFormModel } from './SettlementAccountBankForm';
import { BankOptionalFields, bankOptionalFieldsLabel, bankOptionalFieldMaxLength } from '../../../types/settlement/BankOptionalFields';
import { SettlementAccountBankOptionalField } from './SettlementAccountBankOptionalField';
import cn from 'classnames';
import { State } from '../../../types/location/State';
import { useCities } from '../../../effects/data-accessors/useCities';
import { AutoCompleteInput, FormError } from '../../controls';
import { constants } from '../../../constants';
import { FormikErrors } from 'formik';
import { useCountries } from '../../../effects/data-accessors/useCountries';

interface Props {
    bank: SettlementAccountBankFormModel;
    name: string;
    index: number;
    onDelete: () => void;
    canDelete: boolean;
    onDeleteCustomField: (field: string) => void;
    onAddCustomField: (field: string) => void;
    states: State[];
    setFieldValue: (fieldName: string, value: string) => void;
    isSubmit: boolean;
    errors: string | FormikErrors<SettlementAccountBankFormModel> | undefined;
}

const SettlementAccountBankComponent: React.FC<Props> = ({
    bank,
    index,
    name,
    onDelete,
    canDelete,
    onDeleteCustomField,
    onAddCustomField,
    states,
    setFieldValue,
    isSubmit,
    errors,
}) => {
    const { countries } = useCountries();
    const { cities } = useCities(Number(bank.stateId));
    const isUSACountryCode = !bank.countryCode || bank.countryCode === constants.USCountryCode;

    const handleCityChange = (cityName: string) => {
        const selectedCity = cities.find(c => c.name.localeCompare(cityName, undefined, { sensitivity: 'accent' }) === 0);
        const cityId = selectedCity && selectedCity.id;
        setFieldValue(`${name}.cityName`, cityName)
        setFieldValue(`${name}.cityId`, cityId ? String(cityId) : '')
    };

    const isAnyOptionalField =  !!values(BankOptionalFields).filter(field => bank[field] !== undefined).length

    const renderAdditionalFields = () => {
        return values(BankOptionalFields)
            .filter(field => bank[field] !== undefined)
            .sort((a, b) => a.localeCompare(b))
            .map(field => (
                <SettlementAccountBankOptionalField
                    key={field}
                    onDelete={() => onDeleteCustomField(`${name}.${field}`)}
                >
                    <InputField
                        label={bankOptionalFieldsLabel[field as keyof typeof bankOptionalFieldsLabel].label}
                        placeholder={bankOptionalFieldsLabel[field as keyof typeof bankOptionalFieldsLabel].option}
                        markRequired={false}
                        name={`${name}.${field}`}
                        maxLength={bankOptionalFieldMaxLength[field as keyof typeof bankOptionalFieldsLabel]}
                    />
                </SettlementAccountBankOptionalField>
            ))
    }

    return (
        <>
            <div className="section-title">
                <h2 className="display-inline">{`Bank #${index + 1}`}</h2>
                <button className="btn-link btn-danger" disabled={!canDelete} type="button" onClick={onDelete}>
                    <i className="icon icon-delete" />
                </button>
            </div>
            <div className="main-fields">
                <div className="form-row form-row-inline">
                    <div className="form-cell">
                        <InputField
                            label="Agent Id"
                            placeholder="Agent Id"
                            name={`${name}.agentId`}
                            maxLength={6}
                        />
                    </div>
                    <div className="form-cell">
                        <label className="form-label">
                            Original Account
                        </label>
                        <span className="original-account">
                            {bank.originalAccount || <em>Automatically generated value</em>}
                        </span>
                    </div>
                    <div className="form-cell">
                        <InputField
                            label="Agent Intern AC"
                            placeholder="Agent Intern AC"
                            name={`${name}.agentInternAc`}
                            maxLength={12}
                        />
                    </div>
                    <div className="form-cell">
                        <InputField
                            label="Short Name"
                            placeholder="Short Name"
                            name={`${name}.shortName`}
                            maxLength={10}
                        />
                    </div>
                    <div className="form-cell">
                        <InputField
                            label="DTC"
                            placeholder="DTC"
                            name={`${name}.dtc`}
                            maxLength={4}
                        />
                    </div>
                    <div className="form-cell">
                        <InputField
                            label="Tax ID"
                            placeholder="Tax ID"
                            name={`${name}.taxId`}
                            maxLength={15}
                        />
                    </div>
                    <div className="form-cell">
                        <InputField
                            label="Institutional ID"
                            placeholder="Institutional ID"
                            name={`${name}.institutionalId`}
                            maxLength={10}
                        />
                    </div>
                    {renderAdditionalFields()}
                </div>
            </div>

            <div className="custom-fields no-brd">
                <SettlementAccountBankCustomField
                    dropDownVisible={isAnyOptionalField}
                    customFields={bank.customFields}
                    name={`${name}.customFields`} bank={bank}
                    onAddField={(field) => onAddCustomField(`${name}.${field}`)}
                />
            </div>
            <div className="attn-section">
                <SettlementAccountBankAttention attentions={bank.attention} name={`${name}.attention`} />
            </div>

            <div className="address-section">
                <h4>Address</h4>
                <div className="main-fields">
                    <div className="form-cell full">
                        <InputField
                            label="Address Line 1"
                            placeholder="Address Line 1"
                            name={`${name}.address1`}
                            maxLength={40}
                        />
                    </div>
                    <div className="form-cell full">
                        <InputField
                            label="Address Line 2"
                            placeholder="Address Line 2"
                            name={`${name}.address2`}
                            maxLength={40}
                        />
                    </div>
                    <div className="form-row form-row-inline add-address">
                        <div className="form-cell">
                            <div className="form-item">
                                <div className="form-control-wrapper">
                                    <label className="form-label" htmlFor="country">Country</label>
                                    <select
                                        className={cn('form-control form-select')}
                                        name="countryCode"
                                        required
                                        value={bank.countryCode || ''}
                                        onChange={(e) => {
                                            setFieldValue(`${name}.countryCode`, e.target.value);
                                            setFieldValue(`${name}.state`, '');
                                            setFieldValue(`${name}.stateId`, '');
                                            setFieldValue(`${name}.zipCode`, '');
                                            setFieldValue(`${name}.cityName`, '');
                                            setFieldValue(`${name}.cityId`, '');
                                        }}
                                    >
                                        <option value="" disabled>Select Country</option>
                                        {countries.map(c =>
                                            <option key={c.code} value={c.code}>{c.name}</option>
                                        )}
                                    </select>
                                    <FormError message={isSubmit && typeof errors === 'object' && errors?.countryCode} />
                                </div>
                            </div>
                        </div>
                        <div className="form-cell">
                            <div className="form-item">
                                <div className="form-control-wrapper">
                                    <label className="form-label">City</label>
                                    <div className="form-control-wrapper">
                                        <AutoCompleteInput
                                            name="cityId"
                                            value={bank.cityName}
                                            sourceItems={cities.map(c => c.name)}
                                            pattern={constants.name.source}
                                            placeholder="City"
                                            emptyMessage=""
                                            isInvalid={isSubmit && typeof errors === 'object' && errors?.cityName}
                                            onChange={handleCityChange}
                                            maxLength={20}
                                        />
                                        <FormError message={isSubmit && typeof errors === 'object' && errors?.cityName} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-cell">
                            {isUSACountryCode ?(
                                <div className="form-item">
                                    <div className="form-control-wrapper">
                                        <label className="form-label" htmlFor="state">State</label>
                                        <select
                                            className={cn(
                                                'form-control form-select',
                                                { 'is-invalid': isSubmit && typeof errors === 'object' && errors?.stateId }
                                            )}
                                            name={`${name}.stateId`}
                                            value={bank.stateId || ''}
                                            onChange={(e) => setFieldValue(`${name}.stateId`, e.target.value)}
                                        >
                                            <option value="" disabled>Select State</option>
                                            {states.map(s =>
                                                <option key={s.id} value={s.id}>
                                                    {s.abbreviation}
                                                </option>)
                                            }
                                        </select>
                                        <FormError message={isSubmit && typeof errors === 'object' && errors?.stateId} />
                                    </div>
                                </div>
                            ) : (
                                <InputField
                                    label="State/Province"
                                    placeholder="Enter State"
                                    name={`${name}.state`}
                                    maxLength={40}
                                />
                            )}
                       </div>

                        <div className="form-cell">
                            <InputField
                                label="ZIP/Postal Code"
                                placeholder={isUSACountryCode ? 'Enter ZIP' : 'Enter ZIP/Postal Code'}
                                name={`${name}.zipCode`}
                                maxLength={isUSACountryCode ? constants.USZipCodeSize : constants.PostalCodeSize}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const SettlementAccountBank = memo(
    SettlementAccountBankComponent,
    (prevProps: Props, nextProps: Props) =>
        isEqual(prevProps.bank, nextProps.bank) &&
        isEqual(prevProps.states, nextProps.states) &&
        isEqual(prevProps.errors, nextProps.errors) &&
        prevProps.name === nextProps.name &&
        prevProps.isSubmit === nextProps.isSubmit &&
        prevProps.canDelete === nextProps.canDelete
);
