import * as React from 'react';
import { useDispatch } from 'react-redux';
import { sellerBuysideActions } from '../../actions';
import { SellerBuysideSearchResult } from '../../types/bid-as-dealer/SellerBuysideSearchResult';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { OpenBiddingStatus } from '../../types/enums/OpenBiddingStatus';
import { BwicProcessType } from '../../types/models/Process';
import { isActiveTrade } from '../../types/trades/TradeStatus';
import { LiveBiddingStage2Timer } from '../bidding/common/LiveBiddingStage2Timer';
import { BwicStatusLabel } from '../common';
import { BwicStatusTimerLabel } from '../common/BwicStatusTimerLabel';

interface Props {
    entity: SellerBuysideSearchResult;
}

export function SellerBuysideBwicStatusTimerLabel({ entity }: Props) {
    const dispatch = useDispatch();

    if (entity.position.isTradedAway || isActiveTrade(entity.position.trade)) {
        return <BwicStatusLabel
            status={entity.bwic.status}
            directBidding={entity.bwic.isClearingBankParticipant}
            isParsed={entity.bwic.isParsed}
            liveBidding={entity.bwic.process.type === BwicProcessType.Live}
            bwicTypeIconVisible={false}
        />
    }

    const isBidding = entity.bwic.status === BwicStatus.bidding;
    const isLiveBidding = entity.bwic.process.type === BwicProcessType.Live;
    const isLiveBiddingStage2 =
        isBidding &&
        isLiveBidding &&
        entity.bwic.process.stagedBiddingStatus === OpenBiddingStatus.stage1Ended;

    if (isLiveBiddingStage2) {
        return <LiveBiddingStage2Timer
            bidsDueDateUtc={entity.bwic.bidsDueDateUtc}
            canBidOnStage2={Boolean(entity.position.isStage2Participant)}
            process={entity.bwic.process}
            latestBidSubmission={entity.position.latestBidSubmission}
            renderEmpty={
                <BwicStatusLabel
                    status={entity.bwic.status}
                    directBidding={true}
                    isParsed={entity.bwic.isParsed}
                    liveBidding={true}
                    bwicTypeIconVisible={false}
                    isAllToAll={false}
                />
            }
            onExpired={() => dispatch(sellerBuysideActions.liveBiddingStage2TimerExpired(entity.position.id))}
        />
    }

    return <BwicStatusTimerLabel
        status={entity.bwic.status}
        liveBidding={isLiveBidding}
        isParsed={entity.bwic.isParsed}
        directBidding={entity.bwic.isClearingBankParticipant}
        bidsDueUtc={entity.bwic.bidsDueDateUtc}
        goodUntilUtc={entity.bwic.goodUntilDateUtc}
        process={entity.bwic.process}
        bwicTypeIconVisible={false}
        isAllToAll={entity.bwic.isAllToAll}
    />
}
