import { useSettlementAgentAgreements } from '../../effects/useSettlementAgentAgreements';

export const useAgreement = () => {
    const { items, requestState } = useSettlementAgentAgreements();

    return {
        agreement: items[0],
        requestState
    };
}
