import { constants, roles } from "../../constants";
import { Bid } from '../bid-as-dealer/submit-bid/Bid';
import { SellerBuysideSearchResult } from '../../types/bid-as-dealer/SellerBuysideSearchResult';
import { MyBidRequest } from '../bid-as-dealer/MyBidRequest';
import { biddingUtils, formatUtils, numericUtils } from '../../utils';
import { AppState } from '../../types/state/AppState';
import { useDispatch, useSelector } from 'react-redux';
import { sellerBuysideActions } from '../../actions';
import { BwicProcessType } from '../../types/models/Process';
import { useAgreement } from './useAgreement';
import { getCanBidFlag } from './getCanBidFlag';
import { SellerBuySideCommission } from './SellerBuysideCommission';
import { RoleActionBlocker } from '../access/RoleActionBlocker';

interface Props {
    entity: SellerBuysideSearchResult;
    disabled?: boolean;
}

export function SellerBuySideBid({ entity, disabled = false }: Props) {
    const dispatch = useDispatch();
    const bidAsDealerRequest = entity.bidAsDealerRequest;
    const editState = useSelector((s: AppState) => s.sellerBuyside.editBidRequestState[entity.position.id]);

    const { agreement } = useAgreement();

    const levelRaw = editState?.level.valueRaw ?? formatUtils.formatBid(bidAsDealerRequest?.level, '');
    const level = editState?.level.value ?? bidAsDealerRequest?.level;
    const errorMessage = editState?.level?.error ?? '';
    const axed = editState?.axed ?? bidAsDealerRequest?.axed;
    const final = editState?.final ?? bidAsDealerRequest?.final;

    const canBidFlag = getCanBidFlag(entity, agreement);

    const validate = (level: number) => {
        const error = biddingUtils.validateBid(
            entity.bidAsDealerRequest?.directBid,
            { value: level.toString() },
            entity.bwic.process,
            entity.position.openBiddingStage2Level
        );

        return error.bid ?? '';
    };

    const handleBidChange = (level: string | undefined, axed: boolean, final: boolean) => {
        if (agreement) {
            if (level != null && level !== levelRaw) {
                dispatch(sellerBuysideActions.levelChange(
                    entity.position.id,
                    level,
                    numericUtils.numberOrDefault(level),
                    '',
                    agreement)
                );
            }
            if (axed !== editState?.axed) {
                dispatch(sellerBuysideActions.axedChange(entity.position.id, axed, agreement));
            }
            if (final !== editState?.final) {
                dispatch(sellerBuysideActions.finalChange(entity.position.id, final, agreement));
            }
        }
    };

    const handleOnLeave = () => {
        if (agreement) {
            // Reset to default if 0 or empty
            if (!Number(levelRaw)) {
                dispatch(sellerBuysideActions.levelChange(
                    entity.position.id,
                    formatUtils.formatBid(entity?.bidAsDealerRequest?.level),
                    entity?.bidAsDealerRequest?.level,
                    '',
                    agreement
                ));
            } else {
                const error = validate(Number(level));
                dispatch(sellerBuysideActions.levelChange(
                    entity.position.id,
                    formatUtils.formatBid(Number(levelRaw)),
                    level,
                    error,
                    agreement
                ));
            }
        }
    };

    const handleRevert = () => {
        if (agreement) {
            dispatch(sellerBuysideActions.levelChange(
                entity.position.id,
                formatUtils.formatBid(bidAsDealerRequest?.level),
                bidAsDealerRequest?.level,
                '',
                agreement
            ));

            dispatch(sellerBuysideActions.axedChange(entity.position.id, bidAsDealerRequest?.axed || false, agreement));
            dispatch(sellerBuysideActions.finalChange(entity.position.id, bidAsDealerRequest?.final || false, agreement));
        }
    }

    if (canBidFlag) {
        return (
            <div className="flex-row justify-content-end">
                <RoleActionBlocker roles={[roles.SellerTrader]}>
                    <Bid
                        originalBid={bidAsDealerRequest?.level || 0}
                        error={errorMessage}
                        bid={levelRaw}
                        axed={axed}
                        final={final}
                        disabled={disabled}
                        bidAttributesVisible={
                            entity.bwic.process.type === BwicProcessType.Standard ||
                            entity.bwic.process.type === BwicProcessType.BestFootForward
                        }
                        renderCommission={() =>
                            <SellerBuySideCommission
                                entity={entity}
                                agreement={agreement}
                                disabled={disabled}
                            />
                        }
                        onBidChange={handleBidChange}
                        onLeave={handleOnLeave}
                        onRevert={handleRevert}
                    />
                </RoleActionBlocker>
            </div>
        );
    }

    // readonly
    return entity.bidAsDealerRequest ? (
        <div className="seller-buy-side-bid justify-content-end">
            <MyBidRequest
                level={entity.bidAsDealerRequest.level}
                axed={entity.bidAsDealerRequest.axed}
                final={entity.bidAsDealerRequest.final}
                commission={entity.bidAsDealerRequest.commission}
            />
        </div>
    ) : <div className="flex-row justify-content-end">
            {constants.emptyPlaceholder} <div className="bid-attributes" />
        </div>;
}
