import { SettlementAccount } from '../../../types/settlement/SettlementAccount';
import { DocumentList } from '../../documents/DocumentList';
import { SettlementAccountHeader } from '../SettlementAccountHeader';
import { SettlementAccountBankView } from './SettlementAccountBankView';
import { ShowFor } from '../../access';
import { roles } from '../../../constants';
import { DocumentStoreType } from '../../../types/document/Document';

interface Props {
    settlementAccount: SettlementAccount;
    onEdit?: () => void;
    onDelete?: () => void;
    disabled?: boolean;
}

export function SettlementAccountView({ settlementAccount, onEdit, onDelete, disabled }: Props) {
    return (
        <div className="settlement-accounts-content">
            <SettlementAccountHeader accountName={settlementAccount.accountName}>
                <ShowFor roles={roles.bd()}>
                    <div className="flex-item-right controls">
                        <button className="btn-link btn-danger" onClick={() => onDelete && onDelete()} disabled={disabled}>
                            <i className="icon icon-delete" /> Delete Account
                        </button>
                        <button className="btn btn-ghost btn-sm" onClick={() => onEdit && onEdit()} disabled={disabled}>
                            Edit
                        </button>
                    </div>
                </ShowFor>
            </SettlementAccountHeader>
            <div className="settlement-accounts-sections">
                <div className="settlement-accounts-sections-center">
                    <div className="main-fields align-items-flex-start flex-row">
                        <dl className="definition-list">
                            <dt className="text-warm-grey">Mirae Acc #</dt>
                            <dd>{settlementAccount.miraeAcc}</dd>
                        </dl>
                    </div>
                    <div className="settlement-accounts-sections-list">
                        <div className="section">
                            {settlementAccount.banks.map((b, index) =>
                                <SettlementAccountBankView key={b.id} bank={b} index={index} />)
                            }
                        </div>
                        <DocumentList
                            readonly={true}
                            documents={settlementAccount.documents}
                            documentStoreType={DocumentStoreType.Settlement}
                            documentGroupKey={String(settlementAccount.id)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
