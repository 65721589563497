import { routes } from '../../constants';
import { Redirect, useHistory, useLocation } from 'react-router';
import { WizardHeader } from '../newBWIC';
import { BwicDateTime } from './BwicDateTime';

export const BwicSubmitted = () => {
    const history = useHistory();

    const state = useLocation<{ bidsDue?: Date, date?: Date, timeZone?: string, bwicReferenceName?: string }>().state;

    if (!state?.bidsDue || !state?.bwicReferenceName || !state?.date || !state?.timeZone) {
        return <Redirect to={routes.newBWIC} />
    }

    return (
        <>
            <div className="sub-header">
                <WizardHeader
                    isCompleted={true}
                    isStepNavigationDisabled={true}
                    onSubmit={() => null}
                />
            </div>
            <div className="container-submit container-flex">
                <div className="container-submit-box">
                    <h2>BWIC was created</h2>
                    <p>
                        Selected participants have been notified via email.<br />
                        The BWIC will begin on 
                        <BwicDateTime 
                            bidsDue={state.bidsDue} 
                            date={state.date} 
                            timeZone={state.timeZone} 
                            />
                    </p>
                    <div className="controls">
                        {state.bwicReferenceName ? <button
                            className="btn btn-main"
                            onClick={() => history.push(routes.biddingUrl(state.bwicReferenceName))}>
                            VIEW BWIC
                        </button> : null}
                        <button
                            className="btn btn-ghost"
                            onClick={() => history.push(routes.allBWICs)}>
                            VIEW BWIC MONITOR
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
