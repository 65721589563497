import * as React from 'react';
import { SalesRepresentative } from "../../types/sales-representative/SalesRepresentative";
import { UserProfilePhotoViewer } from '../user-profile-photo/UserProfilePhotoViewer';
import { nameUtils } from '../../utils';

interface Props {
    salesRepresentatives: SalesRepresentative[]
}

export function SalesRepresentativeList({ salesRepresentatives }: Props) {
    if (!salesRepresentatives.length) {
        return null;
    }

    const renderRepresentative = (representative: SalesRepresentative) => {
        return (
            <li key={representative.id}>
                <a className="flex-row navbar-dropdown-menu-item" href={`mailto:${representative.email}`}>
                    <div className="employee-avatar">
                        <UserProfilePhotoViewer userId={representative.id} alt={nameUtils.getInitials(representative.firstName, representative.lastName)} thumbnail={true} />
                    </div>
                    <ul className="employee-contacts">
                        <li>{representative.firstName} {representative.lastName}</li>
                        <li className="text-sm link-color">{representative.email}</li>
                        {representative.phone &&
                            <li>{representative.phone}</li>
                        }
                    </ul>
                </a>
            </li>
        );
    }

    return (
        <div className="navbar-dropdown-menu-section navbar-dropdown-employees">
            <h3>Sales Representatives</h3>
            <ul className="employees-list">
                {salesRepresentatives.map(r => renderRepresentative(r))}
            </ul>
        </div>
    );
}
