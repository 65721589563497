import { isEqual } from 'lodash';
import { FilterButton, FilterClear, FilterPanel, FilterSection } from '../filters';
import { bwicDateFilterOptions } from '../../constants';
import { FilterSelect } from '../common/filters/FilterSelect';
import { TradeStatus } from '../../types/trades/TradeStatus';
import { tradeStatuses } from '../../constants/bidding';
import { tradesActions } from '../../actions/trades.actions';
import { TradeSettlementStatus, tradeSettlementStatusTitle } from '../../types/settlement/TradeSettlementStatus';
import { useSelector } from 'react-redux';
import { DateFilterOption } from '../../types/filters/DateFilterOption';
import { AppState } from '../../types/state/AppState';
import { isRequesting } from '../../utils';
import { DateRangeFilter } from '../common/filters/FilterDateRange/DateRangeFilter';
import { DateRange } from '../../types/filters/DateRange';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props {
    onApply: () => void;
}

export const TradeFilter = ({ onApply }: Props) => {
    const dispatch = useAppDispatch();

    const lastAppliedFilter = useSelector((state: AppState) => state.trades.lastAppliedFilter);
    const filter = useSelector((state: AppState) => state.trades.filter);
    const initialFilter = useSelector((state: AppState) => state.trades.initialFilter);
    const isRequestingSearch = useSelector((state: AppState) => isRequesting(state.trades.searchRequestState))

    const handleCustomDateChange = (data: DateRange) => {
        dispatch(tradesActions.filterDateChange(bwicDateFilterOptions.custom))
        dispatch(tradesActions.filterCustomDateChange(data));
    }

    const handleSelectedDateChange = (data: DateFilterOption) => dispatch(tradesActions.filterDateChange(data));

    const handleReset = () => {
        dispatch(tradesActions.resetFilter())
        onApply()
    }

    return (
        <div className="sub-header-row type02 flex-row">
            <FilterPanel>
                <DateRangeFilter
                    title="Exec.Date"
                    customClassName="wide-range-date"
                    onReset={() => handleSelectedDateChange(bwicDateFilterOptions.unspecified)}
                    selectedDateOption={filter.selectedDateOption}
                    customDateRange={filter.customDateRange}
                    onSelectedDateChange={handleSelectedDateChange}
                    onCustomDateChange={handleCustomDateChange}
                    disabled={isRequestingSearch}
                    lastAppliedFilter={lastAppliedFilter}
                    initialFilter={initialFilter}
                    filter={filter}
                />
                <FilterSelect
                    title="Trade Status"
                    multiply={true}
                    isApplied={
                        lastAppliedFilter.pending === filter.pending &&
                        lastAppliedFilter.confirmed === filter.confirmed &&
                        lastAppliedFilter.rejected === filter.rejected
                    }
                    options={[
                        TradeStatus.pending,
                        TradeStatus.affirmed,
                        TradeStatus.rejected
                    ].map(x => ({
                        text: tradeStatuses.getByKey(x)?.title || '',
                        value: x,
                        selected: (
                            (filter.pending && x === TradeStatus.pending) ||
                            (filter.confirmed && x === TradeStatus.affirmed) ||
                            (filter.rejected && x === TradeStatus.rejected)
                        ),
                        visible: true,
                        disabled: false
                    }))}
                    onChangeItemSelection={item => {
                        (item.value === TradeStatus.pending && dispatch(tradesActions.tradesTogglePending()));
                        (item.value === TradeStatus.affirmed && dispatch(tradesActions.tradesToggleConfirmed()));
                        (item.value === TradeStatus.rejected && dispatch(tradesActions.tradesToggleRejected()));
                    }}
                    onClearAll={() => {
                        filter.pending && dispatch(tradesActions.tradesTogglePending());
                        filter.confirmed && dispatch(tradesActions.tradesToggleConfirmed());
                        filter.rejected && dispatch(tradesActions.tradesToggleRejected());
                    }}
                    onSelectAll={() => {
                        !filter.pending && dispatch(tradesActions.tradesTogglePending());
                        !filter.confirmed && dispatch(tradesActions.tradesToggleConfirmed());
                        !filter.rejected && dispatch(tradesActions.tradesToggleRejected());
                    }}
                    disabled={isRequestingSearch}
                />
                <FilterSelect
                    title="Settl. Status"
                    multiply={true}
                    isApplied={
                        lastAppliedFilter.allocationStatus.unsettled === filter.allocationStatus.unsettled &&
                        lastAppliedFilter.allocationStatus.pendingAllocation === filter.allocationStatus.pendingAllocation &&
                        lastAppliedFilter.allocationStatus.pendingSettlement === filter.allocationStatus.pendingSettlement &&
                        lastAppliedFilter.allocationStatus.pendingProceeds === filter.allocationStatus.pendingProceeds &&
                        lastAppliedFilter.allocationStatus.settled === filter.allocationStatus.settled
                    }
                    options={[
                        TradeSettlementStatus.Unsettled,
                        TradeSettlementStatus.PendingAllocation,
                        TradeSettlementStatus.PendingProceeds,
                        TradeSettlementStatus.PendingSettlement,
                        TradeSettlementStatus.Settled
                    ].map(x => ({
                        text: tradeSettlementStatusTitle[x],
                        value: x,
                        selected: (
                            (filter.allocationStatus
                                && filter.allocationStatus.unsettled && x === TradeSettlementStatus.Unsettled) ||
                            (filter.allocationStatus
                                && filter.allocationStatus.pendingAllocation && x === TradeSettlementStatus.PendingAllocation) ||
                            (filter.allocationStatus
                                && filter.allocationStatus.pendingSettlement && x === TradeSettlementStatus.PendingSettlement) ||
                            (filter.allocationStatus
                                && filter.allocationStatus.pendingProceeds && x === TradeSettlementStatus.PendingProceeds) ||
                            (filter.allocationStatus
                                && filter.allocationStatus.settled && x === TradeSettlementStatus.Settled)
                        ),
                        visible: true,
                        disabled: false
                    }))
                    }
                    onChangeItemSelection={item => {
                        (item.value === TradeSettlementStatus.Unsettled
                            && dispatch(tradesActions.tradesToggleAllocationStatusUnsettled()));
                        (item.value === TradeSettlementStatus.PendingSettlement
                            && dispatch(tradesActions.tradesToggleAllocationStatusPendingSettlement()));
                        (item.value === TradeSettlementStatus.PendingAllocation
                            && dispatch(tradesActions.tradesToggleAllocationStatusPendingAllocation()));
                        (item.value === TradeSettlementStatus.PendingProceeds
                            && dispatch(tradesActions.tradesToggleAllocationStatusPendingProceeds()));
                        (item.value === TradeSettlementStatus.Settled
                            && dispatch(tradesActions.tradesToggleAllocationStatusSettled()));
                    }}
                    onClearAll={() => {
                        filter.allocationStatus.unsettled
                        && dispatch(tradesActions.tradesToggleAllocationStatusUnsettled());
                        filter.allocationStatus.pendingAllocation
                        && dispatch(tradesActions.tradesToggleAllocationStatusPendingAllocation());
                        filter.allocationStatus.pendingSettlement
                        && dispatch(tradesActions.tradesToggleAllocationStatusPendingSettlement());
                        filter.allocationStatus.pendingProceeds
                        && dispatch(tradesActions.tradesToggleAllocationStatusPendingProceeds());
                        filter.allocationStatus.settled
                        && dispatch(tradesActions.tradesToggleAllocationStatusSettled());
                    }}
                    onSelectAll={() => {
                        !filter.allocationStatus.unsettled
                        && dispatch(tradesActions.tradesToggleAllocationStatusUnsettled());
                        !filter.allocationStatus.pendingSettlement
                        && dispatch(tradesActions.tradesToggleAllocationStatusPendingSettlement());
                        !filter.allocationStatus.pendingAllocation
                        && dispatch(tradesActions.tradesToggleAllocationStatusPendingAllocation());
                        !filter.allocationStatus.pendingProceeds
                        && dispatch(tradesActions.tradesToggleAllocationStatusPendingProceeds());
                        !filter.allocationStatus.settled
                        && dispatch(tradesActions.tradesToggleAllocationStatusSettled());
                    }}
                    disabled={isRequestingSearch}
                />
                <FilterSelect
                    title="Reported"
                    multiply={true}
                    isApplied={
                        lastAppliedFilter.reported ||
                        lastAppliedFilter.notReported
                    }
                    options={[
                        'Yes',
                        'No'
                    ].map(x => ({
                        text: x,
                        value: x,
                        selected: x === 'Yes' ? filter.reported : filter.notReported,
                        visible: true,
                        disabled: false
                    }))
                    }
                    onChangeItemSelection={(item) => item.value === 'Yes'
                        ? dispatch(tradesActions.filterReportedChange())
                        : dispatch(tradesActions.filterNotReportedChange())
                    }
                    onClearAll={() => {
                        filter.reported && dispatch(tradesActions.filterReportedChange());
                        filter.notReported && dispatch(tradesActions.filterNotReportedChange());
                    }}
                    onSelectAll={() => {
                        !filter.reported && dispatch(tradesActions.filterReportedChange());
                        !filter.notReported && dispatch(tradesActions.filterNotReportedChange());
                    }}
                    disabled={isRequestingSearch}
                />
                <FilterSection>
                    <FilterButton
                        caption="Apply"
                        disabled={isRequestingSearch || isEqual(filter, lastAppliedFilter)}
                        onClick={onApply}
                    />
                </FilterSection>
                <FilterSection>
                    <FilterClear
                        isDisabled={isRequestingSearch}
                        isShown={!isRequestingSearch && !isEqual(initialFilter, filter)}
                        onClick={handleReset} />
                </FilterSection>
            </FilterPanel>
        </div>
    )
}
