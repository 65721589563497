import * as React from 'react';
import { SaveSettlementAccountBank } from '../../../types/settlement/SaveSettlementAccount';
import { SettlementAccount } from '../../../types/settlement/SettlementAccount';
import { SettlementAccountBankForm } from './SettlementAccountBankForm';
import { user } from '../../../user';
import { useSettlementAccountSave } from './useSettlementAccountSave';
import { isRequesting, isRequestSuccess } from '../../../utils';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { routes } from '../../../constants';

interface Props {
    settlementAccount?: SettlementAccount;
    assetManagerId: number;
    disabled: boolean;
}

export function SettlementAccountEdit({ settlementAccount, assetManagerId, disabled }: Props) {
    const updatedAccount = useRef<SettlementAccount | undefined>();
    const history = useHistory();
    const currentUser = user.current();

    const { saveAccountRequestState, onSave } = useSettlementAccountSave();

    const handleSave = async (banks: SaveSettlementAccountBank[], documents: string[], accountName: string, miraeAcc?: string) => {
        const accountToUpdate = {
            ...settlementAccount || {},
            settlementAgent: { id: currentUser!.companyId},
            assetManager: { id: assetManagerId},
            documents,
            accountName,
            miraeAcc: miraeAcc ? miraeAcc : undefined,
            banks
        }
        updatedAccount.current = await onSave(accountToUpdate);
    }

    useEffect(() => {
        if(isRequestSuccess(saveAccountRequestState) && updatedAccount.current?.id) {
            history.replace(routes.clientsSettlementAccountDetailsUrl(assetManagerId, updatedAccount.current.id))
        }
    },[saveAccountRequestState, assetManagerId, settlementAccount?.id, history])

    return (
        <>
            <SettlementAccountBankForm
                settlementAccountBanks={settlementAccount?.banks}
                accountName={settlementAccount?.accountName || ''}
                miraeAcc={settlementAccount?.miraeAcc || ''}
                documents={settlementAccount?.documents || []}
                onSave={handleSave}
                disabled={disabled || isRequesting(saveAccountRequestState)}
                isSaved={isRequestSuccess(saveAccountRequestState)}
            />
        </>
    );
}
