import React, { Component } from 'react';

export class Disclaimer extends Component {
    render = () => {
        return (
            <div className="disclaimer">
                This email (including any attachments) is confidential and, by accepting this email, you agree that you will treat it as such and act or refrain from acting in accordance with applicable law. It does not constitute investment advice, is only for the use of the intended recipient and copying, redistributing or acting upon such email by unintended recipients is unauthorized and may be unlawful. Receipt by anyone other than the intended recipient is not a waiver of any protections or work-product or attorney-client privilege. If you received this email in error, please notify KopenTech, LLC ("KopenTech") immediately. KopenTech makes no representation or warranty as to the accuracy or completeness of the email and its contents and is not obligated to update or revise any information. This email is not an official confirmation of any transaction and is not offer to sell or the solicitation for the purchase of any security, financial instrument or interest in an investment vehicle. Such offering shall be made only by a final confidential offering circular to eligible investors. Any comments or opinions expressed in this communication do not necessarily reflect those of KopenTech or its affiliates. Emails sent to and from this address may be monitored and stored in accordance with internal policy. This email and any attachments are confidential and may be privileged or otherwise protected from disclosure. If you are not the intended recipient, please telephone or email the sender and delete this message and any attachment from your system immediately. If you are not the intended recipient you must not copy this message or attachment or disclose the contents to any other person. For more details, see the <a target="_blank" href="/terms-and-conditions">Terms and Conditions</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a>.
            </div>
        );
    }

}
