import cn from 'classnames';
import { user } from '../../user';
import { SubscribeLink } from '../access/BlockedFeatureText';
import { calculateSubscriptionTimeline } from '../../utils/subscription.utils';

const maxDays = 14;

export function TrialPeriodBanner() {
    const currentUser = user.current()!;
    const { isTrial, isTrialEnded, isSubscriptionEnded, remainingDays } = calculateSubscriptionTimeline();

    const isSubscriptionExpiredSoon =
        !isTrial &&
        !!currentUser.subscriptionExpiration &&
        remainingDays != null &&
        remainingDays <= maxDays;

    const isTrialEndedSoon =
        isTrial &&
        !isTrialEnded &&
        remainingDays != null &&
        remainingDays <= maxDays;

    if (!isTrial && !isTrialEnded && !isSubscriptionEnded && !isTrialEndedSoon && !isSubscriptionExpiredSoon) return null;

    const className = cn('banner trial-period-banner', {
        'last-week': remainingDays != null && remainingDays >= 7 && remainingDays <= maxDays,
        'last-three': remainingDays != null && remainingDays < 7 && remainingDays > 1,
        'last-day': (remainingDays != null && remainingDays <= 1) || isTrialEnded || isSubscriptionEnded
    });

    const subscribe = () => {
        const text = isSubscriptionExpiredSoon || isSubscriptionEnded
            ? "Renew" : undefined;
        return <SubscribeLink text={text} />
    }
    const have = () => isTrialEnded ? 'have' : 'keep';
    const subscription = () => {
        if (isTrial || isTrialEnded) {
            return "free trial of the KTX Pro";
        }

        const plan = isSubscriptionEnded || isTrialEnded ? currentUser.subscriptionPrev : currentUser.subscription
        return `KTX ${plan}`;
    }
    const expiring = () => {
        if (isSubscriptionEnded || isTrialEnded) return <>has <b>expired</b> and you switched to the Basic plan</>;
        if (remainingDays === 1) return "is expiring today";
        return <>will be ending in <b>{remainingDays} days</b></>;
    }

    return (
        <div className={className}>
            <div>
                Your {subscription()} plan {expiring()}. {subscribe()} to {have()} unlimited access.
            </div>
        </div>
    );
}
