import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sellerBuysideActions } from '../../actions';
import { constants } from '../../constants';
import { formatUtils, numericUtils } from '../../utils';
import { Commission } from '../bid-as-dealer/submit-bid/Commission';
import { SellerBuysideSearchResult } from '../../types/bid-as-dealer/SellerBuysideSearchResult';
import { errorMessages } from '../../constants';
import { SettlementAgentAgreement } from '../../types/bid-as-dealer/SettlementAgentAgreement';
import { useAppSelector } from '../../effects/useAppSelector';

interface SellerBuySideBidProps {
    entity: SellerBuysideSearchResult
    agreement?: SettlementAgentAgreement
    disabled: boolean;
}

export function SellerBuySideCommission({ entity, agreement, disabled }: SellerBuySideBidProps) {
    const dispatch = useDispatch();

    const editState = useAppSelector(s => s.sellerBuyside.editBidRequestState[entity.position.id]);

    const defaultCommission =
        editState?.commission.value ??
        entity.bidAsDealerRequest?.commission ??
        agreement?.commissions[entity.position.rating];

    const defaultCommissionFormatted = formatUtils.formatDecimal(defaultCommission, 4);

    const [value, setValue] = useState(defaultCommissionFormatted);
    const [errorMessage, setErrorMessage] = useState(editState?.commission.error ?? '');

    const validate = (commission: number) => {
        const minValue =
            entity.bidAsDealerRequest?.commission ??
            agreement?.commissions[entity.position.rating] ??
            constants.commissionRange.min;
        const maxValue = constants.commissionRange.max;

        if (commission < minValue || commission > maxValue) {
            return errorMessages.invalidRange(minValue, maxValue);
        }

        return '';
    };

    const handleCommissionChange = (value: string) => {
        if (agreement) {
            setValue(value);

            if (errorMessage) {
                setErrorMessage('');
            }

            dispatch(sellerBuysideActions.commissionChange(
                entity.position.id,
                numericUtils.numberOrDefault(value),
                '',
                agreement)
            );
        }
    };

    const handleOnLeave = () => {
        if (agreement) {
            // Reset to default if 0 or empty
            if (!Number(value)) {
                const defaultValue = entity.bidAsDealerRequest?.commission ?? agreement.commissions[entity.position.rating];
                setValue(formatUtils.formatDecimal(defaultValue, 4));
                dispatch(sellerBuysideActions.commissionChange(entity.position.id, defaultValue!, '', agreement));
            } else {
                const error = validate(Number(value));
                setErrorMessage(error);
                if (!error) {
                    setValue(formatUtils.formatDecimal(Number(value), 4));
                }
                dispatch(sellerBuysideActions.commissionChange(entity.position.id, Number(value), error, agreement));
            }
        }
    };

    return (
        <Commission
            originalCommission={Number(defaultCommission)}
            commission={value}
            error={errorMessage}
            disabled={disabled}
            onCommissionChange={handleCommissionChange}
            onLeave={handleOnLeave}
        />
    )
}
