import * as React from 'react';
import { SettlementAccountBankAttention } from '../../../types/settlement/SettlementAccountBankAttention';
import { Table } from '../../bidding/common/table';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { constants } from '../../../constants';

interface Props {
    attentions: SettlementAccountBankAttention[];
}

export function SettlementAccountBankAttentions({ attentions }: Props) {
    const columns: IColumnDefinition<SettlementAccountBankAttention>[] = [{
            columnKey: 'name',
            renderColumnHeaderContent: () => 'Full Name',
            renderColumnContent: a => a.name,
            bodyClassName: 'data-list-cell-xl-xxl',
            headerClassName: 'data-list-cell-xl-xxl'
        }, {
            columnKey: 'email',
            renderColumnHeaderContent: () => 'Email',
            renderColumnContent: a => a.email,
            bodyClassName: 'data-list-cell-xl-xxl',
            headerClassName: 'data-list-cell-xl-xxl'
        }, {
            columnKey: 'phone',
            renderColumnHeaderContent: () => 'Phone',
            renderColumnContent: a => a.phone || constants.emptyPlaceholder,
            bodyClassName: 'data-list-cell-xl-xxl',
            headerClassName: 'data-list-cell-xl-xxl'
        }
    ];

    return (
        <div className="attn-section">
            <h4>Attn</h4>
            <Table
                className="data-list-striped"
                dataItems={attentions}
                columns={columns.map(c => new ColumnBuilder(c))}
            />
        </div>
    );
}
