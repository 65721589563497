import moment from "moment";
import { constants } from "../../constants";
import { TimeZone } from "../../types/models/TimeZone";
import { getTimeZones } from "../../constants/timeZones";

interface BwicDateTimeProps {
    date: Date
    bidsDue: Date
    timeZone: string
}

export const BwicDateTime = ({ date, bidsDue, timeZone }: BwicDateTimeProps) => {
    const displayDate = moment(date).format('dddd, MMM D, YYYY');
    const timestamp = moment.utc(bidsDue).format(constants.timeFormat);
    const displayTimeZone = getTimeZones().find((tz: TimeZone) => tz.id === timeZone);

    return <> {displayDate} {timestamp} {displayTimeZone?.abbreviation}</>;
}