import * as React from 'react';
import { OnHoverTooltip } from '../common';
import { Trade } from '../../types/trades/Trade';
import { SettlementAgentAgreement } from '../../types/bid-as-dealer/SettlementAgentAgreement';
import { DIRECT_BIDDING_WAS_SUSPENDED, NOT_REQUESTED_DIRECT_BIDDING, WAIT_FOR_RESPONSE_DIRECT_BIDDING } from '../agreements/MissingAgreementTooltip';
import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { isActiveTrade } from '../../types/trades/TradeStatus';

interface SubmitButtonTooltipProps {
    canBidFlag: boolean;
    canSubmit: boolean;
    error?: string;
    isEmpty: boolean;
    agreement?: SettlementAgentAgreement;
    trade?: Trade
    disabled: boolean;
    children: React.ReactNode;
}

export function SubmitButtonTooltip({ trade, error, canBidFlag, canSubmit, isEmpty, agreement, disabled, children }: SubmitButtonTooltipProps) {

    const getSubmitButtonTooltip = () => {
        switch (true) {
            case disabled:
                return null
            case !agreement:
                return NOT_REQUESTED_DIRECT_BIDDING;
            case agreement?.agreementStatus === SettlementAgreementStatus.pending:
                return WAIT_FOR_RESPONSE_DIRECT_BIDDING;
            case agreement?.agreementStatus === SettlementAgreementStatus.rejected && isActiveTrade(trade):
                return 'Traded to me';
            case agreement?.agreementStatus === SettlementAgreementStatus.rejected:
                return DIRECT_BIDDING_WAS_SUSPENDED;
            case !canBidFlag:
                return 'You cannot place a bid for the current security.'
            case !!error:
                return String(error);
            case isEmpty:
                return 'Enter \'My Bid\' to enable this button';
            case canSubmit:
                return 'Click to submit bid includes commission.';
            default:
                return null;
        }
    }

    const tooltip = getSubmitButtonTooltip();

    if (tooltip) {
        return <OnHoverTooltip overlay={tooltip}>{children}</OnHoverTooltip>
    }

    return <>{children}</>;
}
