import { useDispatch } from 'react-redux';
import { errorActions } from '../../actions';
import { onBoardingTooltipConst } from '../../constants';
import { onBoardingTooltipService } from '../../services';
import { OnBoardingTooltipType } from '../../types/onboarding-tooltips/OnBoardingTooltipType';
import { user } from '../../user';

export const useSetWebinarPopupViewed = () => {
    const dispatch = useDispatch();

    const setViewed = async () => {
        try {
            await onBoardingTooltipService.setCount([{page: OnBoardingTooltipType.webinarAnnouncementPopup, count: onBoardingTooltipConst.maxCountLimit}])
            user.setOnBoardingTooltipCounter(OnBoardingTooltipType.webinarAnnouncementPopup, onBoardingTooltipConst.maxCountLimit)
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        }
    }

    return { setViewed }
}
