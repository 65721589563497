import * as React from 'react';

interface Props {
    accountName: string;
    children: React.ReactNode | React.ReactNode[];
}

export function SettlementAccountHeader({ accountName, children }: Props) {
    return (
        <div className="settlement-accounts-sub-title flex-row">
            <h1>{accountName}</h1>
            {children}
        </div>
    );
}
