import React, { FC, useState } from 'react';
import { FieldArray, Form, Formik, FormikValues } from 'formik';
import { isEqual } from 'lodash';
import {
    SaveSettlementAccountBank,
    SaveSettlementAccountBankAttention,
    SaveSettlementAccountBankCustomField
} from '../../../types/settlement/SaveSettlementAccount';
import { SettlementAccountBank } from './SettlementAccountBank';
import { yup } from '../../../validation/yup';
import { Confirm } from '../../alerts';
import { InputField } from './Input';
import { useHistory } from 'react-router';
import { RouteLeavingGuard } from '../../routing';
import { constants, errorMessages } from '../../../constants';
import { DocumentList } from '../../documents/DocumentList';
import { Document, DocumentStoreType } from '../../../types/document/Document';
import IconSVG from '../../../styles/svg-icons';
import { BankOptionalFields } from '../../../types/settlement/BankOptionalFields';
import { useStates } from '../../../effects/data-accessors/useStates';

export interface SettlementAccountBankFormModel {
    id?: number;
    originalAccount?: number;
    agentInternAc?: string;
    agentId?: string;
    dtc?: string;
    shortName?: string;
    taxId?: string;
    attention: SaveSettlementAccountBankAttention[];
    customFields?: SaveSettlementAccountBankCustomField[];
    address1?: string;
    address2?: string;
    countryCode?: string;
    state?: string;
    stateId?: number;
    cityId?: number;
    cityName?: string;
    zipCode?: number;
    institutionalId?: string;
    [BankOptionalFields.Cmta]?: string;
    [BankOptionalFields.Euroclear]?: string;
    [BankOptionalFields.LtId]?: string;
    [BankOptionalFields.InterestedPartyId]?: string;
    [BankOptionalFields.InterestedPartyCustodian]?: string;
    [BankOptionalFields.GovAbaNumber]?: string;
    [BankOptionalFields.GovBankAccountNumber]?: string;
    [BankOptionalFields.MortgageAbaNumber]?: string;
    [BankOptionalFields.MortgageBankAccountNumber]?: string;
}

const initialBank: SettlementAccountBankFormModel = {
    id: 0,
    originalAccount: 0,
    agentId: '',
    agentInternAc: '',
    dtc: '',
    shortName: '',
    taxId: '',
    institutionalId: '',
    customFields: [],
    attention: [],
    address1: '',
    address2: '',
    countryCode: '',
    cityName: '',
    zipCode: undefined,
    state: '',
    stateId: undefined,
    cityId: undefined,
}

const toFormModel = (banks: SaveSettlementAccountBank[]): SettlementAccountBankFormModel[] =>
    banks.map(bank => ({
        id: bank.id,
        originalAccount: bank.originalAccount,
        agentId: String(bank.agentId || ''),
        agentInternAc: bank.agentInternAc || '',
        dtc: String(bank.dtc || ''),
        shortName: String(bank.shortName || ''),
        taxId: String(bank.taxId || ''),
        customFields: bank.customFields || [],
        attention: bank.attention || [],
        address1: bank.address1 || '',
        address2: bank.address2 || '',
        countryCode: bank.countryCode || '',
        state: bank.stateName || '',
        stateId: bank.stateId || undefined,
        cityId: bank.cityId || undefined,
        zipCode: bank.zipCode || undefined,
        cityName: bank.cityName || '',
        institutionalId: String(bank.institutionalId || ''),
        // Optional fields
        cmta: bank.cmta ? String(bank.cmta) : undefined,
        euroclear: bank.euroclear ? String(bank.euroclear) : undefined,
        ltId: bank.ltId ? String(bank.ltId) : undefined,
        interestedPartyId: bank.interestedPartyId ? String(bank.interestedPartyId) : undefined,
        interestedPartyCustodian: bank.interestedPartyCustodian ? String(bank.interestedPartyCustodian) : undefined,
        govAbaNumber: bank.govAbaNumber ? String(bank.govAbaNumber) : undefined,
        govBankAccountNumber: bank.govBankAccountNumber ? String(bank.govBankAccountNumber) : undefined,
        mortgageAbaNumber: bank.mortgageAbaNumber ? String(bank.mortgageAbaNumber) : undefined,
        mortgageBankAccountNumber: bank.mortgageBankAccountNumber ? String(bank.mortgageBankAccountNumber) : undefined,
    }))

interface Props {
    settlementAccountBanks?: SaveSettlementAccountBank[],
    documents: Document[];
    accountName: string;
    miraeAcc: string;
    onSave: (banks: SaveSettlementAccountBank[], documents: string[], accountName: string, miraeAcc?: string) => void;
    disabled: boolean;
    isSaved: boolean;
}

const acceptedExtensions = ['csv', 'doc', 'docx', 'pdf', 'ppt', 'pptx', 'xls', 'xlsx'];

export const SettlementAccountBankForm: FC<Props> = (
    {
        settlementAccountBanks,
        documents,
        accountName,
        miraeAcc,
        onSave,
        disabled,
        isSaved
    }) => {
    const history = useHistory();

    const [confirmBankDeleting, setConfirmBankDeleting] = useState<{ name: string, index: number } | undefined>(undefined);

    const { states } = useStates();

    const handleSave = (values: FormikValues) => {
        const banks = values.banks.map((bank: SettlementAccountBankFormModel) => ({
            id: bank.id || null,
            originalAccount: bank.originalAccount,
            agentId: bank.agentId ? bank.agentId.trim() : '',
            agentInternAc: bank.agentInternAc,
            dtc: bank.dtc ? bank.dtc.trim() : '',
            shortName: bank.shortName ? bank.shortName.trim() : '',
            taxId: bank.taxId ? bank.taxId.trim() : '',
            address1: bank.address1,
            address2: bank.address2,
            countryCode: bank.countryCode,
            stateName: bank.state || '',
            zipCode: bank.zipCode,
            stateId: bank.stateId || undefined,
            cityId: bank.cityId || undefined,
            cityName: bank.cityName || '',
            institutionalId: bank.institutionalId || '',
            // Optional fields
            cmta: bank.cmta ? Number(bank[BankOptionalFields.Cmta]) : '',
            euroclear: bank.euroclear ? Number(bank[BankOptionalFields.Euroclear]) : '',
            ltId: bank[BankOptionalFields.LtId],
            interestedPartyId: bank[BankOptionalFields.InterestedPartyId],
            interestedPartyCustodian: bank[BankOptionalFields.InterestedPartyCustodian],
            govAbaNumber: bank[BankOptionalFields.GovAbaNumber],
            govBankAccountNumber: bank[BankOptionalFields.GovBankAccountNumber],
            mortgageAbaNumber: bank[BankOptionalFields.MortgageAbaNumber],
            mortgageBankAccountNumber: bank[BankOptionalFields.MortgageBankAccountNumber],
            customFields: bank.customFields ? bank.customFields.map(field => ({
                ...field,
                id: field.id || null
            })) : [],
            attention: bank.attention.map(attention => ({
                ...attention,
                id: attention.id || null
            })),
        }))
        onSave(
            banks,
            values.documents.map((d: Document) => d.id),
            values.accountName,
            values.miraeAcc ? values.miraeAcc.trim() : values.miraeAcc
        )
    }

    const initialFormValues = {
        banks: settlementAccountBanks ? toFormModel(settlementAccountBanks) : [{ ...initialBank }],
        accountName,
        miraeAcc,
        documents,
    }

    const validationSchema = yup.object().shape({
        accountName: yup.string().trim().max(40).required(),
        miraeAcc: yup.lazy(
            value => value === undefined || !value.trim()
                ? yup.string().notRequired()
                : yup.string()
                    .min(8, errorMessages.wrongFormatRange(8, 10))
                    .max(10, errorMessages.wrongFormatRange(8, 10))
        ),
        banks: yup.array().min(1).of(
            yup.object().shape({
                agentInternAc: yup.string().max(12),
                agentId: yup.string().trim().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(6),
                dtc: yup.string().trim().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(4),
                shortName: yup.string().max(10),
                institutionalId: yup.string().nullable().trim().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(10),
                taxId: yup.string().max(15),
                address1: yup.string().max(40),
                address2: yup.string().max(40),
                stateId: yup.number(),
                cityName: yup.string().max(20),
                zipCode: yup.string().trim().test('zipCode', errorMessages.invalidValue, function (zipCode) {
                    if (!zipCode) return true
                    if (this.parent.countryCode === constants.USCountryCode) return constants.USZipCode.test(zipCode)
                    return constants.zipCode.test(zipCode)
                }),
                customFields: yup.array().of(
                    yup.object().shape({
                        name: yup.string().matches(constants.name).trim().max(64).required(),
                        value: yup.string().trim().max(64).required(),
                    })
                ),
                attention: yup.array().of(
                    yup.object().shape({
                        name: yup.string().matches(constants.name).trim().max(64).required(),
                        email: yup.string().trim().max(64).email().required(),
                        phone: yup.string().max(64),
                    })
                ),
                // Optional Fields
                cmta: yup.lazy(
                    value => value === undefined
                        ? yup.string().notRequired()
                        : yup.string().nullable().trim().required().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(4)
                ),
                euroclear: yup.lazy(
                    value => value === undefined
                        ? yup.string().notRequired()
                        : yup.string().nullable().trim().required().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(6)
                ),
                ltId: yup.lazy(
                    value => value === undefined
                        ? yup.string().notRequired()
                        : yup.string().nullable().trim().required().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(40)
                ),
                interestedPartyId: yup.lazy(
                    value => value === undefined
                        ? yup.string().notRequired()
                        : yup.string().nullable().trim().required().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(5)
                ),
                interestedPartyCustodian: yup.lazy(
                    value => value === undefined
                        ? yup.string().notRequired()
                        : yup.string().nullable().trim().required().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(12)
                ),
                govAbaNumber: yup.lazy(
                    value => value === undefined
                        ? yup.string().notRequired()
                        : yup.string().nullable().trim().required().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(9)
                ),
                govBankAccountNumber: yup.lazy(
                    value => value === undefined
                        ? yup.string().notRequired()
                        : yup.string().nullable().trim().required().max(17)
                ),
                mortgageAbaNumber: yup.lazy(
                    value => value === undefined
                        ? yup.string().notRequired()
                        : yup.string().nullable().trim().required().matches(constants.regOnlyNumbers, errorMessages.shouldBeNumber).max(9)
                ),
                mortgageBankAccountNumber: yup.lazy(
                    value => value === undefined
                        ? yup.string().notRequired()
                        : yup.string().nullable().trim().required().max(17)
                ),
            })
        )
    });

    return (
        <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSave}>
            {({ values, setFieldValue, errors, submitCount }) => {
                const isChanged = !isEqual(values, initialFormValues)
                return (
                    <Form className="settlement-accounts-form" noValidate={true}>
                        <div className="settlement-accounts-sub-title flex-row">
                            <h1>Settlement Account </h1>
                            <div className="controls flex-item-right">
                                <button
                                    className="btn btn-ghost btn-sm"
                                    onClick={() => history.goBack()}
                                    type="button" disabled={disabled}
                                >
                                    Cancel
                                </button>
                                <button className="btn btn-main btn-sm" type="submit" disabled={!isChanged || disabled}>
                                    Save
                                </button>
                            </div>
                        </div>
                        <div className="settlement-accounts-sections">
                            <div className="settlement-accounts-sections-center">
                                <div className="acc-section">
                                    <InputField
                                        label="Account Name"
                                        placeholder="Account Name"
                                        markRequired={true}
                                        name="accountName"
                                        maxLength={40}
                                    />
                                    <div className="form-row form-row-inline">
                                        <div className="form-cell">
                                            <InputField
                                                label="Mirae Acc #"
                                                placeholder="Mirae Acc"
                                                markRequired={false}
                                                name="miraeAcc"
                                                maxLength={10}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <FieldArray name="banks">
                                    {({ remove, push }) => (
                                        <>
                                            {values.banks.map((bank, index) => {
                                                return (
                                                    <SettlementAccountBank
                                                        key={`bank-${index}`}
                                                        index={index}
                                                        name={`banks.${index}`}
                                                        bank={bank}
                                                        canDelete={values.banks.length > 1}
                                                        onDelete={() => setConfirmBankDeleting({
                                                            name: `Bank #${index + 1}`,
                                                            index
                                                        })}
                                                        onDeleteCustomField={(field) => setFieldValue(field, undefined)}
                                                        onAddCustomField={(field) => setFieldValue(field, null)}
                                                        states={states}
                                                        setFieldValue={setFieldValue}
                                                        isSubmit={!!submitCount}
                                                        errors={errors.banks?.[index]}
                                                    />
                                                )
                                            })}
                                            <div className="section-btn">
                                                <button
                                                    className="btn btn-ghost btn-sm"
                                                    type="button"
                                                    onClick={() => push({ ...initialBank })}
                                                >
                                                    <IconSVG name="plus" width={16} height={16} /> Bank
                                                </button>
                                            </div>
                                            {
                                                confirmBankDeleting && (
                                                    <Confirm
                                                        text={<>
                                                            Would you like to remove the
                                                            <strong>{confirmBankDeleting.name}</strong> section?
                                                        </>}
                                                        onCancel={() => setConfirmBankDeleting(undefined)}
                                                        onConfirm={() => {
                                                            remove(confirmBankDeleting.index);
                                                            setConfirmBankDeleting(undefined);
                                                        }}
                                                    />
                                                )
                                            }
                                        </>
                                    )}
                                </FieldArray>
                                <RouteLeavingGuard
                                    navigate={(pathname: string) => history.push(pathname)}
                                    shouldBlockNavigation={() => isChanged && !isSaved}
                                />
                                <DocumentList
                                    documents={values.documents}
                                    readonly={false}
                                    onDelete={(referenceName) => {
                                        setFieldValue('documents', values.documents.filter(document => document.id !== referenceName))
                                    }}
                                    onDocumentUploaded={(documents) => {
                                        setFieldValue('documents', [...values.documents, ...documents]);
                                    }}
                                    documentStoreType={DocumentStoreType.Settlement}
                                    acceptedExtensions={acceptedExtensions}
                                />
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}
