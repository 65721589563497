import * as React from 'react';
import { TradeSettlementStatus } from '../../types/settlement/TradeSettlementStatus';
import { SettlementStatusLabel } from './SettlementStatusLabel';
import { constants } from '../../constants';

interface Props {
    buyerStatus?: TradeSettlementStatus;
    sellerStatus?: TradeSettlementStatus;
}

export function SettlementStatusLabelCombined({ buyerStatus, sellerStatus }: Props) {
    if (!buyerStatus && !sellerStatus) return constants.emptyPlaceholder;

    let single: TradeSettlementStatus | undefined;

    if (!buyerStatus) single = sellerStatus;
    if (!sellerStatus) single = buyerStatus;
    if (sellerStatus === TradeSettlementStatus.Unsettled && buyerStatus === TradeSettlementStatus.Unsettled) single = TradeSettlementStatus.Unsettled;
    if (sellerStatus === TradeSettlementStatus.Settled && buyerStatus === TradeSettlementStatus.Settled) single = TradeSettlementStatus.Settled;

    if (single) return <SettlementStatusLabel status={single} />;

    return (
        <div className="status status-pending-settlement">
            Pending
        </div>
    );
}
