import { useEffect } from 'react';
import { userProfilePhotoActions } from '../../actions/user-profile-photo.actions';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { isRequesting, isRequestSuccess } from '../../utils';
import { RequestState } from "../../constants/request-state";
import { useAppDispatch } from '../../effects/useAppDispatch';

interface UserImageViewerProps {
    userId: number;
    alt?: string;
    thumbnail?: boolean;
}

export function UserProfilePhotoViewer({ userId, alt, thumbnail = false }: UserImageViewerProps) {
    const dispatch = useAppDispatch();
    const { userPhotos, userPhotoRequestState, thumbnails, thumbnailsRequestState } = useSelector((state: AppState) => state.userProfilePhoto);
    const requestState = thumbnail ? thumbnailsRequestState[userId] : userPhotoRequestState[userId];
    const image = thumbnail ? thumbnails[userId] : userPhotos[userId];

    useEffect(() => {
        if (userId
            && !image
            && !isRequesting(requestState)
            && !isRequestSuccess(requestState)
            && !(requestState === RequestState.failure)
        ) {
            if (thumbnail) {
                dispatch(userProfilePhotoActions.fetchPhotoThumbnail(userId));
            } else {
                dispatch(userProfilePhotoActions.fetchUserImage(userId));
            }
        }
    }, [userId, thumbnail, requestState, image, dispatch]);

    return (
        <div className="user-avatar">
            {!!image && <img src={image} alt="userImage" />}
            {!image && !!alt && <span>{alt}</span>}
        </div>
    )
}
