import React, { useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import { PopupBody, Popup, PopupFooter } from '../controls';
import { releaseActions } from '../../actions/release.actions';
import { AppState } from '../../types/state/AppState';
import { Preloader, Relative, OnHoverTooltip } from '../common';
import { isRequesting, arrayUtils, isRequestSuccess } from '../../utils';
import { user } from '../../user';
import { onBoardingTooltipConst, roles } from '../../constants';
import IconSVG from '../../styles/svg-icons';
import { useGetWebinar } from '../../effects/useGetWebinar';
import { useSetWebinarPopupViewed } from './useSetWebinarPopupViewed';
import { ImageLoader } from '../common/ImageLoader';
import { logger } from '../../logging/logger';
import { Release, ReleaseItem, ReleaseItemType } from '../../types/marketing/Release';
import { LocationState, LocationStateBuilder, PopupType } from '../../types/state/ui/LocationState';

const allowedDescriptionTags = ['ul', 'li'];

export type TWhatsNewPopupPayload = {
    releasesVisible: boolean;
    webinarVisible: boolean;
}

export const WhatsNewPopup: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory<LocationState<undefined, TWhatsNewPopupPayload>>();

    const onBoardingTooltipCounters = user.getOnBoardingTooltipCounters();
    const numberOfViews = onBoardingTooltipCounters.WhatsNewPopup ?? onBoardingTooltipConst.maxCountLimit;
    const webinarAnnouncementNumberOfView = onBoardingTooltipCounters.WebinarAnnouncementPopup;
    const { releasesVisible = false, webinarVisible = false } = history.location?.state?.popup?.payload || {};
    const { data, requestState } = useSelector((state: AppState) => state.release);
    const isVisibleButtonMarker = numberOfViews !== onBoardingTooltipConst.maxCountLimit;
    const isArrangersClient = user.hasSingleRole(roles.ArrangersClient);

    const { requestStateGetWebinar, webinar } = useGetWebinar(isArrangersClient);
    const { setViewed } = useSetWebinarPopupViewed();

    useEffect(() => {
        if (
            webinarAnnouncementNumberOfView < onBoardingTooltipConst.maxCountLimit &&
            isRequestSuccess(requestStateGetWebinar) &&
            webinar &&
            !moment().isSame(webinar.dateOfWebinar, 'day')
        ) {
            history.replace({
                ...history.location,
                state: new LocationStateBuilder(history.location.state)
                    .popup(PopupType.WhatsNewPopup, { releasesVisible: false, webinarVisible: true })
                    .result()
            });
        }
    }, [requestStateGetWebinar, webinar, webinarAnnouncementNumberOfView, history]);

    useEffect(() => {
        if (
            numberOfViews < onBoardingTooltipConst.maxCountLimit &&
            isRequestSuccess(requestStateGetWebinar) &&
            (!webinar || !webinarVisible)
        ) {
            history.replace({
                ...history.location,
                state: new LocationStateBuilder(history.location.state)
                    .popup(PopupType.WhatsNewPopup, { releasesVisible: true, webinarVisible: false })
                    .result()
            });
        }
    }, [numberOfViews, requestStateGetWebinar, webinar, webinarVisible, history]);

    useEffect(() => {
        if (releasesVisible) {
            dispatch(releaseActions.fetchReleases());
        }
    }, [releasesVisible, dispatch]);

    const popupTitle = (
        <>
            <h1 className="display-inline">What’s new</h1>
            <a
                className="btn btn-ghost btn-sm"
                href={process.env.REACT_APP_WHATS_NEW_POPUP_READ_MORE}
                target="_blank"
                rel="noopener noreferrer"
            >
                Read more
            </a>
        </>
    );

    const renderReleaseItemTitle = (iconType: ReleaseItemType) => {
        switch (iconType) {
            case ReleaseItemType.NewFeature:
                return <><IconSVG name="favorite" width={16} height={16} /> New Features:</>;
            case ReleaseItemType.Improvement:
                return <><IconSVG name="idea" width={16} height={16} /> Improvements:</>;
            case ReleaseItemType.BugFix:
                return <><IconSVG name="bug" width={16} height={16} /> Bug Fixes:</>;
            default:
                return null;
        }
    };

    const sanitizeReleaseItemDescription = (description: string) => {
        description.replace(data.listStartSymbol, '<ul>');
        description.replace(data.listEndSymbol, '</ul>');
        description.replace(data.listItemStartSymbol, '<li>');
        description.replace(data.listEndSymbol, '</li>');
        return sanitizeHtml(description, { allowedTags: allowedDescriptionTags });
    }

    const renderReleaseItem = (releaseItem: ReleaseItem) => (
        <div className="content" key={releaseItem.id}>
            {releaseItem.title && <h4>{releaseItem.title}</h4>}
            <div>
                <div className="description"
                     dangerouslySetInnerHTML={{ __html: sanitizeReleaseItemDescription(releaseItem.description || '') }}
                />
                {
                    releaseItem.linkName && releaseItem.link &&
                    <p className="more">
                        <a href={releaseItem.link} target="_blank" rel="noopener noreferrer">{releaseItem.linkName}</a>
                    </p>
                }
                {releaseItem.imageReferenceName ?
                    <ImageLoader path={`${process.env.REACT_APP_MARKETING_ENDPOINT}/api/release-image/v2/${releaseItem.imageReferenceName}`} />
                    : null
                }
            </div>
        </div>
    );

    const renderRelease = (release: Release) => {
        const groupedItems = arrayUtils.groupBy(release.releaseItems || [], (release: ReleaseItem) => release.releaseItemType);
        const groupedItemsCollection = Array.from(groupedItems);
        return (
            <div className="info-row" key={release.id}>
                <h1>{release.title}</h1>
                {
                    groupedItemsCollection.map(([key, items]) => (
                            <div key={key}>
                                <h2>{renderReleaseItemTitle(key)}</h2>
                                {items.map((item: ReleaseItem) => renderReleaseItem(item))}
                            </div>
                        )
                    )
                }
            </div>
        )
    };

    const showReleasesPopup = () => {
        history.replace({
            ...history.location,
            state: new LocationStateBuilder(history.location.state)
                .popup(PopupType.WhatsNewPopup, { releasesVisible: true, webinarVisible: false })
                .result()
        });
    }

    const handleClosePopup = () => {
        history.replace({
            ...history.location,
            state: new LocationStateBuilder(history.location.state).resetPopup().result()
        });

        if (isVisibleButtonMarker) {
            dispatch(releaseActions.markAsReadRequest());
        }
    }

    const handleCloseWebinarPopup = () => {
        history.replace({
            ...history.location,
            state: new LocationStateBuilder(history.location.state)
                .popup(PopupType.WhatsNewPopup, { releasesVisible: false, webinarVisible: false })
                .result()
        });
        setViewed();
    }

    const renderPopup = () => {
        if (!releasesVisible) {
            return null;
        }

        return (
            <Popup
                renderInBody={true}
                renderTitle={popupTitle}
                onClose={handleClosePopup}
                modalClass="modal-whats-new"
            >
                <Relative>
                    <Preloader inProgress={isRequesting(requestState) || !data.releases}>
                        <PopupBody>
                            {data.releases && data.releases.map(release => renderRelease(release))}
                        </PopupBody>
                    </Preloader>
                </Relative>
                <PopupFooter>
                    <div className="flex-item-right updates">
                        <p className="display-inline text-sm text-medium">For more updates check out:</p>
                        <a
                            className="btn-link"
                            href="https://www.linkedin.com/company/kopentech"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconSVG name="linkedin" width={16} height={16} />
                        </a>
                        <a
                            className="btn-link"
                            href="https://twitter.com/kopentech"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconSVG name="twitter" width={16} height={16} />
                        </a>
                    </div>
                </PopupFooter>
            </Popup>
        );
    };

    const renderWebinarPopup = () => {
        if (!webinar || !webinarVisible) {
            return null;
        }

        return (
            <Popup
                onClose={handleCloseWebinarPopup}
                title={webinar.title}
                modalClass="modal-webinar"
            >
                <PopupBody>
                    <h2>CLO volumes, liquidity, market health indicator, and more.</h2>
                    <p>{webinar.description}</p>
                    {webinar.imageReferenceName ?
                        <ImageLoader path={`${process.env.REACT_APP_MARKETING_ENDPOINT}/api/webinar-image/v2/${webinar.imageReferenceName}`} />
                        : null
                    }
                </PopupBody>
                <PopupFooter>
                    <a
                        onClick={()=>{
                            logger.trace('User clicked Register button on Webinar announcement popup')
                            handleCloseWebinarPopup()
                        }}
                        className="btn btn-main"
                        href={webinar.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {webinar.linkName}
                    </a>
                </PopupFooter>
            </Popup>
        );
    }

    return (
        <>
            <OnHoverTooltip overlay="Product Updates">
                <button className="navbar-link navbar-link-whats-new" onClick={() => showReleasesPopup()}>
                    <IconSVG name="whats-new" width={24} height={24} />
                    {isVisibleButtonMarker && <span className="alert-badge" />}
                </button>
            </OnHoverTooltip>
            {renderPopup()}
            {renderWebinarPopup()}
        </>
    );
};
