import { EmptyPlaceholder } from '../common';
import { ShowFor } from '../access';
import { user } from '../../user';
import { roles } from '../../constants';

interface Props {
    onCreate?: () => void;
}

export function SettlementAccountsEmptyView({ onCreate }: Props) {
    const isBrokerDealer = user.hasRoles(...roles.bd());
    return (
        <div className="settlement-accounts-empty">
            <EmptyPlaceholder
                text={isBrokerDealer ? 'There are no Settlement Accounts created for this client.' : 'You do not have any settlement accounts.'}
            >
                <ShowFor roles={roles.bd()}>
                    <button className="btn btn-main" onClick={() => onCreate && onCreate()}>create settlement account</button>
                </ShowFor>
            </EmptyPlaceholder>
        </div>
    );
}
