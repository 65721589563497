import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { tradesActions } from '../../actions/trades.actions';
import { Table } from "../bidding/common/table";
import { BwicTypeLabel, EmptyPlaceholder, OnHoverTooltip, Preloader } from "../common";
import IconSVG from "../../styles/svg-icons";
import { constants } from '../../constants';
import { formatUtils, isRequestSuccess, moneyUtils } from '../../utils';
import { CompanyDetailsPanel } from '../company-details/CompanyDetailsPanel';
import { TradingOrderByColumn, TradingOrderByColumnSortOrder } from '../../types/enums/TradingOrderByColumn';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { TradeStatusLabel } from '../trade/TradeStatusLabel';
import { TradeFilter } from './TradeFilter';
import { SettlementStatusLabel } from '../settlement/SettlementStatusLabel';
import { TradeSettlementStatus } from '../../types/settlement/TradeSettlementStatus';
import { CompanyDetailsButton } from '../company-details/CompanyDetailsButton';
import { EmailLink } from '../bidding/common/EmailLink';
import { TradeStatus } from '../../types/trades/TradeStatus';
import { ReportedFlag } from './ReportedFlag';
import { TooltipPlacement } from '../common/Tooltip';
import { BidOnBehalfIcon } from "../bidding/seller/bid-on-behalf/BidOnBehalfIcon";

export const TradesView = () => {
    const dispatch = useDispatch();

    const sortingField = useRef(TradingOrderByColumn.TradeDate);
    const sortDirection = useRef(TradingOrderByColumnSortOrder.desc);

    const trades = useSelector((state) => state.trades.trades)
    const totalRecordNumber = useSelector((state) => state.trades.totalRecordNumber)
    const searchRequestState = useSelector((state) => state.trades.searchRequestState)
    const isRequestingExportTrades = useSelector((state) => state.trades.isRequestingExportTrades)

    const tradeList = trades.map(t => {
        const executionDateTime = t.executionDateTime && moment(t.executionDateTime, constants.formatTimeStringWithoutTimeZone);
        return ({
            ...t,
            // Add extra fields for sorting
            executionDate: executionDateTime && moment(executionDateTime).format('YYYY-MM-DD'),
            executionTime: executionDateTime && moment(executionDateTime).format('HH:mm'),
            brokerDealer: (t.buyerCompany && t.buyerCompany.mpid) || '',
            seller: (t.sellerCompany && t.sellerCompany.code) || '',
            client: (t.directBuyerCompany && t.directBuyerCompany.code) || '',
        })
    });
    const noSearchResult = !tradeList.length && isRequestSuccess(searchRequestState);

    useEffect(() => {
        dispatch(tradesActions.init(sortingField.current, sortDirection.current));
        return () => dispatch(tradesActions.reset());
    }, [dispatch]);

    const handlePageChanged = () => {
        dispatch(tradesActions.search(sortingField.current, sortDirection.current))
    }

    const handleApply = () => {
        dispatch(tradesActions.search(sortingField.current, sortDirection.current, true))
    }

    const handleResetFilter = () => {
        dispatch(tradesActions.resetFilter());
        handleApply()
    }

    const handleSortChanged = () => {
        dispatch(tradesActions.search(sortingField.current, sortDirection.current, true))
    }

    const handleExport = () => {
        dispatch(tradesActions.exportSearchResult(sortingField.current, sortDirection.current))
    }

    const renderAllocationLetter = (status, isAllocationManual) => {
        if (!status
            || status === TradeSettlementStatus.Unsettled
            || status === TradeSettlementStatus.PendingAllocation) {
            return null;
        }
        return isAllocationManual
            ? (
                <span className="allocation">
                    <OnHoverTooltip overlay="Manual Allocation">
                        <IconSVG name="manual" width={16} height={16} />
                    </OnHoverTooltip>
                </span>
            ) : (
                <span className="allocation">
                    <OnHoverTooltip overlay="Automated Allocation">
                        <IconSVG name="auto" width={16} height={16} />
                    </OnHoverTooltip>
                </span>
            );
    }

    const renderEmailLink = (text) =>
        <OnHoverTooltip overlay={text}>
            (<EmailLink email={text} />)
        </OnHoverTooltip>

    const columnDefinitions = [
        {
            columnKey: 'tradeRowIcon',
            renderColumnHeaderContent: () => '',
            renderColumnContent: entity =>
                <>
                    {!!entity.onBehalf && <BidOnBehalfIcon />}
                    <BwicTypeLabel
                        isParsed={false}
                        liveBidding={false}
                        isAllToAll={entity.isAllToAll}
                        directBidding={entity.directBuyerCompany != null}
                        isMy={false}
                    />
                </>
            ,
            headerClassName: 'data-list-cell-xs',
            bodyClassName: 'data-list-cell-xs'
        }, {
            columnKey: 'tradeDate',
            renderColumnHeaderContent: () => 'Exec. Date (EST)',
            renderColumnContent: entity =>
                entity.executionDateTime &&
                moment(entity.executionDateTime, constants.formatTimeStringWithoutTimeZone).format(constants.dateFormat2),
            headerClassName: 'data-list-cell-md',
            bodyClassName: 'data-list-cell-md',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.TradeDate]
        }, {
            columnKey: 'tradeTime',
            renderColumnHeaderContent: () => 'Exec. Time (EST)',
            renderColumnContent: entity =>
                entity.executionDateTime &&
                moment(entity.executionDateTime, constants.formatTimeStringWithoutTimeZone).format(constants.timeFormat),
            headerClassName: 'data-list-cell-md',
            bodyClassName: 'data-list-cell-md',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.TradeDate]
        }, {
            columnKey: 'tradeStatus',
            renderColumnHeaderContent: () => 'Trade Status',
            renderColumnContent: entity => <TradeStatusLabel status={entity.status}
                rejectReason={entity.rejectedReason} />,
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.TradeStatus]
        }, {
            columnKey: 'settlementStatus',
            renderColumnHeaderContent: () => 'Settl. Status',
            renderColumnContent: entity => (
                <div className='display-flex'>
                    {entity.isDirect && !entity.isAllToAll && renderAllocationLetter(entity.settlementStatus, entity.isAllocationManual)}
                    {entity.settlementStatus ? <SettlementStatusLabel status={entity.settlementStatus} /> : constants.emptyPlaceholder}
                </div>
            ),
            headerClassName: 'data-list-cell-lg cell-settl-status',
            bodyClassName: 'data-list-cell-lg cell-settl-status',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.SettlementStatus]
        }, {
            columnKey: 'isin',
            renderColumnHeaderContent: () => 'ISIN',
            renderColumnContent: entity => entity.isin ? entity.isin : constants.emptyPlaceholder,
            headerClassName: 'data-list-cell-md',
            bodyClassName: 'data-list-cell-md',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.Isin]
        }, {
            columnKey: 'cusip',
            renderColumnHeaderContent: () => 'CUSIP',
            renderColumnContent: entity => entity.cusip,
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.Cusip]
        }, {
            columnKey: 'ticker',
            renderColumnHeaderContent: () => 'Ticker',
            renderColumnContent: entity => entity.ticker,
            headerClassName: 'data-list-cell-lg',
            bodyClassName: 'data-list-cell-lg',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.Ticker]
        }, {
            columnKey: 'tickerSize',
            renderColumnHeaderContent: () => 'Size (Quantity)',
            renderColumnContent: entity => (
                <OnHoverTooltip overlay={moneyUtils.money(entity.size)} placement={TooltipPlacement.BottomRight}>
                    <div className="text-ellipsis">{moneyUtils.money(entity.size)}</div>
                </OnHoverTooltip>
            ),
            className: 'data-list-cell-sm text-right',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.Size]
        }, {
            columnKey: 'price',
            renderColumnHeaderContent: () => 'Bid Size (Price)',
            renderColumnContent: entity => formatUtils.formatBid(entity.price),
            headerClassName: 'data-list-cell-md text-right',
            bodyClassName: 'data-list-cell-md text-right',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.Price]
        }, {
            columnKey: 'settlementDate',
            renderColumnHeaderContent: () => 'Settlement (EST)',
            renderColumnContent: entity => moment(entity.settlementDate).format(constants.dateShortFormat),
            headerClassName: 'data-list-cell-md',
            bodyClassName: 'data-list-cell-md',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.SettlementDate]
        }, {
            columnKey: 'buyerShortcode',
            renderColumnHeaderContent: () => 'Buyer Shortcode',
            renderColumnContent: entity => (
                entity.directBuyerCompany
                    ? (
                        <>
                            <CompanyDetailsButton
                                title={entity.directBuyerCompany.code}
                                isBrokerDealer={false}
                                company={entity.directBuyerCompany}
                                rowKey={entity.id}
                            />
                            {entity.isDirect && entity.affirmedRejectedBy
                                ? renderEmailLink(entity.affirmedRejectedBy)
                                : null}
                        </>
                    )
                    : constants.emptyPlaceholder
            ),
            headerClassName: 'data-list-cell-xxl cell-shortcode',
            bodyClassName: 'data-list-cell-xxl cell-shortcode',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.BuyerShortCode]
        }, {
            columnKey: 'brokerDealer',
            renderColumnHeaderContent: () => 'Dealer MPID',
            renderColumnContent: entity => (
                <>
                    <CompanyDetailsButton
                        title={entity.buyerCompany.mpid}
                        isBrokerDealer={true}
                        company={entity.buyerCompany}
                        rowKey={entity.id}
                    />
                    {
                        !entity.isDirect
                            && entity.affirmedRejectedBy
                            ? renderEmailLink(entity.affirmedRejectedBy)
                            : null
                    }
                </>
            ),
            headerClassName: 'data-list-cell-xxl cell-shortcode',
            bodyClassName: 'data-list-cell-xxl cell-shortcode',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.DealerMPID]
        }, {
            columnKey: 'seller',
            renderColumnHeaderContent: () => 'Seller Shortcode',
            renderColumnContent: entity => (
                <>
                    {entity.sellerCompany
                        ? (
                            <CompanyDetailsButton
                                title={entity.sellerCompany.code}
                                company={entity.sellerCompany}
                                rowKey={entity.id}
                            />
                        )
                        : constants.emptyPlaceholder
                    }
                    {
                        entity.createdBy
                            ? renderEmailLink(entity.createdBy)
                            : null
                    }
                </>
            ),
            headerClassName: 'data-list-cell-xxl cell-shortcode',
            bodyClassName: 'data-list-cell-xxl cell-shortcode',
            sortingField: TradingOrderByColumn[TradingOrderByColumn.SellerShortCode]
        }, {
            columnKey: 'reported',
            renderColumnHeaderContent: () => 'Is Reported',
            renderColumnContent: entity => (
                !entity.onBehalf &&
                entity.status === TradeStatus.affirmed &&
                <ReportedFlag
                    tradeId={entity.id}
                    isReported={entity.isReported}
                    reportedInProgress={entity.reportedInProgress}
                />
            ),
            headerClassName: 'data-list-cell-xs cell-reporter padding-l-0 text-center',
            bodyClassName: 'data-list-cell-xs cell-reporter padding-l-0 text-center'
        }
    ]

    return (
        <div className="container trades-container">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>Trades</h1>
                    <div className="flex-item-right controls">
                        <Preloader
                            small={true}
                            fullScreen={false}
                            inProgress={isRequestingExportTrades} text="Exporting…"
                        >
                            <button
                                disabled={noSearchResult || !isRequestSuccess(searchRequestState)}
                                onClick={handleExport}
                                className="btn-link"
                            >
                                <IconSVG name="export" width={16} height={16} /> Export
                            </button>
                        </Preloader>
                    </div>
                </div>
                <TradeFilter onApply={handleApply} />
            </div>

            <Preloader inProgress={!isRequestSuccess(searchRequestState) && !tradeList.length}>
                <div className="flex-row flex-row-full-size">
                    {!noSearchResult &&
                        <Table
                            className="data-list-trades data-list-striped"
                            columns={columnDefinitions.map(c => new ColumnBuilder(c))}
                            dataItems={tradeList}
                            onNextPageRequest={handlePageChanged}
                            infiniteScrollEnabled={tradeList.length < totalRecordNumber}
                            onSort={(field, direction) => {
                                sortingField.current = TradingOrderByColumn[field];
                                sortDirection.current = TradingOrderByColumnSortOrder[direction];
                                handleSortChanged()
                            }}
                            defaultSortBy={TradingOrderByColumn[sortingField.current]}
                            defaultSortByDirection={TradingOrderByColumnSortOrder[sortDirection.current]}
                        />
                    }
                    {noSearchResult && <EmptyPlaceholder onResetClick={handleResetFilter}/>}
                    <CompanyDetailsPanel />
                </div>
            </Preloader>
        </div>
    );
};
