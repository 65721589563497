import { useDispatch } from 'react-redux';
import { securityDetailsActions } from '../../actions';
import { SecurityDetails } from '.';
import SecurityDetailsOptions from './SecurityDetailsOptions';
import { useAppSelector } from '../../effects/useAppSelector';
import { ControlPanelOptions } from '../bidding/common/ControlPanelOptions';
import { BWICLog } from './bwic-log/BWICLog';
import { RightSideTabPanel } from '../controls/RightSideTabPanel';
import { bwicLogActions } from '../../actions/bwic-log.actions';

interface Props {
    optionsExclude?: ControlPanelOptions[];
    onClose?: () => void;
}

export function SecurityDetailsPanel({ optionsExclude = [], onClose }: Props) {
    const dispatch = useDispatch();
    const bwicLog = useAppSelector(s => s.bwicLog);
    const securityDetails = useAppSelector(s => s.securityDetails);

    const security = bwicLog.security || securityDetails.security;

    if (!security) return null;

    const items = [
        {
            title: 'Security',
            active: !!securityDetails.security,
            renderContent: () => <SecurityDetails />,
            onActivate: () => dispatch(securityDetailsActions.securityDetailsInitRequest(security, false)),
            onDeactivate: () => dispatch(securityDetailsActions.securityDetailsReset())
        },
        {
            title: 'BWIC Log',
            active: !!bwicLog.security,
            renderContent: () => <BWICLog />,
            onActivate: () => dispatch(bwicLogActions.bwicLogInitRequest(security)),
            onDeactivate: () => dispatch(bwicLogActions.bwicLogReset())
        },
    ]

    return (
        <RightSideTabPanel
            className="right-panel right-panel-tabs"
            title={
                <>
                    <>{security.ticker}</>
                    <SecurityDetailsOptions optionsExclude={optionsExclude} position={security} />
                </>
            }
            onClose={() => {
                dispatch(securityDetailsActions.securityDetailsReset());
                dispatch(bwicLogActions.bwicLogReset());
                onClose?.();
            }}
            items={items}
        />
    );
}
