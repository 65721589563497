import React, { memo } from 'react';
import { FieldArray } from 'formik';
import{ InputField } from './Input';
import { constants } from '../../../constants';
import { SaveSettlementAccountBankCustomField } from '../../../types/settlement/SaveSettlementAccount';
import { SettlementAccountBankAddOptionalFields } from './SettlementAccountBankAddOptionalFields';
import { SettlementAccountBankFormModel } from './SettlementAccountBankForm';
import IconSVG from '../../../styles/svg-icons';

interface Props {
    customFields?: SaveSettlementAccountBankCustomField[],
    name: string;
    bank: SettlementAccountBankFormModel;
    onAddField: (field: string) => void;
    dropDownVisible: boolean;
}

const initialCustomField: SaveSettlementAccountBankCustomField = {
    name: '',
    value: ''
}

const SettlementAccountBankCustomFieldComponent: React.FC<Props> = ({ customFields, name, bank, onAddField, dropDownVisible }) => {
    return (
        <FieldArray name={name}>
            {({ remove, push }) => {
                return (
                    <>
                        <SettlementAccountBankAddOptionalFields
                            bank={bank}
                            onAddField={onAddField}
                            onAddCustomField={() => push({ ...initialCustomField })}
                            isVisible={dropDownVisible}
                        />
                        {
                            customFields?.map((customField, index) => (
                                <div className="form-row form-row-inline custom-field" key={`customField-${index}`}>
                                    <InputField
                                        formItemCustomClass="custom-field-name"
                                        label="Name"
                                        placeholder="Enter Name"
                                        markRequired={true}
                                        name={`${name}.${index}.name`}
                                        pattern={constants.name.source}
                                        loading={false}
                                        maxLength={64}
                                    />
                                    <InputField
                                        formItemCustomClass="custom-field-value"
                                        label="Value"
                                        placeholder="Enter Value"
                                        markRequired={true}
                                        name={`${name}.${index}.value`}
                                        pattern={constants.name.source}
                                        loading={false}
                                        maxLength={64}
                                    />
                                    <button className="btn-link btn-danger" type="button" onClick={() => remove(index)}>
                                        <i className="icon icon-delete" />
                                    </button>
                                </div>
                            ))
                        }
                        {
                            !!customFields?.length && (
                                <button
                                    className="flex-item-right btn-link"
                                    type="button"
                                    onClick={() => push({ ...initialCustomField })}
                                >
                                    <IconSVG name="plus" width={16} height={16} /> Add New Line
                                </button>
                            )
                        }
                    </>
                )
            }}
        </FieldArray>
    )
}

SettlementAccountBankCustomFieldComponent.defaultProps = {
    customFields: []
}

export const SettlementAccountBankCustomField = memo(SettlementAccountBankCustomFieldComponent)
