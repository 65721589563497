import * as React from 'react';
import { TradeStatus } from '../../types/trades/TradeStatus';
import { RejectedLink } from '../bidding/common';
import { OnHoverTooltip } from '../common/OnHoverTooltip';

interface Props {
    status: TradeStatus;
    rejectReason?: string;
}

export function TradeStatusLabel({ status, rejectReason }: Props) {
    switch (status) {
        case TradeStatus.affirmed:
            return <span className="status status-affirmed">Affirmed</span>;
        case TradeStatus.pending:
            return <span className="status status-pending">Pending</span>;
        case TradeStatus.rejected:
            return <RejectedLink rejectReason={rejectReason} />;
        case TradeStatus.canceled:
            return (
                <OnHoverTooltip overlay="The trade is canceled automatically at the end of the business day.">
                    <span className="status status-canceled">Canceled</span>
                </OnHoverTooltip>
            );
        default:
            return null;
    }
}
