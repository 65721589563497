import React, { useState } from 'react';
import IconSVG from '../../styles/svg-icons';
import UserFeedbackPopup from './UserFeedbackPopup';
import {OnHoverTooltip} from '../common';

const UserFeedback: React.FunctionComponent = () => {
    const [isFeedbackPopupVisible, setFeedbackPopupVisibility] = useState(false);

    function togglePopup () {
        setFeedbackPopupVisibility(!isFeedbackPopupVisible);
    }

    return (
        <>
            <OnHoverTooltip overlay="Feedback">
                <button
                    type="button"
                    className="navbar-link navbar-link-feedback"
                    onClick={togglePopup}>
                    <IconSVG name="feedback" width="24" height="24" />
                </button>
            </OnHoverTooltip>
            {isFeedbackPopupVisible && <UserFeedbackPopup onClose={togglePopup}/>}
        </>
    );
};

export default UserFeedback;
