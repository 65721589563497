import { yup } from '../../../validation/yup';
import { constants, errorMessages } from '../../../constants';

export const addFeedbackValidation = () => {
    return yup.object().shape({
        reason: yup.array().of(yup.string()).transform(value => (value.length ? value : undefined)).required(errorMessages.emptyMultiSelect),
        product: yup.array().of(yup.string()).transform(value => (value.length ? value : undefined)).required(errorMessages.emptyMultiSelect),
        message: yup.string().trim().required().matches(constants.nonWhitespace.source).max(1024),
    });
};
