import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dateTimeUtils, formatUtils, stringUtils } from '../../utils';
import moment from 'moment';
import { constants, levelSpecifications } from '../../constants';
import { BwicProcessType } from '../../types/models/Process';

class Rules extends Component {
    render = () => {
        const { rules, goodUntil, timeZone } = this.props;
        const bidsGoodUntilTime = moment(goodUntil, constants.formatTimeStringWithoutTimeZone)
            .add(30, 'minute')
            .format(constants.timeFormat)

        return (
            <>
                <h2>Rules: {rules.title}</h2>
                <ul className="template-rules-list">
                    {rules.reserveLevelsApply && <li>— Reserve levels apply</li>}
                    {rules.earlyTrades && <li>— Can trade early</li>}
                    <li>— Bids good until {bidsGoodUntilTime}&nbsp;{timeZone?.abbreviation}</li>
                    {
                        !stringUtils.isNullOrWhiteSpace(rules.rulesText) &&
                        <li>— Notes: {rules.rulesText}</li>
                    }
                    <li>— T+{this.props.daysToSettle} settlement</li>
                    {rules.minBidIncrement && <li>— The minimum bid improvement should be higher than the previous bid by {formatUtils.formatBid(rules.minBidIncrement)}</li>}
                    {this.renderOpenBidding()}
                    {this.renderJumpBall()}
                    {this.renderTopX()}
                    {this.renderBestFootForward()}
                </ul>
            </>
        );
    }

    renderOpenBidding = () => {
        const { rules, date, goodUntil, timeZone } = this.props;

        if (rules.type !== BwicProcessType.Live || rules.liveBidding == null) return null;

        const endsByDate = moment(date).format(constants.dateFormat);
        const endsByTime = moment(goodUntil).format(constants.timeFormat);
        const levelSpecificationType = levelSpecifications.getByKey(rules.liveBidding.levelSpecificationType || 0).title;

        return (
            <li>
                — LiveBidding&#8482; BWIC:
                <ul>
                    <li className="text-medium">Bidding is open for platform participants only.</li>
                    <li>
                        <span className="text-medium">Stage 1:</span><br />
                        — Stage 1 duration - {rules.liveBidding.stage1Deadline} minutes
                        <br />
                        — Reminder to bid - {dateTimeUtils.parseTimeSpan(rules.cutOffReminder).totalMinutes} minutes
                    </li>
                    <li>
                        <span className="text-medium">Stage 2:</span><br />
                        — Top {rules.liveBidding?.openBiddingImproverCount} + ties participants can see live {levelSpecificationType} level and can improve during a {rules.liveBidding.improvementRound} minutes interval.<br />
                        — Bidding continues till either no improvements received during improvement
                        interval or till the end of BWIC {endsByDate} {endsByTime} {timeZone.abbreviation}.<br />
                        — The minimum improvement should be higher than current {levelSpecificationType} by {formatUtils.formatBid(rules.liveBidding.minBidIncrement)}.
                    </li>
                </ul>
            </li>
        );
    }

    renderJumpBall = () => {
        const { rules } = this.props;

        return (
            <>
                {
                    rules.type === BwicProcessType.JumpBall &&
                    <>
                        <li>
                            — Jump Ball BWIC:
                            <ul>
                                <li>
                                    <span className="text-medium">Stage 1:</span><br />
                                    — Open bidding with autofeedback.<br />
                                    {
                                        rules.jumpBall.automaticStage1Deadline == null &&
                                        <>— Seller controls finish time.<br /></>
                                    }
                                    {
                                        rules.jumpBall.automaticStage1Deadline != null &&
                                        <>— Stage 1 duration - {dateTimeUtils.parseTimeSpan(rules.jumpBall.automaticStage1Deadline).totalMinutes} minutes.<br /></>
                                    }
                                    {
                                        rules.jumpBall.automaticStage1Deadline &&
                                        <>— Reminder to bid - {dateTimeUtils.parseTimeSpan(rules.cutOffReminder).totalMinutes} minutes.<br /></>
                                    }
                                </li>
                                <li>
                                    <span className="text-medium">Stage 2:</span><br />
                                    — Jump Ball, a silent stage with one time to improve your bid. Bids from Stage 1 are carried to Stage 2 and bidders have one time to improve, if they choose. However, the seller is notified if the buyer is bidding over themselves and has the option to trade at the lower bid. Results are announced when all bidders submit bids in Stage 2 or when Seller closes BWIC via trading or DNT.<br />
                                    — Top {rules.jumpBall.improverCount} + ties participants can improve bids.<br />
                                    {
                                        rules.jumpBall.improvementRound == null &&
                                        `— Seller controls finish time.`
                                    }
                                    {
                                        rules.jumpBall.improvementRound != null &&
                                        `— Stage 2 duration - ${dateTimeUtils.parseTimeSpan(rules.jumpBall.improvementRound).totalMinutes}
                                         minutes.`
                                    }
                                </li>
                            </ul>
                        </li>
                    </>
                }
            </>
        )
    }

    renderTopX = () => {
        const { rules } = this.props;

        return (
            <>
                {
                    rules.type === BwicProcessType.TopX &&
                    <>
                        <li>
                            — Top X BWIC:
                            <ul>
                                <li>
                                    <span className="text-medium">Stage 1:</span><br />
                                    — Open bidding with autofeedback.<br />
                                    {
                                        rules.topX.automaticStage1Deadline == null &&
                                        <>— Seller controls finish time.<br /></>
                                    }
                                    {
                                        rules.topX.automaticStage1Deadline != null &&
                                        <>— Stage 1 duration - {dateTimeUtils.parseTimeSpan(rules.topX.automaticStage1Deadline).totalMinutes} minutes.<br /></>
                                    }
                                    {
                                        rules.topX.automaticStage1Deadline &&
                                        <>— Reminder to bid - {dateTimeUtils.parseTimeSpan(rules.cutOffReminder).totalMinutes} minutes<br /></>
                                    }
                                </li>
                                <li></li>
                                <li>
                                    <span className="text-medium">Stage 2:</span><br />
                                    — Top X bidders have the ability to improve their bids many times. Bids from Stage 1 are carried to Stage 2. However, the seller is notified if the bidder is bidding over themselves and has the option to trade at the lower bid. The seller can provide manual/auto feedback on bids. Results are announced when the Seller closes BWIC via trading or DNT.<br />
                                    — Top {rules.topX.improverCount} + ties participants can improve bids.<br />
                                    {
                                        rules.topX.improvementRound == null &&
                                        `— Seller controls finish time.`
                                    }
                                    {
                                        rules.topX.improvementRound != null &&
                                        `— Stage 2 duration - ${dateTimeUtils.parseTimeSpan(rules.topX.improvementRound).totalMinutes}
                                         minutes.`
                                    }
                                </li>
                            </ul>
                        </li>
                    </>
                }
            </>
        )
    }

    renderBestFootForward = () => {
        const { rules } = this.props;

        return (
            <>
                {
                    rules.type === BwicProcessType.BestFootForward &&
                    <>
                        <li>
                            — Best Foot Forward BWIC:
                            <ul className="bullets">
                                <li>One stage only. Bidder can improve bids during the bidding period.</li>
                                <li>Bids submission duration - {dateTimeUtils.parseTimeSpan(rules.bff.stage1Deadline).totalMinutes} minutes</li>
                                <li>Reminder to bid - {dateTimeUtils.parseTimeSpan(rules.cutOffReminder).totalMinutes} minutes</li>
                            </ul>
                        </li>

                    </>
                }
            </>
        )
    }
}

const mapStateToProps = ({ process }) => ({
    bidsDue: process.bidsDue,
    goodUntil: process.goodUntil,
    date: process.date,
    rules: process.templates.current,
    isTemplateChanged: process.templates.dirty,
    improvers: process.templates.improvers,
    timeZone: process.timeZones.find(tz => tz.id === process.timeZone),
    daysToSettle: process.daysToSettle
});


const connectedRules = connect(mapStateToProps)(Rules);
export { connectedRules as Rules };
