import { useDispatch } from 'react-redux';
import { sellerBuysideActions } from '../../actions';
import { useAppSelector } from '../../effects/useAppSelector';
import { BidRequestStatus } from '../../types/bid-as-dealer/BidRequestStatus';
import { SellerBuysideSearchResult } from '../../types/bid-as-dealer/SellerBuysideSearchResult';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { FeatureButton } from '../access/FeatureButton';
import { DirectBiddingDisclaimerActionBlocker }
    from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker';
import { useAgreement } from './useAgreement';
import { getCanBidFlag } from './getCanBidFlag';
import { SubmitButtonTooltip } from './SubmitButtonTooltip';
import { RoleActionBlocker } from '../access/RoleActionBlocker';
import { roles } from '../../constants/roles';

interface Props {
    entity: SellerBuysideSearchResult;
    disabled: boolean;
}

export function SellerBuySideSubmitButton({ entity, disabled }: Props) {
    const dispatch = useDispatch();

    const bidAsDealerRequest = entity.bidAsDealerRequest;
    const positionId = entity.position.id;
    const editBidRequest = useAppSelector(s => s.sellerBuyside.editBidRequestState[positionId]);
    const { agreement } = useAgreement();
    const canBidFlag = getCanBidFlag(entity, agreement);
    const hasErrors = !!editBidRequest?.level?.error || !!editBidRequest?.commission?.error;

    const isSubmitButtonDisabled = () => {
        if (disabled || !canBidFlag) return true;

        if (
            hasErrors ||
            !editBidRequest ||
            editBidRequest.level.valueRaw === '' ||
            !editBidRequest.commission.value) {
            return true;
        }

        const status = entity.bidAsDealerRequest?.status;
        if (status === BidRequestStatus.rejected || status === BidRequestStatus.canceled) {
            return false;
        }

        if (bidAsDealerRequest) {
            return !(
                editBidRequest.level.value !== bidAsDealerRequest.level ||
                editBidRequest.settlementAgent.id !== bidAsDealerRequest.brokerDealer?.id ||
                Boolean(editBidRequest.axed) !== Boolean(bidAsDealerRequest.axed) ||
                Boolean(editBidRequest.final) !== Boolean(bidAsDealerRequest.final)
            );
        }
        return !(editBidRequest.settlementAgent.id && (Number(editBidRequest.level.value)) && Number(editBidRequest.commission.value))
    };

    const handleSubmit = () => dispatch(sellerBuysideActions.confirmBidRequestPopup(positionId, true))

    if (entity.bwic.status === BwicStatus.bidding || entity.bwic.status === BwicStatus.scheduled) {
        const disabled = isSubmitButtonDisabled();

        return (
            <RoleActionBlocker roles={[roles.SellerTrader]}>
                {
                    (blocked: boolean) =>
                        <DirectBiddingDisclaimerActionBlocker onConfirm={handleSubmit}>
                            <SubmitButtonTooltip
                                canBidFlag={canBidFlag}
                                canSubmit={!disabled}
                                error={editBidRequest?.level?.error || editBidRequest?.commission?.error}
                                agreement={agreement}
                                isEmpty={!editBidRequest?.level?.valueRaw && !bidAsDealerRequest?.level}
                                disabled={blocked}
                                trade={entity.position.trade}
                            >
                                <FeatureButton
                                    className="btn btn-main btn-sm width-64"
                                    disabled={disabled}
                                    onClick={handleSubmit}
                                >
                                    Bid
                                </FeatureButton>
                            </SubmitButtonTooltip>
                        </DirectBiddingDisclaimerActionBlocker>
                }
            </RoleActionBlocker>
        );
    }

    return null;
}
