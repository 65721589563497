import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SellerBuysideSearchResult } from '../../types/bid-as-dealer/SellerBuysideSearchResult';
import { AppState } from '../../types/state/AppState';
import { EditBidRequestStateItem } from '../../types/state/SellerBuysideState';
import { RouteLeavingGuard } from '../routing';

export function SellerBuysideRouteLeavingGuard() {
    const history = useHistory();

    const isChanged = (edit: EditBidRequestStateItem, dataItem?: SellerBuysideSearchResult) => (
        dataItem?.bidAsDealerRequest ? (
            edit.commission.value !== dataItem.bidAsDealerRequest.commission ||
            edit.level.value !== dataItem.bidAsDealerRequest.level ||
            edit.settlementAgent.id !== dataItem.bidAsDealerRequest.brokerDealer.id ||
            Boolean(edit.axed) !== Boolean(dataItem.bidAsDealerRequest.axed) ||
            Boolean(edit.final) !== Boolean(dataItem.bidAsDealerRequest.final)
        ) : (
            edit.level.value || Boolean(edit.axed) || Boolean(edit.final)
        )
    );

    const hasChanges = useSelector((s: AppState) =>
        Object
            .keys(s.sellerBuyside.editBidRequestState)
            .some((key: string) =>
                isChanged(
                    s.sellerBuyside.editBidRequestState[+key],
                    s.sellerBuyside.dataItems.find(i => i.position.id === +key))
            )
    );

    if (!hasChanges) return null;

    return (
        <RouteLeavingGuard
            title="Unsubmitted Bids"
            text="You have unsubmitted bids. Are you sure you want to leave?"
            navigate={(pathname: string) => history.push(pathname)}
            shouldBlockNavigation={() => true}
        />
    );
}
