import React from 'react';
import { useSelector } from 'react-redux';
import IconSVG from '../../styles/svg-icons';
import { OnHoverTooltip } from "../common";
import { AppState } from '../../types/state/AppState';
import { BrokerDealerCompanySlim } from '../../types/company/BrokerDealerCompanySlim';
import { user } from '../../user/user';
import { roles } from '../../constants';
import { StatusMessageSectionType } from '../../types/state/NotificationState';
import { StatusMessageSection } from '../status-message/StatusMessageSection';

export const Participants = () => {
    const dealersList = useSelector((state: AppState) => state.entities.brokerDealerList.items);
    const dealerContactList = useSelector((state: AppState) => state.entities.brokerDealerContactList.items);
    const dealerUserList = useSelector((state: AppState) => state.entities.brokerDealerUserList.items);
    const selectedDealers = useSelector((state: AppState) => state.newBwicDealers.selectedDealers);
    const isAllToAll = useSelector((state: AppState) => state.process.isAllToAll);
    const renderDealer = (dealer: BrokerDealerCompanySlim) => {
        const emails = new Set([
            ...dealerContactList.filter(c => c.companyId === dealer.id).map(c => c.email),
            ...dealerUserList.filter(c => c.companyId === dealer.id).map(c => c.email)
        ]);

        const members = [...emails]
            .sort((a, b) => a.localeCompare(b))
            .map((email, index) => <div className="submit-participants-company" key={index}>{email}</div>);

        return (
            <div className="participants-cnt" key={dealer.id}>
                <h3>{dealer.name}</h3>
                <div className="participants-list">
                    {
                        !!dealer.distributionList &&
                        <div className="submit-participants-distribution">
                            <OnHoverTooltip overlay="Distribution List">
                                <IconSVG name="distrib" width={16} height={16} />
                            </OnHoverTooltip>
                            {dealer.distributionList}
                        </div>
                    }
                    {
                        !!dealer.headOfTrading && dealer.headOfTrading.email &&
                        <div className="submit-participants-distribution">
                            <OnHoverTooltip overlay="Head of Trading">
                                <IconSVG name="head-of-trade" width={16} height={16} />
                            </OnHoverTooltip>
                            {dealer.headOfTrading.email}
                        </div>
                    }
                    {members}
                </div>
            </div>
        );
    };

    const list = dealersList
        .filter(d => selectedDealers[d.id])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(c => renderDealer(c));

    if (user.hasRoles(roles.BrokerDealerTrader) || isAllToAll) {
        return (
            <>
                <h2>Send BWIC to:</h2>
                <StatusMessageSection type={StatusMessageSectionType.Info}>
                    BWIC will be sent anonymously to all platform members. The seller's name will not be disclosed to bidders.
                </StatusMessageSection>
            </>
        )
    }

    return (
        <>
            <h2>Send BWIC to:</h2>
            <div className="container-submit-participants">
                {list}
            </div>
        </>
    );
};
