import React, { useState, useEffect } from 'react';
import { Popup, PopupBody, PopupFooter, MultiSelectDataItem } from '../controls';
import { TextAreaField } from '../forms';
import { Form, Formik, FormikTouched } from 'formik';
import { useRequestUserFeedback } from '../../effects/useRequestUserFeedback';
import { RequestState } from '../../constants/request-state';
import { addFeedbackValidation } from './validation/addFeedbackValidation';
import { Preloader } from '../common';
import MultiSelectField from '../forms/MultiSelectField';

const feedbackOptions = {
    productOptions: [
        {
            text: 'AMR Refinancings',
            value: 'AMR Refinancings',
            disabled: false,
            selected: false
        },
        {
            text: 'BWIC Monitor',
            value: 'BWIC Monitor',
            disabled: false,
            selected: false
        },
        {
            text: 'Dashboard',
            value: 'Dashboard',
            disabled: false,
            selected: false
        },
        {
            text: 'Dealer Inventory',
            value: 'Dealer Inventory',
            disabled: false,
            selected: false
        },
        {
            text: 'Electronic Trading',
            value: 'Electronic Trading',
            disabled: false,
            selected: false
        },
        {
            text: 'Issuance Monitor',
            value: 'Issuance Monitor',
            disabled: false,
            selected: false
        },
        {
            text: 'Portfolio',
            value: 'Portfolio',
            disabled: false,
            selected: false
        },
        {
            text: 'Other',
            value: 'Other',
            disabled: false,
            selected: false
        }
    ],
    reasonOptions: [
        {
            text: 'Performance',
            value: 'Performance',
            disabled: false,
            selected: false
        },
        {
            text: 'Design',
            value: 'Design',
            disabled: false,
            selected: false
        },
        {
            text: 'Feature',
            value: 'Feature',
            disabled: false,
            selected: false
        },
        {
            text: 'Other',
            value: 'Other',
            disabled: false,
            selected: false
        }
    ]
}

interface Props {
    onClose(): void;
}

interface FormState {
    reason: string[];
    message: string;
    product: string[];
}

const UserFeedbackPopup = ({ onClose }: Props) => {
    const [formData, setInputs] = useState<FormState>({
        reason: [],
        message: '',
        product: [],
    });
    const [requestState, setRequestState] = useState(RequestState.none);
    const reasonOptionsArray = feedbackOptions.reasonOptions.map((option) => option.value);
    const productOptionsArray = feedbackOptions.productOptions.map((option) => option.value);

    function closePopup(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        onClose();
    }

    function sendFeedback(values: FormState) {
        setInputs(values);
        setRequestState(RequestState.request);
    }

    useRequestUserFeedback(
        formData,
        requestState,
        setRequestState
    );

    const handleResetAll = (
        e: React.MouseEvent,
        setFieldValue: (key: string, value: string[]) => void,
        name: string
    ) => {
        e.preventDefault()
        setFieldValue(name, []);
    }

    const handleSelectAll = (
        e: React.MouseEvent,
        setFieldValue: (key: string, value: string[]) => void,
        data: MultiSelectDataItem[],
        name: string,
        setTouched: (fields: { [field: string]: boolean }, shouldValidate?: boolean) => void,
        touched: FormikTouched<FormState>
    ) => {
        e.preventDefault()
        const updatedData = data.map((i: MultiSelectDataItem) => ({ ...i, selected: true }))
        setFieldValue(name, updatedData.map((i: MultiSelectDataItem) => i.value as string));
        setTouched({ ...touched, [name]: true })
    }

    const handleSelect = (
        setFieldValue: (key: string, value: string[]) => void,
        selectedValues: string[],
        name: string,
        setTouched: (fields: { [field: string]: boolean }, shouldValidate?: boolean) => void,
        touched: FormikTouched<FormState>,
        optionsArr: string[]
    ) => {
        return (item: MultiSelectDataItem, selected: boolean) => {
            let updatedData: string[] = [...selectedValues];
            selected
                ? updatedData.push(item.value as string)
                : updatedData = updatedData.filter(i => i !== item.value)
            updatedData = updatedData.sort((a, b) => optionsArr.indexOf(a) - optionsArr.indexOf(b))
            setFieldValue(name, updatedData);
            setTouched({ ...touched, [name]: true })
        }
    };

    useEffect(() => {
        if (requestState === RequestState.success) {
            onClose();
        }
    }, [requestState, onClose]);

    return (
        <Popup title="Help Us Improve" modalClass="modal-feedback" onClose={onClose}>
            <Formik
                initialValues={formData}
                validateOnBlur={false}
                onSubmit={sendFeedback}
                validationSchema={addFeedbackValidation}
            >
                {({ isValid, values, setFieldValue, errors, setTouched, touched }) => {
                    return (
                        <Form noValidate>
                            <PopupBody>
                                <p>
                                    Tell us what you think about the product. Positive or negative, any kind of feedback is
                                    highly appreciated.
                                </p>
                                <div className="form-row">
                                    <MultiSelectField
                                        onSelect={handleSelect(setFieldValue, values.reason, "reason", setTouched, touched, reasonOptionsArray)}
                                        dataItems={feedbackOptions.reasonOptions}
                                        selectedValues={values.reason}
                                        label='What are you happy or unhappy with?'
                                        errorMessage={(touched.reason && errors.reason?.toString()) || ''}
                                        name="reason"
                                        placeholder="Select an option"
                                        onResetAll={(e: React.MouseEvent) => handleResetAll(e, setFieldValue, "reason")}
                                        onSelectAll={(e: React.MouseEvent) => handleSelectAll(
                                            e,
                                            setFieldValue,
                                            feedbackOptions.reasonOptions,
                                            "reason", setTouched,
                                            touched)}
                                    />
                                </div>
                                <div className="form-row">
                                    <MultiSelectField
                                        onSelect={handleSelect(setFieldValue, values.product, "product", setTouched, touched, productOptionsArray)}
                                        dataItems={feedbackOptions.productOptions}
                                        selectedValues={values.product}
                                        label='Select product'
                                        errorMessage={(touched.product && errors.product?.toString()) || ''}
                                        name="product"
                                        placeholder="Select product"
                                        onResetAll={(e: React.MouseEvent) => handleResetAll(e, setFieldValue, "product")}
                                        onSelectAll={(e: React.MouseEvent) => handleSelectAll(
                                            e,
                                            setFieldValue,
                                            feedbackOptions.productOptions,
                                            "product",
                                            setTouched,
                                            touched)}
                                    />
                                </div>
                                <div className="form-row">
                                    <label htmlFor="message" className="form-label">
                                        Tell us what you think <span className="text-red">*</span>
                                    </label>
                                    <TextAreaField
                                        placeholder="Tell us what you think"
                                        maxLength={1024}
                                        name="message"
                                        rows="3"
                                    />
                                </div>
                                <p className="info">
                                    Having technical issues? Found a bug? Contact our <a href={`mailto:${process.env.REACT_APP_SALES_EMAIL}`}>support team</a>!
                                </p>
                            </PopupBody>
                            <PopupFooter>
                                {requestState === RequestState.request &&
                                    <Preloader inProgress={requestState === RequestState.request} fullScreen={false} small={true} />
                                }
                                <button type="button" className="btn btn-ghost" onClick={closePopup}>
                                    Cancel
                                </button>
                                <button
                                    disabled={requestState === RequestState.request || !isValid}
                                    type="submit"
                                    className="btn btn-main"
                                >
                                    Send
                                </button>
                            </PopupFooter>
                        </Form>
                    )
                }}
            </Formik>
        </Popup>
    );
};

export default UserFeedbackPopup;
