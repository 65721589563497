import { useEffect, useState } from "react"
import { RequestState } from "../../constants/request-state";
import { bwicService } from "../../services";
import { constants } from "../../constants/constants";
import { SecurityClassDetails, SecurityIdentifier } from "../../types/security/SecurityClassDetails";
import { SecurityIdentifierType } from "../../types/security/SecurityIdentifierType";
import { DataFromAmr } from "../amrPipeline/types/DataFromAmr";

interface SecurityIdentifiers {
    [SecurityIdentifierType.Ticker144a]: string
    [SecurityIdentifierType.ISIN144a]: string
    [SecurityIdentifierType.CUSIP144a]: string
    [SecurityIdentifierType.TickerRegS]: string
    [SecurityIdentifierType.ISINRegS]: string
    [SecurityIdentifierType.CUSIPRegS]: string
    [SecurityIdentifierType.TickerAccd]: string
    [SecurityIdentifierType.ISINAccd]: string
    [SecurityIdentifierType.CUSIPAccd]: string
}

interface SecurityDetails extends DataFromAmr {
    identifiers: SecurityIdentifiers;
}

export const useSecurityDetails = (securityId?: number) => {
    const [securityDetails, setSecurityDetails] = useState<SecurityDetails | null>(null);
    const [securityDetailsRequestState, setSecurityDetailsRequestState] = useState(RequestState.none);

    const normilizeIdentifiers = (securityIdentifiers: SecurityIdentifier[]) => {
        const result = {
            [SecurityIdentifierType.Ticker144a]: constants.emptyPlaceholder,
            [SecurityIdentifierType.ISIN144a]: constants.emptyPlaceholder,
            [SecurityIdentifierType.CUSIP144a]: constants.emptyPlaceholder,
            [SecurityIdentifierType.TickerRegS]: constants.emptyPlaceholder,
            [SecurityIdentifierType.ISINRegS]: constants.emptyPlaceholder,
            [SecurityIdentifierType.CUSIPRegS]: constants.emptyPlaceholder,
            [SecurityIdentifierType.TickerAccd]: constants.emptyPlaceholder,
            [SecurityIdentifierType.ISINAccd]: constants.emptyPlaceholder,
            [SecurityIdentifierType.CUSIPAccd]: constants.emptyPlaceholder
        };
        securityIdentifiers.forEach(ri => {
            result[ri.type] = ri.identifier;
        })
        return result;
    }

    useEffect(() => {
        (async () => {
            if (securityId) {
                setSecurityDetailsRequestState(RequestState.request);
                try {
                    const identifiersResponse: SecurityClassDetails = await bwicService.getClassDetails(securityId);
                    setSecurityDetails(
                        {
                            ...identifiersResponse,
                            identifiers: normilizeIdentifiers(identifiersResponse.securityIdentifiers)
                        }
                    );
                    setSecurityDetailsRequestState(RequestState.success);
                } catch (e) {
                    setSecurityDetailsRequestState(RequestState.failure);
                }
            }
        })();
    }, [securityId])

    return { securityDetails, securityDetailsRequestState };
}