import moment from 'moment';
import * as React from 'react';
import cn from 'classnames';
import { BwicPositionBase } from '../../types/bwic/BwicPositionBase';
import { trackingService } from '../../services/action-tracking.service';
import { ListBuilder } from '../../utils/ListBuilder';
import { ControlPanelOptions } from '../bidding/common/ControlPanelOptions';
import { useBwicWithSecurityExist } from '../../effects/useBwicWithSecurityExist';
import { isRequesting, isRequestSuccess } from '../../utils';
import { Preloader, OnHoverTooltip } from '../common';
import { ContextMenu } from '../controls';
import { ContextMenuItem } from '../controls/ContextMenuItem';
import { useActiveInventorySecuritesIds } from '../../effects/useActiveInventorySecuritesIds';
import { useHistory } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { addToPortfolioActions } from '../../actions/add-to-portfolio.actions';
import { AddToPortfolioPopup } from '../portfolio/add-to-portfolio/AddToPortfolioPopup';
import IconSVG from "../../styles/svg-icons";
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';

interface Props {
    position: BwicPositionBase;
    optionsExclude: ControlPanelOptions[];
}

export default function SecurityDetailsOptions({ position, optionsExclude }: Props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState(false);
    const [bwicExistRequestState, isBwicExist] = useBwicWithSecurityExist(position.securityId, !expanded);
    const [activeSecuritiesRequestState, activeSecurities] = useActiveInventorySecuritesIds(!expanded);
    const securityInventoryExist = activeSecurities[position.securityId];
    const isPopupVisible = useSelector((state: AppState) => state.addToPortfolio.popupVisible);
    const loading = isRequesting(bwicExistRequestState) || isRequesting(activeSecuritiesRequestState);

    const handleShow = () => {
        trackingService.trackAction('Security control panel options context menu have been opened', { securityId: position.securityId });
        setExpanded(true);
    }

    const handleAddToPortfolioClick = () => {
        dispatch(addToPortfolioActions.reset());
        dispatch(addToPortfolioActions.setPopupVisibility(true));
    }

    const menuItems = new ListBuilder<React.ReactNode>()
        .addWhen(() => !optionsExclude.some(el => el === ControlPanelOptions.addToPortfolio),
            <ContextMenuItem key="portfolio" onClick={handleAddToPortfolioClick}>
                Add to Portfolio
            </ContextMenuItem>
        )
        .addWhen(() => !optionsExclude.some(el => el === ControlPanelOptions.viewInventory),
            <ContextMenuItem
                key="inventory"
                onClick={() => history.push(`${routes.inventory}?isinCusipsAndTickers=${position.ticker}`)}
                disabled={!securityInventoryExist}
                requiredFeatures={[SubscriptionFeature.InventorySecuritySearch]}
            >
                <OnHoverTooltip disabled={!!securityInventoryExist} overlay="Security is not available in Dealer Inventory">
                    View Dealer Inventory
                </OnHoverTooltip>
            </ContextMenuItem>
        )
        .addWhen(() => !optionsExclude.some(el => el === ControlPanelOptions.viewBwic),
            <ContextMenuItem
                key="bwic"
                requiredFeatures={[SubscriptionFeature.BwicMonitorSecuritySearch]}
                disabled={!isBwicExist}
                onClick={() => history.push(`${routes.allBWICs}?isinCusipsAndTickers=${position.ticker}&dateFrom=${moment().format('YYYY-M-D')}`)}
            >
                <OnHoverTooltip disabled={isBwicExist} overlay="Security is not available on BWICs">
                    View BWIC
                </OnHoverTooltip>
            </ContextMenuItem>
        )
        .result();

    return (
        <>
            <ContextMenu
                onShow={handleShow}
                onHide={() => setExpanded(false)}
                customTargetComponent={
                    <button className={cn('btn btn-sm btn-ghost btn-options', { 'show': expanded, 'loading': loading })}>
                        Actions
                        <IconSVG name="icon-expand" width="16" height="16" />
                        <Preloader inProgress={loading} small />
                    </button>
                }
            >
                {isRequestSuccess(bwicExistRequestState) && isRequestSuccess(activeSecuritiesRequestState) ? menuItems : []}
            </ContextMenu>
            {isPopupVisible && <AddToPortfolioPopup position={position} />}
        </>
    );
}
