import { useState } from 'react';
import classNames from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Popup } from '../controls';
import { Preloader, Relative } from '../common';
import { profileActions } from '../../actions';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { imageCropperUtils } from '../../utils/image-cropper-utils';
import { UploadFileButton } from '../common/UploadFileButton';
import { DragFileZone } from '../common/DragFileZone';
import { errorMessages } from '../../constants';
import { userProfilePhotoConstants } from '../../constants/user-profile-upload-photo.constants';
import { user } from '../../user';
import { Confirm } from '../alerts';
import ImageSVG from '../../styles/svg';
import IconSVG from '../../styles/svg-icons';
import { isIE } from '../../utils/isIE.utils';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { numericUtils } from '../../utils';

export function UserProfilePhotoUploaderPopup() {
    const { acceptedFormats, maxFileSize, maxFileSizeForIE, sliderValueRange, sliderStep, defaultZoom, autoCropArea, initialAspectRatio } = userProfilePhotoConstants;
    const dispatch = useAppDispatch();
    const currentUser = user.current();
    const { userProfilePhoto, profile } = useSelector((s: AppState) => s);
    const { userPhotos } = userProfilePhoto;
    const currentUserPhoto = userPhotos[currentUser!.id];
    const { isUserProfilePhotoUploading, isUserProfilePhotoDeleting } = profile;
    const [cropper, setCropper] = useState<Cropper | null>(null);
    const [newImage, setNewImage] = useState('');
    const [dragEnter, setDragEnter] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [zoom, setZoom] = useState(defaultZoom);
    const [visibleDeleteConfirmationPopup, setVisibleDeleteConfirmationPopup] = useState(false);

    const validateFile = (file: Blob) => {
        if (acceptedFormats.indexOf(file.type) === -1) {
            return errorMessages.uploadingUserImageAcceptedFormats(
                acceptedFormats.map(format => `.${format.split('/')[1]}`).join(', ')
            )
        }
        if (isIE() && file.size > maxFileSizeForIE) {
            return errorMessages.uploadingUserImageMaxSize(3);
        }
        if (file.size > maxFileSize) {
            return errorMessages.uploadingUserImageMaxSize(5);
        }
        return ''
    };

    const handleOnFile = (file: Blob) => {
        const errorMessage = validateFile(file);
        setErrorMessage(errorMessage);
        if (!errorMessage && !isUserProfilePhotoDeleting) {
            imageCropperUtils.readFile(file)
                .then((image) => {
                    setNewImage(image);
                    setZoom(defaultZoom);
                });
        }
    };

    const handleOnDeletePhoto = () => {
        setVisibleDeleteConfirmationPopup(false);
        dispatch(profileActions.deleteUserImage())
    };

    const handleCrop = () => {
        if (cropper) {
            const croppedImageBase64 = cropper.getCroppedCanvas().toDataURL('image/jpeg');
            const blob = imageCropperUtils.dataURLtoBlob(croppedImageBase64);
            if (blob) {
                dispatch(profileActions.uploadUserImage(blob))
            }
        }
    };

    const renderErrorMessage = () => errorMessage
        ? (
            <div className="status-message alert">
                <i className="icon icon-warning" />
                <span className="status-message-cnt">{errorMessage}</span>
            </div>
        ) : null;

    const renderContent = () => {
        if (newImage) {
            return (
                <div className="photo-uploader-image flex-row">
                    <div className="image-cropper">
                        <Cropper
                            style={{ width: "100%" }}
                            // @ts-ignore
                            autoCropArea={autoCropArea}
                            src={newImage}
                            // @ts-ignore
                            initialAspectRatio={initialAspectRatio}
                            // @ts-ignore
                            guides={false}
                            // @ts-ignore
                            background={false}
                            onInitialized={(e) => setCropper(e)}
                            // @ts-ignore
                            viewMode={3}
                            // @ts-ignore
                            cropBoxResizable={false}
                            // @ts-ignore
                            dragMode="move"
                            // @ts-ignore
                            center={false}
                            // @ts-ignore
                            zoom={e => {
                                let zoom = e.detail.ratio;
                                if (zoom < 1) {
                                    zoom = sliderValueRange.min
                                }
                                if (zoom <= sliderValueRange.max +  1) {
                                    setZoom(zoom)
                                } else {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <span className="note-text">Drag to reposition photo</span>
                </div>
            )
        }
        if (currentUserPhoto) {
            return (
                <div className="photo-uploader-image flex-row">
                    <div className="current-image">
                        <img src={currentUserPhoto} alt="userImage" />
                    </div>
                </div>
            )
        }
        return (
            <div className={classNames('photo-uploader-empty', { 'drag-enter-content': dragEnter })}>
                <DragFileZone
                    onFile={handleOnFile}
                    onDragEnter={() => setDragEnter(true)}
                    onDragLeave={() => setDragEnter(false)}
                >
                    <ImageSVG name="no-avatar" width="120" height="120" />
                    <p>Drag a profile photo here</p>
                    <div className="or-separator text-sm">or</div>
                    <UploadFileButton
                        className="btn-main"
                        onFile={handleOnFile}
                        buttonText="Browse From Computer"
                        acceptedFormats={acceptedFormats}
                        disabled={isUserProfilePhotoDeleting}
                    />
                    {renderErrorMessage()}
                </DragFileZone>
            </div>
        )
    };

    const renderFooter = () => {
        if (newImage) {
            return (
                <>
                    {renderErrorMessage()}
                    <div className="flex-row">
                        <div className="zoom-slider flex-row">
                            <span>Zoom</span>
                            <Slider
                                value={zoom}
                                min={sliderValueRange.min}
                                max={sliderValueRange.max}
                                step={sliderStep}
                                onChange={zoom => {
                                    if(numericUtils.isNumber(zoom)){
                                        cropper?.zoomTo(zoom);
                                        setZoom(zoom)
                                    }
                                }}
                            />
                        </div>
                        <UploadFileButton
                            className="btn-ghost flex-item-right"
                            onFile={handleOnFile}
                            buttonText="Change Photo"
                            acceptedFormats={acceptedFormats}
                            disabled={isUserProfilePhotoDeleting}
                        />
                        <button className="btn btn-main" disabled={isUserProfilePhotoDeleting} onClick={handleCrop}>
                            Save Photo
                        </button>
                    </div>
                </>
            )
        }
        if (currentUserPhoto) {
            return (
                <>
                    {renderErrorMessage()}
                    <div className="flex-row">
                        <button
                            className="btn-link btn-link-lg btn-danger"
                            disabled={isUserProfilePhotoDeleting}
                            onClick={() => setVisibleDeleteConfirmationPopup(true)}
                        >
                            <IconSVG name="basket" width="16" height="16" /> Delete Photo
                        </button>
                        <UploadFileButton
                            className="btn-ghost flex-item-right"
                            onFile={handleOnFile}
                            buttonText="Change Photo"
                            acceptedFormats={acceptedFormats}
                            disabled={isUserProfilePhotoDeleting}
                        />
                        <button className="btn btn-main" disabled={true}>Save Photo</button>
                    </div>
                </>
            )
        }
        return null
    };

    return (
        <Popup
            title="Select Profile Photo"
            modalClass={classNames('modal-photo-uploader', { 'drag-enter': dragEnter })}
            onClose={() => dispatch(profileActions.setVisibleUploadImagePopup(false))}
        >
            <div className="photo-uploader">
                <div
                    className={classNames("photo-uploader-content", { "photo-uploading": isUserProfilePhotoUploading })}>{renderContent()}</div>
                <div className="photo-uploader-footer">
                    <Preloader inProgress={isUserProfilePhotoUploading} fullScreen={false} text="Uploading your photo…">
                        {renderFooter()}
                    </Preloader>
                </div>
            </div>
            <Relative>
                {
                    visibleDeleteConfirmationPopup &&
                    <Confirm
                        title="Confirmation"
                        confirmButtonText="Delete"
                        onCancel={() => setVisibleDeleteConfirmationPopup(false)}
                        onConfirm={handleOnDeletePhoto}
                        text={<span>Do you really want to delete profile photo?</span>}
                    />
                }
            </Relative>
        </Popup>
    )
}
